
import { RESET_PASSWORD_RESET, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from "./ResetPasswordTypes"


const initialResetPasswordState = {
    loading: false,
    resetpassword: [],
}

export const resetPasswordReducer  = (state = initialResetPasswordState, action) => {
    switch (action.type) {
        case RESET_PASSWORD_RESET:
            return {
                loading: false,
                resetpassword: [],
            }
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                loading: false,
                resetpassword: action.payload
            }
        case RESET_PASSWORD_FAILURE:
            return {
                loading: false,
                resetpassword: [],
                error: action.payload
            }
        default:
            return state;
    }
}