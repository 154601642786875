import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup,
    CardBody
} from "reactstrap";
import Header from 'components/Headers/Header'
import {
    CannyProvider,
    CannyFeedback
} from "react-canny";

import {fetchSSOToken} from 'redux/Ideas/IdeasAction';
import { useDispatch ,connect} from 'react-redux';
import Swal from 'sweetalert2'
import Plans from './Plans';

const Ideas = (props) => {

    const APP_ID = "/* Your app id */";
    const BOARD_TOKEN = "93e1fd3d-5b6b-4178-aa77-2a0ff96f9424";
    
    
    const [ssoToken, setSsoToken] = useState('')

    const dispatch = useDispatch();

    useEffect(() => {
        
        const data = {email:props.userDetails.email, name: props.userDetails.name, avatarURL: null, id:props.userDetails.userId}
        dispatch(fetchSSOToken(data))

    }, [])

    useEffect(() => {

        if(props.fetchSSOToken.loading){
            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
        }
        else{
            Swal.close()
        }

        if(props.fetchSSOToken.ssotoken.length){
            setSsoToken(props.fetchSSOToken.ssotoken[0].ssoToken)
        }
        
    }, [props.fetchSSOToken])
    

    
    return (
    <>
        <Header></Header>
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    
                    <Card className="shadow">
                        <CardHeader>

                            <div className='float-left'>
                                <h5 className="h3 mb-0">Ideas</h5>
                                
                            </div>
                            <div className='float-right'>
                                <a href="https://the-premed-consultants.canny.io/" className='btn btn-primary' target="_blank" color="primary">Show Roadmap</a>
                            </div>

                        </CardHeader>

                
                        <CardBody >
                
              
                            <Row className="pl-4 pr-4">
                                <Col lg='12' style={props.adminUser.admin ? {pointerEvents:"none"} :{}}>

                                    {ssoToken ? 
                                    <CannyProvider appId={APP_ID}>
                                        <CannyFeedback
                                        basePath="/client/ideas"
                                        boardToken={BOARD_TOKEN}
                                        ssoToken={ssoToken} />
                                    </CannyProvider>
                                     : ""}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    
                </div>
            </Row>
        </Container>
        <Plans></Plans>
    </>
  )
}
const mapStateToProps = state => {
    return {
      createWorkActivities : state.createWorkActivities,
      userDetails : state.loginAuth.userDetails,
      fetchSSOToken : state.fetchSSOToken,
      adminUser : state.adminUser
  
    }
}

export default connect(mapStateToProps)(Ideas)