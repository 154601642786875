import { SAVE_PERSONAL_STATEMENT_RESET, SAVE_PERSONAL_STATEMENT_REQUEST, SAVE_PERSONAL_STATEMENT_SUCCESS, SAVE_PERSONAL_STATEMENT_FAILURE, FETCH_PERSONAL_STATEMENT_FAILURE, FETCH_PERSONAL_STATEMENT_REQUEST, FETCH_PERSONAL_STATEMENT_SUCCESS } from './PersonalStatementTypes'

const initialPersonalStatementState = {
    loading: false,
    personalstatement: [],
}

const initialFetchPersonalStatementState = {
    personalstatement : [],
    loading: false,

}

export const personalStatementReducer  = (state = initialPersonalStatementState, action) => {
    switch (action.type) {
        case SAVE_PERSONAL_STATEMENT_RESET:
            return {
                loading: false,
                personalstatement: [],
            }
        case SAVE_PERSONAL_STATEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SAVE_PERSONAL_STATEMENT_SUCCESS:
            return {
                loading: false,
                personalstatement: [action.payload]
            }
        case SAVE_PERSONAL_STATEMENT_FAILURE:
            return {
                loading: false,
                personalstatement: [],
                error: action.payload
            }
        default:
            return state;
    }
}


export const fetchPersonalStatementReducer = (state = initialFetchPersonalStatementState, action) => {
    switch (action.type) {
        case FETCH_PERSONAL_STATEMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PERSONAL_STATEMENT_SUCCESS:
            return {
                loading: false,
                personalstatement: action.payload
            }
        case FETCH_PERSONAL_STATEMENT_FAILURE:
            return {
                loading: false,
                personalstatement: [],
                error: action.payload
            }
        default: return state;
    }
}