import { 
    FETCH_QUESTION_BANK_FAILURE, 
    FETCH_QUESTION_BANK_REQUEST, 
    FETCH_QUESTION_BANK_SUCCESS,
    FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE, 
    FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST, 
    FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS,
    CREATE_PRACTICE_TEST_RESULT_FAILURE,
    CREATE_PRACTICE_TEST_RESULT_REQUEST,
    CREATE_PRACTICE_TEST_RESULT_SUCCESS,
    CREATE_PRACTICE_TEST_RESULT_RESET

} from "./DATTypes"


const initialFetchQuestionBankState = {
    questionbank : [],
    loading: false,
}

export const fetchQuestionBankReducer = (state = initialFetchQuestionBankState, action) => {
    switch (action.type) {
        case FETCH_QUESTION_BANK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_QUESTION_BANK_SUCCESS:
            return {
                loading: false,
                questionbank: action.payload
            }
        case FETCH_QUESTION_BANK_FAILURE:
            return {
                loading: false,
                questionbank: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialCreatePracticeTestResultState = {
    practicetestresult: [],
    loading : false
}

export const createPracticeTestResultReducer = (state = initialCreatePracticeTestResultState, action) => {
    switch(action.type){
        case CREATE_PRACTICE_TEST_RESULT_RESET:
            return {
                loading: false,
                practicetestresult: []
            }
        case CREATE_PRACTICE_TEST_RESULT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_PRACTICE_TEST_RESULT_SUCCESS:
            return {
                loading: false,
                practicetestresult: [action.payload]
            }
        case CREATE_PRACTICE_TEST_RESULT_FAILURE:
            return {
                loading : false,
                practicetestresult : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchPracticeTestAttemptsState = {
    practicetestattempts : [],
    loading: false,
}

export const fetchPracticeTestAttemptsReducer = (state = initialFetchPracticeTestAttemptsState, action) => {
    switch (action.type) {
        case FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS:
            return {
                loading: false,
                practicetestattempts: action.payload
            }
        case FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE:
            return {
                loading: false,
                practicetestattempts: [],
                error: action.payload
            }
        default: return state;
    }
}
