import { 
    OPEN_COURSE_MODAL,
    CLOSE_COURSE_MODAL,
    CREATE_COURSE_REQUEST, 
    CREATE_COURSE_SUCCESS, 
    CREATE_COURSE_FAILURE,
    CREATE_COURSE_RESET,
    FETCH_COURSES_REQUEST, 
    FETCH_COURSES_SUCCESS, 
    FETCH_COURSES_FAILURE,
    EDIT_COURSE,
    NEW_COURSE,
    UPDATE_COURSE_REQUEST, 
    UPDATE_COURSE_SUCCESS, 
    UPDATE_COURSE_FAILURE,
    UPDATE_COURSE_RESET,
    DELETE_COURSE_REQUEST,
    DELETE_COURSE_SUCCESS, 
    DELETE_COURSE_FAILURE,
    DELETE_COURSE_RESET  } from "./CoursesTypes";

const initialCreateCourseState = {
    loading: false,
    course: [],
}

const initialUpdateCourseState = {
    loading: false,
    course: [],
}

const initialDeleteCourseState = {
    loading: false,
    course: [],
}

const initialFetchCoursesState = {
    courses : [],
    loading: false,

}

const initialModalCourseState = {
    status: false
}

const initialEditCourseState = {
    status: false,
    courseId : ''
}

const initialNewCourseState = {
    status: false,
}

export const createCoursesReducer = (state = initialCreateCourseState, action) => {
    switch (action.type) {
        case CREATE_COURSE_RESET:
            return {
                loading: false,
                course: [],
            }
        case CREATE_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_COURSE_SUCCESS:
            return {
                loading: false,
                course: [action.payload]
            }
        case CREATE_COURSE_FAILURE:
            return {
                loading: false,
                course: [],
                error: action.payload
            }
        default: return state;
    }
}

export const fetchCoursesReducer = (state = initialFetchCoursesState, action) => {
    switch (action.type) {
        case FETCH_COURSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_COURSES_SUCCESS:
            return {
                loading: false,
                courses: action.payload
            }
        case FETCH_COURSES_FAILURE:
            return {
                loading: false,
                courses: [],
                error: action.payload
            }
        default: return state;
    }
}

export const updateCourseReducer = (state = initialUpdateCourseState, action) => {
    switch (action.type) {
        case UPDATE_COURSE_RESET:
            return {
                loading: false,
                course: [],
            }
        case UPDATE_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_COURSE_SUCCESS:
            return {
                loading: false,
                course: [action.payload]
            }
        case UPDATE_COURSE_FAILURE:
            return {
                loading: false,
                course: [],
                error: action.payload
            }
        default: return state;
    }
}

export const editCourseReducer = (state = initialEditCourseState, action) => {
    switch (action.type) {
        case EDIT_COURSE:
            return {
                status: action.payload.status,
                courseId: action.payload.courseId
            }
        default: return state;
    }
}

export const newCourseReducer = (state = initialNewCourseState, action) => {
    switch (action.type) {
        case NEW_COURSE:
            return {
                status: action.payload.status,
            }
        default: return state;
    }
}

export const updateCourseModalStatusReducer = (state = initialModalCourseState, action) => {
    switch (action.type) {
        case OPEN_COURSE_MODAL:
            return {
                status : true
            }
        case CLOSE_COURSE_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}


export const deleteCourseReducer = (state = initialDeleteCourseState, action) => {
    switch (action.type) {
        case DELETE_COURSE_RESET:
            return {
                loading: false,
                course: [],
            }
        case DELETE_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_COURSE_SUCCESS:
            return {
                loading: false,
                course: [action.payload]
            }
        case DELETE_COURSE_FAILURE:
            return {
                loading: false,
                course: [],
                error: action.payload
            }
        default: return state;
    }
}