import {
  FETCH_ALL_USERS_FAILURE,
  FETCH_ALL_USERS_REQUEST,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_RESET,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_MULTI_USERS_FAILURE,
  UPDATE_MULTI_USERS_REQUEST,
  UPDATE_MULTI_USERS_RESET,
  UPDATE_MULTI_USERS_SUCCESS,
} from "./ManageUsersTypes";



import axios from "axios";

const fetchAllUsersRequest = () => {
    return {
        type: FETCH_ALL_USERS_REQUEST,
    }
}

const fetchAllUsersSuccess = success => {
    return {
        type: FETCH_ALL_USERS_SUCCESS,
        payload: success
    }
}

const fetchAllUsersFailure = error => {
    return {
        type: FETCH_ALL_USERS_FAILURE,
        payload : error
    }
}

export const fetchAllUsers = (data) => {
    
    const fetcAllUsersRoute = data.route;

    return (dispatch) => {
        
        dispatch(fetchAllUsersRequest());
        
        
        axios.post('/getAllUsers' + fetcAllUsersRoute , data)
        .then(response => {
            
            const allUsers = response.data
            dispatch(fetchAllUsersSuccess(allUsers))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchAllUsersFailure(errorMsg))
        })
        
    }

}



const fetchUserDetailsRequest = () => {
    return {
        type: FETCH_USER_DETAILS_REQUEST,
    }
}

const fetchUserDetailsSuccess = success => {
    return {
        type: FETCH_USER_DETAILS_SUCCESS,
        payload: success
    }
}

const fetchUserDetailsFailure = error => {
    return {
        type: FETCH_USER_DETAILS_FAILURE,
        payload : error
    }
}

export const fetchUserDetails = (data) => {

    return (dispatch) => {
        
        dispatch(fetchUserDetailsRequest());
        
        
        axios.post('/getUserDetails' , data)
        .then(response => {
            const userDetails = response.data
            dispatch(fetchUserDetailsSuccess(userDetails))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchUserDetailsFailure(errorMsg))
        })
        
    }

}



export const updateUserDetailsReset = () => {
    return {
        type: UPDATE_USER_DETAILS_RESET,
    }
}

const updateUserDetailsRequest = () => {
    return {
        type: UPDATE_USER_DETAILS_REQUEST,
    }
}

const updateUserDetailsSuccess = success => {
    return {
        type: UPDATE_USER_DETAILS_SUCCESS,
        payload: success
    }
}

const updateUserDetailsFailure = error => {
    return {
        type: UPDATE_USER_DETAILS_FAILURE,
        payload : error
    }
}

export const updateUserDetails = (data) => {

    return (dispatch) => {

        dispatch(updateUserDetailsRequest());
        
        
        
        axios.post('/updateUserDetails', data)
        .then(response => {
            const userdetails = response.data
            dispatch(updateUserDetailsSuccess(userdetails))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateUserDetailsFailure(errorMsg))
        })
        
    }

}
export const updateMultiUsersReset = () => {
  return {
    type: UPDATE_MULTI_USERS_RESET,
  };
};

const updateMultiUsersRequest = () => {
  return {
    type: UPDATE_MULTI_USERS_REQUEST,
  };
};

const updateMultiUsersSuccess = (success) => {
  return {
    type: UPDATE_MULTI_USERS_SUCCESS,
    payload: success,
  };
};

const updateMultiUsersFailure = (error) => {
  return {
    type: UPDATE_MULTI_USERS_FAILURE,
    payload: error,
  };
};

export const updateMultiUsers = (data) => {
  return (dispatch) => {
    dispatch(updateMultiUsersRequest());

    axios
      .post("/updateMultiUsers", data)
      .then((response) => {
        const multiusers = response.data;
        dispatch(updateMultiUsersSuccess(multiusers));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateMultiUsersFailure(errorMsg));
      });
  };
};
