import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Col,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardTitle,
} from "reactstrap";
import { useDispatch ,connect} from 'react-redux';
import Swal from 'sweetalert2'
import classnames from 'classnames';
import TestCard from 'components/Card/Test/TestCard';
import {fetchQuestionBank, createPracticeTestResultReset, fetchPracticeTestAttempts} from 'redux/DAT/DATActions';
import TestAttempt from 'components/Card/Test/TestAttemptCard';
import { Line } from 'react-chartjs-2';

import DatCourses from '../pages/DatCourses'

const OrganicChemistry = (props) => {

    const dispatch = useDispatch();

    const topics = {
        "dat-biology" : "Biology",
        "dat-general-chemistry" : "General Chemistry",
        "dat-organic-chemistry" : "Organic Chemistry",
        "dat-perceptual-ability" : "Perceptual Ability",
        "dat-reading-comprehension" : "Reading Comprehension",
        "dat-quantitative-reasoning" : "Quantitative Reasoning",
    }

    

    const [questionBankData, setQuestionBankData] = useState([])

    const datName ="organicChemistryQBank";
    useEffect(() => {
       
        
        dispatch(fetchQuestionBank({doc:datName}))
        

    }, [])

    useEffect(() => {
      
        if(props.fetchQuestionBank.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{

          if(props.fetchQuestionBank.questionbank.length){

                
                Swal.close()
                
                setQuestionBankData(props.fetchQuestionBank.questionbank)
                setSelectedPracticeTest(0)
                

                

                setDocId(props.fetchQuestionBank.questionbank[0].docId)

                dispatch(fetchPracticeTestAttempts({handle: props.userDetails.handle, datName: datName, docId: props.fetchQuestionBank.questionbank[0].docId}))
                

          }
          else if (typeof props.fetchQuestionBank.error !== "undefined") {

                Swal.fire({
                    title: 'Failed!',
                    text: 'Something went wrong!',
                    icon : 'error',
                    timer: 3000
                })

          }
          else{
            Swal.close()
          }
        }


    }, [props.fetchQuestionBank])

    const [docId , setDocId] = useState("practiceTest")

    useEffect(() => {
        
        setPracticeTestAttempts([])
        setCurrentAttempt(0)
        dispatch(fetchPracticeTestAttempts({handle: props.userDetails.handle, datName: datName, docId: docId}))
        

    }, [docId])
    

    const [practiceTestAttempts, setPracticeTestAttempts] = useState([]);

    useEffect(() => {
      
        if(props.fetchPracticeTestAttempts.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{

          if(props.fetchPracticeTestAttempts.practicetestattempts.length){
            
                setPracticeTestAttempts(props.fetchPracticeTestAttempts.practicetestattempts)
                setCurrentAttempt(props.fetchPracticeTestAttempts.practicetestattempts.length - 1)
                Swal.close()
                
          }
          else if (typeof props.fetchPracticeTestAttempts.error !== "undefined") {

                Swal.fire({
                    title: 'Failed!',
                    text: 'Something went wrong!',
                    icon : 'error',
                    timer: 3000
                })

          }
          else{
      
            Swal.close()
          }
        }


    }, [props.fetchPracticeTestAttempts])

    
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [hover, setHover] = useState(false);
    
    const activeTabStyle = { color: "white" , backgroundColor:"#2dce89" }
    const inactiveTabStyle = { color: hover ? 'red' : '' }

    const handleMouseEnter = () => {
        setHover(true);
       
    };

    const handleMouseLeave = () => {
        setHover(false);
       
    };

    const [startPractice, setStartPractice] = useState(false)
    const [showAttempt, setShowAttempt] = useState(false)
    
    const [dataAttempt, setDataAttempt] = useState({})

    

    useEffect(() => {
        if(!startPractice && !showAttempt){
            dispatch(fetchPracticeTestAttempts({handle: props.userDetails.handle, datName: datName, docId: docId}))
        }

    }, [startPractice, showAttempt])
    
    const handleStartPractice = () =>{
        

        Swal.fire({
            title: 'Start?',
            html: 
            `<div>
                <p style="font-weight: bold;">Please read the following instructions carefully.</p>
                
                <ul style="text-align: left;">
                    <li style="margin-bottom: 10px; font-size: 16px">Your test score will be shown at the end of the test along with the answers to each question.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">You can mark questions using the <span style="font-weight: bold;">“Mark Question”</span> button.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">You can highlight questions using the <span style="font-weight: bold;">"highlight"</span> button.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">The time spent will be shown during the exam.</li>
                </ul>
                
            </div>`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, Start!',
            cancelButtonColor: '#5e72e4',
            confirmButtonColor: '#2dce89'
        }).then((result) => {
            
            if (result.isConfirmed) {
                setDataAttempt({})
                setResultsDocId(null)
                setStartPractice(!startPractice)
            }
        }) 

    }

    useEffect(() => {


        if(props.createPracticeTestResult.loading){
            Swal.fire({
                allowOutsideClick : false,
                didOpen: () => {
                    Swal.showLoading()
                    
                }
            });
        }
        else{

          if(props.createPracticeTestResult.practicetestresult.length){

                
                Swal.close()
                
                setDataAttempt(props.createPracticeTestResult.practicetestresult[0])

                setStartPractice(false)
                setShowAttempt(true)
                dispatch(createPracticeTestResultReset())

          }
          else if (typeof props.createPracticeTestResult.error !== "undefined") {

                Swal.fire({
                    title: 'Failed!',
                    text: 'Something went wrong!',
                    icon : 'error',
                    timer: 3000
                })

          }
        }

    }, [props.createPracticeTestResult])
    

    

    const [selectedPracticeTest, setSelectedPracticeTest] = useState(null)
    

    const [currentAttempt, setCurrentAttempt] = useState(null);
    

    const handleNext = () => {
        if (currentAttempt < (practiceTestAttempts.length-1)) {
            setCurrentAttempt(currentAttempt + 1);
        }
    };

    const handlePrevious = () => {
        if (currentAttempt > 0) {
            setCurrentAttempt(currentAttempt - 1);
        }
    };

    const handleViewAttempt = () => {
        setDataAttempt(practiceTestAttempts[currentAttempt])
        setShowAttempt(true)
    }


    const handleSelectPracticeTest = (docId) =>{
        
        setDocId(docId)
    }

    const [resultsDocId, setResultsDocId] = useState(null);
    const handleContinuePractice = () =>{
        // console.log(questionBankData[selectedPracticeTest].practiceTest)
        console.log(practiceTestAttempts[currentAttempt])
        Swal.fire({
            title: 'Continue?',
            html: 
            `<div>
                <p style="font-weight: bold;">Please read the following instructions carefully.</p>
                
                <ul style="text-align: left;">
                    <li style="margin-bottom: 10px; font-size: 16px">Your test score will be shown at the end of the test along with the answers to each question.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">You can mark questions using the <span style="font-weight: bold;">“Mark Question”</span> button.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">You can highlight questions using the <span style="font-weight: bold;">"highlight"</span> button.</li>
                    <li style="margin-bottom: 10px; font-size: 16px">The time spent will be shown during the exam.</li>
                </ul>
                
            </div>`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue!',
            cancelButtonColor: '#5e72e4',
            confirmButtonColor: '#2dce89'
        }).then((result) => {
            
            if (result.isConfirmed) {
                setResultsDocId(practiceTestAttempts[currentAttempt].resultsDocId)
                setDataAttempt(practiceTestAttempts[currentAttempt])
                setStartPractice(!startPractice)
                
            }
        }) 
     
    }

    return (
    <>
        <div className="header bg-gradient-success pb-8 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                
                </div>
            </Container>
        </div>
        
            <Container className="mt--7" fluid>
                <Row>
                    {startPractice?
                    <>
                        <Col md="1"></Col>
                            <Col md="10">
                                {selectedPracticeTest !== null &&
                                    <TestCard dataAttempt={dataAttempt} questionList={questionBankData[selectedPracticeTest].practiceTest} docId={questionBankData[selectedPracticeTest].docId} datName={datName} subjectName={topics[props.location.pathname.split("/")[2]]} resultsDocId={resultsDocId}></TestCard>
                                }
                            </Col>
                        <Col md="1"></Col>
                    </>
                    : 
                    
                    showAttempt ? 
                        <>
                            <Col md="1"></Col>
                            <Col md="10">
                                <Button className="shadow" color="info" onClick={() => setShowAttempt(false)}><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;Back</Button>    
                                <TestAttempt dataAttempt={dataAttempt} docId={questionBankData[selectedPracticeTest].docId} subjectName={topics[props.location.pathname.split("/")[2]]}></TestAttempt>
                            </Col>
                            <Col md="1"></Col>
                        </>
                    :
                    <>
                        <Col md="1"></Col>
                        <Col md="10">
                            <Card className=''>
                                <CardHeader className="font-weight-bolder text-xl text-default">{topics[props.location.pathname.split("/")[2]]}</CardHeader>
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                        
                        <Col md="1"></Col>
                        <Col md="10 mt-5 mb-3">
                            <Nav tabs>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={ activeTab === '1' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '1'})}
                                        onClick={() => toggle('1')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        
                                    >
                                        Question Banks
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '2' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => toggle('2')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Video Content
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '3' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => toggle('3')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Study Notes
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '4' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '4' })}
                                        onClick={() => toggle('4')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Cheat Sheets
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '5' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '5' })}
                                        onClick={() => toggle('5')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Flash Cards
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="1"></Col>
                        <Col md="10">
                            <Card >
                                <CardBody className='mt-2 pt-2'>
                                    
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row className=' mb-1'>
                                                {selectedPracticeTest !== null ? <>
                                                    <Col sm="12 " ><p className='text-lg text-default font-weight-bolder mb-2'>Question Banks</p></Col>
                                                    <Col sm="7" className='mb-3'>
                                                        {practiceTestAttempts.length ?
                                                        <><Row className='mb-3'>
                                                            <Col md="6" lg="12">
                                                                <Row className="align-items-center ">
                                                                    <Col className="text-center">
                                                                        <button onClick={handlePrevious} className='btn btn-success'><i class="fas fa-arrow-left"></i></button>
                                                                    </Col>
                                                                    <Col className="text-center">
                                                                        <h2 className='text-sm'>
                                                                            Attempt {currentAttempt + 1}/{practiceTestAttempts.length}
                                                                        </h2>
                                                                    </Col>
                                                                    <Col className="text-center">
                                                                        <button onClick={handleNext} className='btn btn-success'><i class="fas fa-arrow-right"></i></button>
                                                                    
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md="6" lg="12" className='d-flex align-items-center justify-content-center'><a href='#' onClick={handleViewAttempt} className='text-success font-weight-bold'><i class="fas fa-eye"></i>&nbsp;View Attempt</a></Col>
                                                        </Row>
                                                                    
                                                        <Card className='bg-default'>
                                                            <CardHeader className='bg-transparent'>
                                                                <Row className="align-items-center">
                                                                    <div className="col">
                                                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                                        ORGANIC CHEMISTRY STATISTICS
                                                                        </h6>
                                                                        <h2 className="mb-0 text-white">Scores</h2>
                                                                    </div>
                                                                </Row>
                                                            </CardHeader>
                                                            <CardBody className=''>
                                                                
                                                                <div className="chart">
                                                                    <Line
                        
                                                                        data={{
                                                                            labels: practiceTestAttempts.slice(-10).map(item => item.date),
                                                                            
                                                                            datasets: [{
                                                                            label: "SCORE",
                                                                            data: practiceTestAttempts.slice(-10).map(item => item.score.split("/")[0]),
                                                                            borderColor: 'rgb(255, 99, 132)',
                                                                            backgroundColor: 'rgba(255, 99, 132, 0)',
                                                                            pointStyle: 'circle', 
                                                                            pointRadius: 5, 
                                                                            pointBackgroundColor: 'rgb(255, 99, 132)',
                                                                            
                                                                            }
                                                                        ]}
                                                                        }
                                                                        
                                                                        options={{
                                                                            
                                                                            tooltips: {
                                                                            callbacks: {
                                                                                afterLabel: function(tooltipItem, data) {
                                                                                if (tooltipItem.datasetIndex === 3) {
                                                                                    
                                                                                    // return 'TOTAL : ' + totalData[tooltipItem.index];
                                                                                }
                                                                                
                                                                                } 
                                                                            }
                                                                            },
                                                                            responsive: true,
                                                                            scales: {
                                                                            myScale: {
                                                                                type: 'logarithmic',
                                                                                position: 'right', // `axis` is determined by the position as `'y'`,
                                                                                
                                                                            },
                                                                            yAxes: [{
                                                                                ticks: {
                                                                                    

                                                                                    max : questionBankData[selectedPracticeTest].practiceTest.length,
                                                                                    min : 0,
                                                                                
                                                                                    },
                                                                                    
                                                                                }
                                                                            ],
                                                                            xAxes : [{
                                                                                display:false,
                                                                                
                                                                            }]  ,
                                                                            
                                                                            },
                                                                            clip: false
                                                                        }
                                                                        
                                                                        }

                                                                        id="chart-sales"
                                                                        className="chart-canvas"
                                                                    />
                                                                </div>
                                                            </CardBody>
                                                            

                                                        </Card>
                                                        </> : ""}
                                                        <Row className='mb-3 mt-3'>
                                                            {practiceTestAttempts.length ? 
                                                            <><Col lg="6" md="12" >
                                                                <Card className="card-stats mb-3 w-100">
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col className="col-auto">
                                                                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                                    <i className="fas fa-calendar" />
                                                                                </div>
                                                                            </Col>
                                                                            <div className="col">
                                                                                <CardTitle
                                                                                    tag="h5"
                                                                                    className="text-uppercase text-muted mb-0"
                                                                                >
                                                                                    Date
                                                                                </CardTitle>
                                                                                <span className="font-weight-bold mb-0">
                                                                                {practiceTestAttempts[currentAttempt].date}  
                                                                                
                                                                                </span>
                                                                            </div>
                                                                            
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                            <Col lg="6" md="12" >
                                                                <Card className="card-stats mb-3 w-100">
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col className="col-auto">
                                                                                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                                        <i className="fas fa-clock" />
                                                                                    </div>
                                                                                </Col>
                                                                                <div className="col">
                                                                                    <CardTitle
                                                                                        tag="h5"
                                                                                        className="text-uppercase text-muted mb-0"
                                                                                    >
                                                                                        Time Spent
                                                                                    </CardTitle>
                                                                                    <span className="h2 font-weight-bold mb-0">
                                                                                        
                                                                                    {practiceTestAttempts[currentAttempt].timeSpent}  
                                                                                    </span>
                                                                                </div>
                                                                                
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            <Col lg="6" md="12" >
                                                                <Card className="card-stats mb-3 w-100">
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col className="col-auto">
                                                                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                                                    <i className="fas fa-chart-pie" />
                                                                                </div>
                                                                            </Col>
                                                                            <div className="col">
                                                                                <CardTitle
                                                                                    tag="h5"
                                                                                    className="text-uppercase text-muted mb-0"
                                                                                >
                                                                                    Score
                                                                                </CardTitle>
                                                                                <span className="h2 font-weight-bold mb-0">
                                                                                    
                                                                                {practiceTestAttempts[currentAttempt].score}  
                                                                                </span>
                                                                            </div>
                                                                            
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col></> : ""}
                                                            <Col md="12">
                                                                {/* <Row>
                                                                    
                                                                    <Col md="6"> */}
                                                                        
                                                                            
                                                                          <button className='btn btn-success' onClick={handleStartPractice}>{practiceTestAttempts.length ? "Retake" : "Start"} Practice</button>
                                                                
                                                                          {practiceTestAttempts.length ? <>
                                                                            {practiceTestAttempts[currentAttempt].status == "draft" ? 
                                                                          
                                                                                <button className='btn btn-success' onClick={handleContinuePractice}>Continue</button>
                                                                          
                                                                         : ""}</> : "" }

                                                                    {/* </Col>
                                                                </Row> */}
                                                            </Col>
                                                        </Row>
                                                        
                                                        
                                                        
                                                    </Col>
                                                    <Col sm="5">
                                                        <Row>
                                                            {
                                                                questionBankData.map((option, index)=>{
                                                                    return <Col key={index} sm="12">
                                                                        <button key={index} onClick={ () => handleSelectPracticeTest(option.docId)} className='btn btn-success w-100 h2 pt-4 pb-4  text-left pl-4'><h3 className='mb-0 text-white font-weight-bolder'>Practice Test #{option.docId.split("practiceTest")[1]}</h3></button>
                                                                    </Col>
                                                                })
                                                            }
                                                           
                                                        </Row>
                                                    </Col>
                                                </>  : 
                                                    <Col sm="12 text-center">
                                                        <div className='display-4'>No Question Banks</div>
                                                
                                                    </Col>
                                                }
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <DatCourses bgColor="bg-success" textColor="text-white" subject={topics[props.location.pathname.split("/")[2]]}/>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                    </>}
                </Row>
            </Container>
        
    </>
  )
}
const mapStateToProps = state => {
    return {
      userDetails : state.loginAuth.userDetails,
      fetchQuestionBank : state.fetchQuestionBank,
      createPracticeTestResult : state.createPracticeTestResult,
      fetchPracticeTestAttempts : state.fetchPracticeTestAttempts,
    }
}

export default connect(mapStateToProps)(OrganicChemistry)