import { 
    CREATE_COACH_TUTOR_AVAILABILITY_FAILURE,
    CREATE_COACH_TUTOR_AVAILABILITY_REQUEST,
    CREATE_COACH_TUTOR_AVAILABILITY_RESET,
    CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS,

    DELETE_COACH_TUTOR_AVAILABILITY_FAILURE,
    DELETE_COACH_TUTOR_AVAILABILITY_REQUEST,
    DELETE_COACH_TUTOR_AVAILABILITY_RESET,
    DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS,


    FETCH_COACH_TUTOR_AVAILABILITY_FAILURE,
    FETCH_COACH_TUTOR_AVAILABILITY_REQUEST,
    FETCH_COACH_TUTOR_AVAILABILITY_RESET,
    FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS,

    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET,
    FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS
} from "./ManageCoachTutorAvailabilityTypes"

const initialCreateCoachTutorAvailabilityState = {
    createcoachtutoravailability: [],
    loading : false
}

export const createCoachTutorAvailabilityReducer = (state = initialCreateCoachTutorAvailabilityState, action) => {
    switch(action.type){
        case CREATE_COACH_TUTOR_AVAILABILITY_RESET:
            return {
                loading: false,
                createcoachtutoravailability: []
            }
        case CREATE_COACH_TUTOR_AVAILABILITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_COACH_TUTOR_AVAILABILITY_SUCCESS:
            return {
                loading: false,
                createcoachtutoravailability: [action.payload]
            }
        case CREATE_COACH_TUTOR_AVAILABILITY_FAILURE:
            return {
                loading : false,
                createcoachtutoravailability: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialDeleteCoachTutorAvailabilityState = {
    deletecoachtutoravailability: [],
    loading : false
}

export const deleteCoachTutorAvailabilityReducer = (state = initialDeleteCoachTutorAvailabilityState, action) => {
    switch(action.type){
        case DELETE_COACH_TUTOR_AVAILABILITY_RESET:
            return {
                loading: false,
                deletecoachtutoravailability: []
            }
        case DELETE_COACH_TUTOR_AVAILABILITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_COACH_TUTOR_AVAILABILITY_SUCCESS:
            return {
                loading: false,
                deletecoachtutoravailability: [action.payload]
            }
        case DELETE_COACH_TUTOR_AVAILABILITY_FAILURE:
            return {
                loading : false,
                deletecoachtutoravailability: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchCoachTutorAvailabilityState = {
    fetchcoachtutoravailability: [],
    loading : false
}

export const fetchCoachTutorAvailabilityReducer = (state = initialFetchCoachTutorAvailabilityState, action) => {
    switch(action.type){
        case FETCH_COACH_TUTOR_AVAILABILITY_RESET:
            return {
                loading: false,
                fetchcoachtutoravailability: []
            }
        case FETCH_COACH_TUTOR_AVAILABILITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_COACH_TUTOR_AVAILABILITY_SUCCESS:
            return {
                loading: false,
                fetchcoachtutoravailability: [action.payload]
            }
        case FETCH_COACH_TUTOR_AVAILABILITY_FAILURE:
            return {
                loading : false,
                fetchcoachtutoravailability: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchHistoryCoachTutorAvailabilityState = {
    fetchhistorycoachtutoravailability: [],
    loading : false
}

export const fetchHistoryCoachTutorAvailabilityReducer = (state = initialFetchHistoryCoachTutorAvailabilityState, action) => {
    switch(action.type){
        case FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_RESET:
            return {
                loading: false,
                fetchhistorycoachtutoravailability: []
            }
        case FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_SUCCESS:
            return {
                loading: false,
                fetchhistorycoachtutoravailability: [action.payload]
            }
        case FETCH_HISTORY_COACH_TUTOR_AVAILABILITY_FAILURE:
            return {
                loading : false,
                fetchhistorycoachtutoravailability: [],
                error: action.payload
            }
        default: return state;
    }
}
