import {
  CREATE_COMMUNITY_POST_REQUEST,
  CREATE_COMMUNITY_POST_SUCCESS,
  CREATE_COMMUNITY_POST_FAILURE,
  CREATE_COMMUNITY_POST_RESET,
  UPDATE_COMMUNITY_POST_REQUEST,
  UPDATE_COMMUNITY_POST_SUCCESS,
  UPDATE_COMMUNITY_POST_FAILURE,
  UPDATE_COMMUNITY_POST_RESET,
  DELETE_COMMUNITY_POST_REQUEST,
  DELETE_COMMUNITY_POST_SUCCESS,
  DELETE_COMMUNITY_POST_FAILURE,
  DELETE_COMMUNITY_POST_RESET,
  FETCH_ALL_COMMUNITY_POSTS_RESET,
  FETCH_ALL_COMMUNITY_POSTS_REQUEST,
  FETCH_ALL_COMMUNITY_POSTS_FAILURE,
  FETCH_ALL_COMMUNITY_POSTS_SUCCESS,
  CREATE_COMMUNITY_COMMENT_REQUEST,
  CREATE_COMMUNITY_COMMENT_SUCCESS,
  CREATE_COMMUNITY_COMMENT_FAILURE,
  CREATE_COMMUNITY_COMMENT_RESET,
  UPDATE_COMMUNITY_COMMENT_REQUEST,
  UPDATE_COMMUNITY_COMMENT_SUCCESS,
  UPDATE_COMMUNITY_COMMENT_FAILURE,
  UPDATE_COMMUNITY_COMMENT_RESET,
  DELETE_COMMUNITY_COMMENT_REQUEST,
  DELETE_COMMUNITY_COMMENT_SUCCESS,
  DELETE_COMMUNITY_COMMENT_FAILURE,
  DELETE_COMMUNITY_COMMENT_RESET,
  CREATE_COMMUNITY_REPLY_REQUEST,
  CREATE_COMMUNITY_REPLY_SUCCESS,
  CREATE_COMMUNITY_REPLY_FAILURE,
  CREATE_COMMUNITY_REPLY_RESET,
  UPDATE_COMMUNITY_REPLY_REQUEST,
  UPDATE_COMMUNITY_REPLY_SUCCESS,
  UPDATE_COMMUNITY_REPLY_FAILURE,
  UPDATE_COMMUNITY_REPLY_RESET,
  DELETE_COMMUNITY_REPLY_REQUEST,
  DELETE_COMMUNITY_REPLY_SUCCESS,
  DELETE_COMMUNITY_REPLY_FAILURE,
  DELETE_COMMUNITY_REPLY_RESET,
  CREATE_COMMUNITY_LIKEPOST_REQUEST,
  CREATE_COMMUNITY_LIKEPOST_SUCCESS,
  CREATE_COMMUNITY_LIKEPOST_FAILURE,
  CREATE_COMMUNITY_LIKEPOST_RESET,
  CREATE_COMMUNITY_SAVEPOST_REQUEST,
  CREATE_COMMUNITY_SAVEPOST_SUCCESS,
  CREATE_COMMUNITY_SAVEPOST_FAILURE,
  CREATE_COMMUNITY_SAVEPOST_RESET,
  CREATE_COMMUNITY_LIKECOMMENT_REQUEST,
  CREATE_COMMUNITY_LIKECOMMENT_SUCCESS,
  CREATE_COMMUNITY_LIKECOMMENT_FAILURE,
  CREATE_COMMUNITY_LIKECOMMENT_RESET,
  FETCH_COMMUNITY_LIKEDPOST_RESET,
  FETCH_COMMUNITY_LIKEDPOST_REQUEST,
  FETCH_COMMUNITY_LIKEDPOST_FAILURE,
  FETCH_COMMUNITY_LIKEDPOST_SUCCESS,
  FETCH_LEADERBOARD_RESET,
  FETCH_LEADERBOARD_REQUEST,
  FETCH_LEADERBOARD_FAILURE,
  FETCH_LEADERBOARD_SUCCESS,
} from "./CommunityType";

const initialCreateCommunityPostState = {
  createcommunitypost: [],
  loading: false,
};

export const createCommunityPostReducer = (
  state = initialCreateCommunityPostState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_POST_RESET:
      return {
        loading: false,
        createcommunitypost: [],
      };
    case CREATE_COMMUNITY_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_POST_SUCCESS:
      return {
        loading: false,
        createcommunitypost: [action.payload],
      };
    case CREATE_COMMUNITY_POST_FAILURE:
      return {
        loading: false,
        createcommunitypost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityPostState = {
  updatecommunitypost: [],
  loading: false,
};

export const updateCommunityPostReducer = (
  state = initialUpdateCommunityPostState,
  action
) => {
  switch (action.type) {
    case UPDATE_COMMUNITY_POST_RESET:
      return {
        loading: false,
        updatecommunitypost: [],
      };
    case UPDATE_COMMUNITY_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMMUNITY_POST_SUCCESS:
      return {
        loading: false,
        updatecommunitypost: [action.payload],
      };
    case UPDATE_COMMUNITY_POST_FAILURE:
      return {
        loading: false,
        updatecommunitypost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityPostState = {
  deletecommunitypost: [],
  loading: false,
};

export const deleteCommunityPostReducer = (
  state = initialDeleteCommunityPostState,
  action
) => {
  switch (action.type) {
    case DELETE_COMMUNITY_POST_RESET:
      return {
        loading: false,
        deletecommunitypost: [],
      };
    case DELETE_COMMUNITY_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMMUNITY_POST_SUCCESS:
      return {
        loading: false,
        deletecommunitypost: [action.payload],
      };
    case DELETE_COMMUNITY_POST_FAILURE:
      return {
        loading: false,
        deletecommunitypost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchAllCommunityPostsState = {
  allposts: [],
  loading: false,
};

export const fetchAllCommunityPostsReducer = (
  state = initialFetchAllCommunityPostsState,
  action
) => {
  switch (action.type) {
    case FETCH_ALL_COMMUNITY_POSTS_RESET:
      return {
        loading: false,
        allposts: [],
      };
    case FETCH_ALL_COMMUNITY_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_COMMUNITY_POSTS_SUCCESS:
      return {
        loading: false,
        allposts: action.payload,
      };
    case FETCH_ALL_COMMUNITY_POSTS_FAILURE:
      return {
        loading: false,
        allposts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateCommunityCommentState = {
  createcommunitycomment: [],
  loading: false,
};

export const createCommunityCommentReducer = (
  state = initialCreateCommunityCommentState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_COMMENT_RESET:
      return {
        loading: false,
        createcommunitycomment: [],
      };
    case CREATE_COMMUNITY_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_COMMENT_SUCCESS:
      return {
        loading: false,
        createcommunitycomment: [action.payload],
      };
    case CREATE_COMMUNITY_COMMENT_FAILURE:
      return {
        loading: false,
        createcommunitycomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityCommentState = {
  updatecommunitycomment: [],
  loading: false,
};

export const updateCommunityCommentReducer = (
  state = initialUpdateCommunityCommentState,
  action
) => {
  switch (action.type) {
    case UPDATE_COMMUNITY_COMMENT_RESET:
      return {
        loading: false,
        updatecommunitycomment: [],
      };
    case UPDATE_COMMUNITY_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMMUNITY_COMMENT_SUCCESS:
      return {
        loading: false,
        updatecommunitycomment: [action.payload],
      };
    case UPDATE_COMMUNITY_COMMENT_FAILURE:
      return {
        loading: false,
        updatecommunitycomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityCommentState = {
  deletecommunitycomment: [],
  loading: false,
};

export const deleteCommunityCommentReducer = (
  state = initialDeleteCommunityCommentState,
  action
) => {
  switch (action.type) {
    case DELETE_COMMUNITY_COMMENT_RESET:
      return {
        loading: false,
        deletecommunitycomment: [],
      };
    case DELETE_COMMUNITY_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMMUNITY_COMMENT_SUCCESS:
      return {
        loading: false,
        deletecommunitycomment: [action.payload],
      };
    case DELETE_COMMUNITY_COMMENT_FAILURE:
      return {
        loading: false,
        deletecommunitycomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateCommunityReplyState = {
  createcommunityreply: [],
  loading: false,
};

export const createCommunityReplyReducer = (
  state = initialCreateCommunityReplyState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_REPLY_RESET:
      return {
        loading: false,
        createcommunityreply: [],
      };
    case CREATE_COMMUNITY_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_REPLY_SUCCESS:
      return {
        loading: false,
        createcommunityreply: [action.payload],
      };
    case CREATE_COMMUNITY_REPLY_FAILURE:
      return {
        loading: false,
        createcommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityReplyState = {
  updatecommunityreply: [],
  loading: false,
};

export const updateCommunityReplyReducer = (
  state = initialUpdateCommunityReplyState,
  action
) => {
  switch (action.type) {
    case UPDATE_COMMUNITY_REPLY_RESET:
      return {
        loading: false,
        updatecommunityreply: [],
      };
    case UPDATE_COMMUNITY_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMMUNITY_REPLY_SUCCESS:
      return {
        loading: false,
        updatecommunityreply: [action.payload],
      };
    case UPDATE_COMMUNITY_REPLY_FAILURE:
      return {
        loading: false,
        updatecommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityReplyState = {
  deletecommunityreply: [],
  loading: false,
};

export const deleteCommunityReplyReducer = (
  state = initialDeleteCommunityReplyState,
  action
) => {
  switch (action.type) {
    case DELETE_COMMUNITY_REPLY_RESET:
      return {
        loading: false,
        deletecommunityreply: [],
      };
    case DELETE_COMMUNITY_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMMUNITY_REPLY_SUCCESS:
      return {
        loading: false,
        deletecommunityreply: [action.payload],
      };
    case DELETE_COMMUNITY_REPLY_FAILURE:
      return {
        loading: false,
        deletecommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateCommunityLikePostState = {
  createcommunitylikepost: [],
  loading: false,
};

export const createCommunityLikePostReducer = (
  state = initialCreateCommunityLikePostState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_LIKEPOST_RESET:
      return {
        loading: false,
        createcommunitylikepost: [],
      };
    case CREATE_COMMUNITY_LIKEPOST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_LIKEPOST_SUCCESS:
      return {
        loading: false,
        createcommunitylikepost: [action.payload],
      };
    case CREATE_COMMUNITY_LIKEPOST_FAILURE:
      return {
        loading: false,
        createcommunitylikepost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateCommunitySavePostState = {
  createcommunitysavepost: [],
  loading: false,
};

export const createCommunitySavePostReducer = (
  state = initialCreateCommunitySavePostState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_SAVEPOST_RESET:
      return {
        loading: false,
        createcommunitysavepost: [],
      };
    case CREATE_COMMUNITY_SAVEPOST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_SAVEPOST_SUCCESS:
      return {
        loading: false,
        createcommunitysavepost: [action.payload],
      };
    case CREATE_COMMUNITY_SAVEPOST_FAILURE:
      return {
        loading: false,
        createcommunitysavepost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateCommunityLikeCommentState = {
  createcommunitylikeComment: [],
  loading: false,
};

export const createCommunityLikeCommentReducer = (
  state = initialCreateCommunityLikeCommentState,
  action
) => {
  switch (action.type) {
    case CREATE_COMMUNITY_LIKECOMMENT_RESET:
      return {
        loading: false,
        createcommunitylikeComment: [],
      };
    case CREATE_COMMUNITY_LIKECOMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMMUNITY_LIKECOMMENT_SUCCESS:
      return {
        loading: false,
        createcommunitylikeComment: [action.payload],
      };
    case CREATE_COMMUNITY_LIKECOMMENT_FAILURE:
      return {
        loading: false,
        createcommunitylikeComment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchCommunityLikedPostState = {
  likedpost: [],
  loading: false,
};

export const fetchCommunityLikedPostReducer = (
  state = initialFetchCommunityLikedPostState,
  action
) => {
  switch (action.type) {
    case FETCH_COMMUNITY_LIKEDPOST_RESET:
      return {
        loading: false,
        likedpost: [],
      };
    case FETCH_COMMUNITY_LIKEDPOST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMMUNITY_LIKEDPOST_SUCCESS:
      return {
        loading: false,
        likedpost: action.payload,
      };
    case FETCH_COMMUNITY_LIKEDPOST_FAILURE:
      return {
        loading: false,
        likedpost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFetchLeaderboardState = {
  lb: [],
  loading: false,
};

export const fetchLeaderboardReducer = (
  state = initialFetchLeaderboardState,
  action
) => {
  switch (action.type) {
    case FETCH_LEADERBOARD_RESET:
      return {
        loading: false,
        lb: [],
      };
    case FETCH_LEADERBOARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LEADERBOARD_SUCCESS:
      return {
        loading: false,
        lb: action.payload,
      };
    case FETCH_LEADERBOARD_FAILURE:
      return {
        loading: false,
        lb: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
