/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";

import routes from "clientRoutes";
import { connect, useDispatch } from "react-redux";
import Plans from "views/pages/Plans";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { studentsLifetimePlan } from "util/DataList";
import { fetchLtpItem } from "redux/ManageLifeTimePlan/ManageLifeTimePlanActions";

const Admin = (props) => {
   const dispatch = useDispatch();

  const history = useHistory()
  const mainContent = React.useRef(null);
  const location = useLocation();

  const [isLoading, setLoading] = useState(true)
  const [isUAS, setUAS] = useState("")
  const [isStudent, setStudent] = useState()

  const [showUpgradeMessage, setShowUpgradeMessage] = useState(true)


  
  useEffect(() => {
    if(props.userDetails.coach === false || props.userDetails.coach == "false" ){
      setStudent(true)
    }
    // if(props.userDetails.admin === false || props.userDetails.admin == "false" ){
    //   setStudent(true)
    // }
  },[props.userDetails])

  useEffect(() => {
    const lockPages = ['mcat-powerbook','chatbot', 'community', 'ideas', 'plans'];
    
    if(lockPages.includes(history.location.pathname.split('/')[2])){
      setShowUpgradeMessage(false)
    } 
    else{
      setShowUpgradeMessage(true)
    }

    // Add a listener for changes
    const unlisten = history.listen((location) => {


      if(lockPages.includes(history.location.pathname.split('/')[2])){
        setShowUpgradeMessage(false)
      } 
      else{
        setShowUpgradeMessage(true)
      }
    });

    // Clean up the listener when the component is unmounted
    return () => {
      unlisten();
    };
  }, [history]);


  

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/client") {
        
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const [subscribe, setSubscribe] = useState('');

  const fetchSubscriptionDetails = () => {
    if(props.userDetails.ultimateAdvisingStudent !== undefined){
      if(props.userDetails.ultimateAdvisingStudent == "true" || props.userDetails.ultimateAdvisingStudent === true || studentsLifetimePlan.includes(props.userDetails.email)){
        setSubscribe('unlock')
        setLoading(false)
      }
      else{

        if(props.fetchDetailsSubscription.fetchdetailssubscription.length){

          const subsDetails = props.fetchDetailsSubscription.fetchdetailssubscription[0]
          
          if(subsDetails.status == "active"){
            setSubscribe('unlock')
             setLoading(false)
          }
          else{
            const date = new Date();
            const secondsSinceEpoch = Math.floor(date.getTime() / 1000);
            

            if(subsDetails.cancel_at_period_end){
              if(secondsSinceEpoch > subsDetails.canceled_at){
                setSubscribe('lock')
                 setLoading(false)
              }
              else{
                setSubscribe('unlock')
                 setLoading(false)
              }
            }
            else{
              setSubscribe('lock')
               setLoading(false)
            }
          }

        }
        else{
          setSubscribe('lock')
           setLoading(false)
        }
      }
    }
  }

  useEffect(() => {

    fetchSubscriptionDetails()

  }, [props.fetchDetailsSubscription, studentsLifetimePlan, props.userDetails ])

  
  useEffect(() => {
      dispatch(fetchLtpItem())
  }, [])
        
  useEffect(() => {
      if (props.fetchLtpItem.ltpitems.length) {
          props.fetchLtpItem.ltpitems.forEach((item)=>{
              if(props.userDetails.email == item.email){
                  console.log(item.email)
                  setSubscribe('unlock')
              }
          })  
      }
  }, [props.fetchLtpItem, props.userDetails]);

  const [isVisible, setIsVisible] = useState(true);

  const overlayStyle = {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0,0,0,0.7)',
      color: 'white',
      textAlign: 'center',
      padding: '10px 0',
      // display: isVisible ? 'block' : 'none',
      minWidth: "100%",
      zIndex: "400"
  };


  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/client/dashboard",
          imgSrc: require("../assets/img/brand/tpc-pre-dental-logo.png"),
          imgSrcWhite: require("../assets/img/brand/tpc-pre-dental-logo-white.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
        //   brandText={getBrandText(props.location.pathname)}
            name={props.userDetails.name}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/client/dashboard" />
        </Switch>
        <Container fluid>
          {/* <AdminFooter /> */}
          
          {
            isLoading === false ? 
              isStudent ? 
                subscribe == "lock" && showUpgradeMessage ? 
                <Container 
                    style={overlayStyle}
                    onMouseEnter={() => setIsVisible(false)}
                    onMouseLeave={() => setIsVisible(true)}
                >
                    You are using a free plan. <a href="#" className={ props.adminUser.admin ? "text-yellow disabled" : "text-yellow"} onClick={e => {e.preventDefault(); history.push("/client/plans")}} >Upgrade to Pro.</a>
                </Container>  : ""
                : ""
            : ""
          }
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  userDetails: state.loginAuth.userDetails,
  fetchDetailsSubscription : state.fetchDetailsSubscription,
  adminUser : state.adminUser,
  fetchLtpItem: state.fetchLtpItem,
})

export default connect(mapStateToProps)(Admin);
