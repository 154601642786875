import {
    CREATE_TPC_COURSE_REQUEST,
    CREATE_TPC_COURSE_FAILURE,
    CREATE_TPC_COURSE_RESET,
    CREATE_TPC_COURSE_SUCCESS,
    FETCH_TPC_COURSES_FAILURE,
    FETCH_TPC_COURSES_REQUEST,
    FETCH_TPC_COURSES_SUCCESS,
    UPDATE_TPC_COURSE_FAILURE,
    UPDATE_TPC_COURSE_REQUEST,
    UPDATE_TPC_COURSE_RESET,
    UPDATE_TPC_COURSE_SUCCESS,
    DELETE_TPC_COURSE_FAILURE,
    DELETE_TPC_COURSE_REQUEST,
    DELETE_TPC_COURSE_RESET,
    DELETE_TPC_COURSE_SUCCESS,

    CREATE_TPC_COURSE_CATEGORY_REQUEST,
    CREATE_TPC_COURSE_CATEGORY_FAILURE,
    CREATE_TPC_COURSE_CATEGORY_RESET,
    CREATE_TPC_COURSE_CATEGORY_SUCCESS,

    CREATE_TPC_COURSE_LESSONS_REQUEST,
    CREATE_TPC_COURSE_LESSONS_FAILURE,
    CREATE_TPC_COURSE_LESSONS_RESET,
    CREATE_TPC_COURSE_LESSONS_SUCCESS,

    UPDATE_TPC_COURSE_CATEGORY_ORDER_REQUEST,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_FAILURE,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_RESET,
    UPDATE_TPC_COURSE_CATEGORY_ORDER_SUCCESS,

    UPDATE_TPC_COURSE_CATEGORY_REQUEST,
    UPDATE_TPC_COURSE_CATEGORY_FAILURE,
    UPDATE_TPC_COURSE_CATEGORY_RESET,
    UPDATE_TPC_COURSE_CATEGORY_SUCCESS,

    UPDATE_TPC_COURSE_LESSONS_REQUEST,
    UPDATE_TPC_COURSE_LESSONS_FAILURE,
    UPDATE_TPC_COURSE_LESSONS_RESET,
    UPDATE_TPC_COURSE_LESSONS_SUCCESS,

    DELETE_TPC_COURSE_LESSONS_REQUEST,
    DELETE_TPC_COURSE_LESSONS_FAILURE,
    DELETE_TPC_COURSE_LESSONS_RESET,
    DELETE_TPC_COURSE_LESSONS_SUCCESS,

    DELETE_TPC_COURSE_CATEGORY_REQUEST,
    DELETE_TPC_COURSE_CATEGORY_FAILURE,
    DELETE_TPC_COURSE_CATEGORY_RESET,
    DELETE_TPC_COURSE_CATEGORY_SUCCESS,

    UPDATE_MARKED_COMPLETE_LESSON_REQUEST,
    UPDATE_MARKED_COMPLETE_LESSON_FAILURE,
    UPDATE_MARKED_COMPLETE_LESSON_RESET,
    UPDATE_MARKED_COMPLETE_LESSON_SUCCESS,

    FETCH_MARKED_COMPLETE_LESSON_REQUEST,
    FETCH_MARKED_COMPLETE_LESSON_FAILURE,
    FETCH_MARKED_COMPLETE_LESSON_RESET,
    FETCH_MARKED_COMPLETE_LESSON_SUCCESS,

    CREATE_BUNDLECOURSE_REQUEST, 
    CREATE_BUNDLECOURSE_SUCCESS, 
    CREATE_BUNDLECOURSE_FAILURE,
    CREATE_BUNDLECOURSE_RESET,

    FETCH_BUNDLECOURSE_REQUEST, 
    FETCH_BUNDLECOURSE_SUCCESS, 
    FETCH_BUNDLECOURSE_FAILURE,
    FETCH_BUNDLECOURSE_RESET, 

    SAVE_COURSES_ORDER_FAILURE,
    SAVE_COURSES_ORDER_REQUEST,
    SAVE_COURSES_ORDER_RESET,
    SAVE_COURSES_ORDER_SUCCESS

} from "./ManageTpcCoursesTypes"
import axios from "axios"

export const createTpcCourseReset = () => {
    return {
        type: CREATE_TPC_COURSE_RESET,
    }
}

const createTpcCourseRequest = () => {
    return {
        type: CREATE_TPC_COURSE_REQUEST,
    }
}

const createTpcCourseSuccess = success => {
    return {
        type: CREATE_TPC_COURSE_SUCCESS,
        payload: success
    }
}

const createTpcCourseFailure = error => {
    return {
        type: CREATE_TPC_COURSE_FAILURE,
        payload : error
    }
}

export const createTpcCourse = (data) => {

    return (dispatch) => {

        dispatch(createTpcCourseRequest());
        
        
        
        axios.post('/createTpcCourse', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const TpcCourse = response.data
            dispatch(createTpcCourseSuccess(TpcCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createTpcCourseFailure(errorMsg))
        })
        
    }

}


const fetchTpcCoursesRequest = () => {
    return {
        type: FETCH_TPC_COURSES_REQUEST,
    }
}

const fetchTpcCoursesSuccess = success => {
    return {
        type: FETCH_TPC_COURSES_SUCCESS,
        payload: success
    }
}

const fetchTpcCoursesFailure = error => {
    return {
        type: FETCH_TPC_COURSES_FAILURE,
        payload : error
    }
}

export const fetchTpcCourses = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchTpcCoursesRequest());
        
        
        axios.post('/getTpcCourses' , data)
        .then(response => {
            const TpcCourses = response.data
            dispatch(fetchTpcCoursesSuccess(TpcCourses))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchTpcCoursesFailure(errorMsg))
        })
        
    }

}


export const updateTpcCourseReset = () => {
    return {
        type: UPDATE_TPC_COURSE_RESET,
    }
}

const updateTpcCourseRequest = () => {
    return {
        type: UPDATE_TPC_COURSE_REQUEST,
    }
}

const updateTpcCourseSuccess = success => {
    return {
        type: UPDATE_TPC_COURSE_SUCCESS,
        payload: success
    }
}

const updateTpcCourseFailure = error => {
    return {
        type: UPDATE_TPC_COURSE_FAILURE,
        payload : error
    }
}

export const updateTpcCourse = (data) => {

    return (dispatch) => {

        dispatch(updateTpcCourseRequest());
        
        
        
        axios.post('/updateTpcCourse', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const TpcCourse = response.data
            dispatch(updateTpcCourseSuccess(TpcCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateTpcCourseFailure(errorMsg))
        })
        
    }

}

export const deleteTpcCourseReset = () => {
    return {
        type: DELETE_TPC_COURSE_RESET,
    }
}

const deleteTpcCourseRequest = () => {
    return {
        type: DELETE_TPC_COURSE_REQUEST,
    }
}

const deleteTpcCourseSuccess = success => {
    return {
        type: DELETE_TPC_COURSE_SUCCESS,
        payload: success
    }
}

const deleteTpcCourseFailure = error => {
    return {
        type: DELETE_TPC_COURSE_FAILURE,
        payload : error
    }
}

export const deleteTpcCourse = (data) => {

    return (dispatch) => {

        dispatch(deleteTpcCourseRequest());
        
        
        
        axios.post('/deleteTpcCourse', data)
        .then(response => {
            const TpcCourse = response.data
            dispatch(deleteTpcCourseSuccess(TpcCourse))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteTpcCourseFailure(errorMsg))
        })
        
    }

}

export const createTpcCourseCategoryReset = () => {
    return {
        type: CREATE_TPC_COURSE_CATEGORY_RESET,
    }
}

const createTpcCourseCategoryRequest = () => {
    return {
        type: CREATE_TPC_COURSE_CATEGORY_REQUEST,
    }
}

const createTpcCourseCategorySuccess = success => {
    return {
        type: CREATE_TPC_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const createTpcCourseCategoryFailure = error => {
    return {
        type: CREATE_TPC_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const createTpcCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(createTpcCourseCategoryRequest());
        
        
        
        axios.post('/createTpcCourseCategory', data)
        .then(response => {
            const TpcCourseCategory = response.data
            dispatch(createTpcCourseCategorySuccess(TpcCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createTpcCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const createTpcCourseLessonsReset = () => {
    return {
        type: CREATE_TPC_COURSE_LESSONS_RESET,
    }
}

const createTpcCourseLessonsRequest = () => {
    return {
        type: CREATE_TPC_COURSE_LESSONS_REQUEST,
    }
}

const createTpcCourseLessonsSuccess = success => {
    return {
        type: CREATE_TPC_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const createTpcCourseLessonsFailure = error => {
    return {
        type: CREATE_TPC_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const createTpcCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(createTpcCourseLessonsRequest());
        
        
        
        axios.post('/createTpcCourseLessons', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const TpcCourseLessons = response.data
            dispatch(createTpcCourseLessonsSuccess(TpcCourseLessons))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createTpcCourseLessonsFailure(errorMsg))
        })
        
    }

}


export const updateTpcCourseCategoryOrderReset = () => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_ORDER_RESET,
    }
}

const updateTpcCourseCategoryOrderRequest = () => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_ORDER_REQUEST,
    }
}

const updateTpcCourseCategoryOrderSuccess = success => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_ORDER_SUCCESS,
        payload: success
    }
}

const updateTpcCourseCategoryOrderFailure = error => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_ORDER_FAILURE,
        payload : error
    }
}

export const updateTpcCourseCategoryOrder = (data) => {

    return (dispatch) => {

        dispatch(updateTpcCourseCategoryOrderRequest());
        
        
        
        axios.post('/updateTpcCourseCategoryOrder', data)
        .then(response => {
            const TpcCourseCategory = response.data
            dispatch(updateTpcCourseCategoryOrderSuccess(TpcCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateTpcCourseCategoryOrderFailure(errorMsg))
        })
        
    }

}


export const updateTpcCourseCategoryReset = () => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_RESET,
    }
}

const updateTpcCourseCategoryRequest = () => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_REQUEST,
    }
}

const updateTpcCourseCategorySuccess = success => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const updateTpcCourseCategoryFailure = error => {
    return {
        type: UPDATE_TPC_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const updateTpcCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(updateTpcCourseCategoryRequest());
        
        
        
        axios.post('/updateTpcCourseCategory', data)
        .then(response => {
            const TpcCourseCategory = response.data
            dispatch(updateTpcCourseCategorySuccess(TpcCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateTpcCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const updateTpcCourseLessonsReset = () => {
    return {
        type: UPDATE_TPC_COURSE_LESSONS_RESET,
    }
}

const updateTpcCourseLessonsRequest = () => {
    return {
        type: UPDATE_TPC_COURSE_LESSONS_REQUEST,
    }
}

const updateTpcCourseLessonsSuccess = success => {
    return {
        type: UPDATE_TPC_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const updateTpcCourseLessonsFailure = error => {
    return {
        type: UPDATE_TPC_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const updateTpcCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(updateTpcCourseLessonsRequest());
        
        
        
        axios.post('/updateTpcCourseLessons', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const TpcCourseCategory = response.data
            dispatch(updateTpcCourseLessonsSuccess(TpcCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateTpcCourseLessonsFailure(errorMsg))
        })
        
    }

}

export const deleteTpcCourseLessonsReset = () => {
    return {
        type: DELETE_TPC_COURSE_LESSONS_RESET,
    }
}

const deleteTpcCourseLessonsRequest = () => {
    return {
        type: DELETE_TPC_COURSE_LESSONS_REQUEST,
    }
}

const deleteTpcCourseLessonsSuccess = success => {
    return {
        type: DELETE_TPC_COURSE_LESSONS_SUCCESS,
        payload: success
    }
}

const deleteTpcCourseLessonsFailure = error => {
    return {
        type: DELETE_TPC_COURSE_LESSONS_FAILURE,
        payload : error
    }
}

export const deleteTpcCourseLessons = (data) => {

    return (dispatch) => {

        dispatch(deleteTpcCourseLessonsRequest());
        
        
        
        axios.post('/deleteTpcCourseLessons', data)
        .then(response => {
            const TpcCourseLessons = response.data
            dispatch(deleteTpcCourseLessonsSuccess(TpcCourseLessons))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteTpcCourseLessonsFailure(errorMsg))
        })
        
    }

}

export const deleteTpcCourseCategoryReset = () => {
    return {
        type: DELETE_TPC_COURSE_CATEGORY_RESET,
    }
}

const deleteTpcCourseCategoryRequest = () => {
    return {
        type: DELETE_TPC_COURSE_CATEGORY_REQUEST,
    }
}

const deleteTpcCourseCategorySuccess = success => {
    return {
        type: DELETE_TPC_COURSE_CATEGORY_SUCCESS,
        payload: success
    }
}

const deleteTpcCourseCategoryFailure = error => {
    return {
        type: DELETE_TPC_COURSE_CATEGORY_FAILURE,
        payload : error
    }
}

export const deleteTpcCourseCategory = (data) => {

    return (dispatch) => {

        dispatch(deleteTpcCourseCategoryRequest());
        
        
        
        axios.post('/deleteTpcCourseCategory', data)
        .then(response => {
            const TpcCourseCategory = response.data
            dispatch(deleteTpcCourseCategorySuccess(TpcCourseCategory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteTpcCourseCategoryFailure(errorMsg))
        })
        
    }

}

export const updateMarkedCompleteLessonReset = () => {
    return {
        type: UPDATE_MARKED_COMPLETE_LESSON_RESET,
    }
}

const updateMarkedCompleteLessonRequest = () => {
    return {
        type: UPDATE_MARKED_COMPLETE_LESSON_REQUEST,
    }
}

const updateMarkedCompleteLessonSuccess = success => {
    return {
        type: UPDATE_MARKED_COMPLETE_LESSON_SUCCESS,
        payload: success
    }
}

const updateMarkedCompleteLessonFailure = error => {
    return {
        type: UPDATE_MARKED_COMPLETE_LESSON_FAILURE,
        payload : error
    }
}

export const updateMarkedCompleteLesson = (data) => {

    return (dispatch) => {

        dispatch(updateMarkedCompleteLessonRequest());
        
        
        
        axios.post('/updateMarkedCompleteLesson', data)
        .then(response => {
            const MarkedCompleteLesson = response.data
            dispatch(updateMarkedCompleteLessonSuccess(MarkedCompleteLesson))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateMarkedCompleteLessonFailure(errorMsg))
        })
        
    }

}

export const fetchMarkedCompleteLessonReset = () => {
    return {
        type: FETCH_MARKED_COMPLETE_LESSON_RESET,
    }
}

const fetchMarkedCompleteLessonRequest = () => {
    return {
        type: FETCH_MARKED_COMPLETE_LESSON_REQUEST,
    }
}

const fetchMarkedCompleteLessonSuccess = success => {
    return {
        type: FETCH_MARKED_COMPLETE_LESSON_SUCCESS,
        payload: success
    }
}

const fetchMarkedCompleteLessonFailure = error => {
    return {
        type: FETCH_MARKED_COMPLETE_LESSON_FAILURE,
        payload : error
    }
}

export const fetchMarkedCompleteLesson = (data) => {

    return (dispatch) => {

        dispatch(fetchMarkedCompleteLessonRequest());
        
        
        
        axios.post('/fetchMarkedCompleteLesson', data)
        .then(response => {
            const MarkedCompleteLesson = response.data
            dispatch(fetchMarkedCompleteLessonSuccess(MarkedCompleteLesson))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchMarkedCompleteLessonFailure(errorMsg))
        })
        
    }

}

export const createBundleCoursesReset = () => {
    return {
        type: CREATE_BUNDLECOURSE_RESET,
    }
}

const createBundleCoursesRequest = () => {
    return {
        type: CREATE_BUNDLECOURSE_REQUEST,
    }
}

const createBundleCoursesSuccess = success => {
    return {
        type: CREATE_BUNDLECOURSE_SUCCESS,
        payload: success
    }
}

const createBundleCoursesFailure = error => {
    return {
        type: CREATE_BUNDLECOURSE_FAILURE,
        payload : error
    }
}

export const createBundleCourses = (data) => {

    return (dispatch) => {

        dispatch(createBundleCoursesRequest());

        axios.post('/createBundleCourses', data)
        .then(response => {
            const bundles = response.data
            dispatch(createBundleCoursesSuccess(bundles))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createBundleCoursesFailure(errorMsg))
        })
        
    }

}

export const fetchBundleCoursesReset = () => {
    return {
        type: FETCH_BUNDLECOURSE_RESET,
    }
}

const fetchBundleCoursesRequest = () => {
    return {
        type: FETCH_BUNDLECOURSE_REQUEST,
    }
}

const fetchBundleCoursesSuccess = success => {
    return {
        type: FETCH_BUNDLECOURSE_SUCCESS,
        payload: success
    }
}

const fetchBundleCoursesFailure = error => {
    return {
        type: FETCH_BUNDLECOURSE_FAILURE,
        payload : error
    }
}

export const fetchBundleCourses = (data) => {

    return (dispatch) => {

        dispatch(fetchBundleCoursesRequest());
        
        axios.post('/fetchBundleCourses', data)
        .then(response => {
            const bundleCourses = response.data
            dispatch(fetchBundleCoursesSuccess(bundleCourses))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchBundleCoursesFailure(errorMsg))
        })
        
    }

}

export const saveCoursesOrderReset = () => {
    return {
        type: SAVE_COURSES_ORDER_RESET,
    }
}

const saveCoursesOrderRequest = () => {
    return {
        type: SAVE_COURSES_ORDER_REQUEST,
    }
}

const saveCoursesOrderSuccess = success => {
    return {
        type: SAVE_COURSES_ORDER_SUCCESS,
        payload: success
    }
}

const saveCoursesOrderFailure = error => {
    return {
        type: SAVE_COURSES_ORDER_FAILURE,
        payload : error
    }
}

export const saveCoursesOrder = (data) => {

    return (dispatch) => {

        dispatch(saveCoursesOrderRequest());
        
        axios.post('/saveCoursesOrder', data)
        .then(response => {
            const coursesOrder = response.data
            dispatch(saveCoursesOrderSuccess(coursesOrder))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(saveCoursesOrderFailure(errorMsg))
        })
        
    }

}

