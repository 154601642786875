import React, {useEffect} from 'react'
import Header from 'components/Headers/Header'
import {useForm} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup
} from "reactstrap";
import { personalStatement, personalStatementReset, fetchPersonalStatement } from 'redux/PersonalStatement/PersonalStatementActions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import Plans from './Plans';
const PersonalStatement = (props) => {

    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    
    useEffect(() => {
        register("inputPersonalStatement");
        dispatch(fetchPersonalStatement({handle: props.userDetails.handle}))
        
        const statement_val = typeof props.fetchPersonalStatement.personalstatement.statement !== "undefined" ? props.fetchPersonalStatement.personalstatement.statement : "";
        setValue("inputPersonalStatement", statement_val)
            

    }, [props.fetchPersonalStatement.personalstatement.statement,dispatch]);

    const onSubmit = (data) => {

        const userData = {
            handle: data.email,
            statement: data.inputPersonalStatement
        }

        dispatch(personalStatement(userData));
        
    }

    if(props.personalStatement.loading){
        Swal.fire({
          allowOutsideClick : false,
          didOpen: () => {
            Swal.showLoading()
            
          }
        });
    
      }
      else{
      
        
        if(props.personalStatement.personalstatement.length && typeof props.personalStatement.error === "undefined"){
          Swal.fire({
              title: 'Success!',
              text: 'Personal statement successfully save!',
              icon : 'success',
              timer: 3000
              
            }
          )
    
          dispatch(personalStatementReset());
          
        }
    
        else if(typeof props.personalStatement.error !== "undefined"){
    
         
            Swal.fire({
              title:'Failed!',
              text:'Something went wrong, Please try again!',
              icon :'error',
              timer: 3000
              
            })
          
              
            dispatch(personalStatementReset());       
          
        }
    
      }
      const countWords = (str) => {
        str = str.trim();
  
        // Return 0 if the string is empty
        if (str === "") {
          return 0;
        }
        
        // Split the string into an array of words using whitespace as the delimiter
        const words = str.split(/\s+/);
        
        // Return the number of words
        return words.length;
  
      }
    return (
        <>
            <Header></Header>
                <Container className="mt--7" fluid>
                <Row>
                    <div className="col">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <div className='float-left'>
                                        <h5 className="h3 mb-0">Personal Statement</h5>
                                    </div>
                                    <div className='float-right'>
                                        <Button color="primary" disabled={props.adminUser.admin ? "true" : ""} type='submit'>Save</Button>
                                    </div>
                                </CardHeader>
                                <Row className="pl-4 pr-4">
                                    <Col lg='12'>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                              <span className='text-danger'>*</span> The response you enter below will appear in your application exactly as you type it. Using all capital letters or not capitalizing properly does not present a professional image to your application. Do NOT personalize your Personal Statement for a specific dental school. You can NOT make any edits to your Personal Statement after you have e-submitted your completed application to ADEA AADSAS.
                                                
                                            </label>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAchievementIssuedDate"
                                            >
                                                Please explain why you want to pursue a dental career.
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="inputPersonalStatement"
                                                name="inputPersonalStatement"
                                                placeholder="Write a text here ..."
                                                rows="15"
                                                value={watch("inputPersonalStatement")}
                                                onChange={e => setValue("inputPersonalStatement", e.target.value)}
                                                type="textarea"
                                                maxLength="4500"
                                            />
                                            <div className='text-sm'>Character count : {(typeof getValues("inputPersonalStatement") == "undefined")  ? "0" : getValues("inputPersonalStatement").length}/4500 | Word Count : {countWords((typeof getValues("inputPersonalStatement") == "undefined")  ? "0" : getValues("inputPersonalStatement"))}</div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </Form>
                    </div>
                </Row>
            </Container>
            <Plans></Plans>
        </>
    )
}

const mapStateToProps = state => {
    return {
        personalStatement : state.personalStatement,
        userDetails : state.loginAuth.userDetails,
        fetchPersonalStatement : state.fetchPersonalStatement,
        adminUser : state.adminUser
    }
}

export default connect(mapStateToProps)(PersonalStatement)