import {
  CREATE_PROMEMBER_POST_REQUEST,
  CREATE_PROMEMBER_POST_SUCCESS,
  CREATE_PROMEMBER_POST_FAILURE,
  CREATE_PROMEMBER_POST_RESET,
  UPDATE_PROMEMBER_POST_REQUEST,
  UPDATE_PROMEMBER_POST_SUCCESS,
  UPDATE_PROMEMBER_POST_FAILURE,
  UPDATE_PROMEMBER_POST_RESET,
  DELETE_PROMEMBER_POST_REQUEST,
  DELETE_PROMEMBER_POST_SUCCESS,
  DELETE_PROMEMBER_POST_FAILURE,
  DELETE_PROMEMBER_POST_RESET,
  FETCH_ALL_PROMEMBER_POSTS_RESET,
  FETCH_ALL_PROMEMBER_POSTS_REQUEST,
  FETCH_ALL_PROMEMBER_POSTS_FAILURE,
  FETCH_ALL_PROMEMBER_POSTS_SUCCESS,
  CREATE_PROMEMBER_COMMENT_REQUEST,
  CREATE_PROMEMBER_COMMENT_SUCCESS,
  CREATE_PROMEMBER_COMMENT_FAILURE,
  CREATE_PROMEMBER_COMMENT_RESET,
  UPDATE_PROMEMBER_COMMENT_REQUEST,
  UPDATE_PROMEMBER_COMMENT_SUCCESS,
  UPDATE_PROMEMBER_COMMENT_FAILURE,
  UPDATE_PROMEMBER_COMMENT_RESET,
  DELETE_PROMEMBER_COMMENT_REQUEST,
  DELETE_PROMEMBER_COMMENT_SUCCESS,
  DELETE_PROMEMBER_COMMENT_FAILURE,
  DELETE_PROMEMBER_COMMENT_RESET,
  CREATE_PROMEMBER_REPLY_REQUEST,
  CREATE_PROMEMBER_REPLY_SUCCESS,
  CREATE_PROMEMBER_REPLY_FAILURE,
  CREATE_PROMEMBER_REPLY_RESET,
  UPDATE_PROMEMBER_REPLY_REQUEST,
  UPDATE_PROMEMBER_REPLY_SUCCESS,
  UPDATE_PROMEMBER_REPLY_FAILURE,
  UPDATE_PROMEMBER_REPLY_RESET,
  DELETE_PROMEMBER_REPLY_REQUEST,
  DELETE_PROMEMBER_REPLY_SUCCESS,
  DELETE_PROMEMBER_REPLY_FAILURE,
  DELETE_PROMEMBER_REPLY_RESET,
  CREATE_PROMEMBER_LIKEPOST_REQUEST,
  CREATE_PROMEMBER_LIKEPOST_SUCCESS,
  CREATE_PROMEMBER_LIKEPOST_FAILURE,
  CREATE_PROMEMBER_LIKEPOST_RESET,
  CREATE_PROMEMBER_SAVEPOST_REQUEST,
  CREATE_PROMEMBER_SAVEPOST_SUCCESS,
  CREATE_PROMEMBER_SAVEPOST_FAILURE,
  CREATE_PROMEMBER_SAVEPOST_RESET,
  CREATE_PROMEMBER_LIKECOMMENT_REQUEST,
  CREATE_PROMEMBER_LIKECOMMENT_SUCCESS,
  CREATE_PROMEMBER_LIKECOMMENT_FAILURE,
  CREATE_PROMEMBER_LIKECOMMENT_RESET,
  FETCH_PROMEMBER_LIKEDPOST_RESET,
  FETCH_PROMEMBER_LIKEDPOST_REQUEST,
  FETCH_PROMEMBER_LIKEDPOST_FAILURE,
  FETCH_PROMEMBER_LIKEDPOST_SUCCESS,
} from "./ProMemberType";

import axios from "axios";

export const createProMemberPostReset = () => {
  return {
    type: CREATE_PROMEMBER_POST_RESET,
  };
};

const createProMemberPostRequest = () => {
  return {
    type: CREATE_PROMEMBER_POST_REQUEST,
  };
};

const createProMemberPostSuccess = (success) => {
  return {
    type: CREATE_PROMEMBER_POST_SUCCESS,
    payload: success,
  };
};

const createProMemberPostFailure = (error) => {
  return {
    type: CREATE_PROMEMBER_POST_FAILURE,
    payload: error,
  };
};

export const createProMemberPost = (data) => {
  return (dispatch) => {
    dispatch(createProMemberPostRequest());
    axios
      .post("/createProMemberPost", data)
      .then((response) => {
        const createProMemberPost = response.data;
        dispatch(createProMemberPostSuccess(createProMemberPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createProMemberPostFailure(errorMsg));
      });
  };
};

export const updateCommunityProMemberPostReset = () => {
  return {
    type: UPDATE_PROMEMBER_POST_RESET,
  };
};

const updateCommunityProMemberPostRequest = () => {
  return {
    type: UPDATE_PROMEMBER_POST_REQUEST,
  };
};

const updateCommunityProMemberPostSuccess = (success) => {
  return {
    type: UPDATE_PROMEMBER_POST_SUCCESS,
    payload: success,
  };
};

const updateCommunityProMemberPostFailure = (error) => {
  return {
    type: UPDATE_PROMEMBER_POST_FAILURE,
    payload: error,
  };
};

export const updateCommunityProMemberPost = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityProMemberPostRequest());
    axios
      .post("/updateCommunityProMemberPost", data)
      .then((response) => {
        const updateCommunityProMemberPost = response.data;
        dispatch(
          updateCommunityProMemberPostSuccess(updateCommunityProMemberPost)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityProMemberPostFailure(errorMsg));
      });
  };
};

export const deleteCommunityProMemberPostReset = () => {
  return {
    type: DELETE_PROMEMBER_POST_RESET,
  };
};

const deleteCommunityProMemberPostRequest = () => {
  return {
    type: DELETE_PROMEMBER_POST_REQUEST,
  };
};

const deleteCommunityProMemberPostSuccess = (success) => {
  return {
    type: DELETE_PROMEMBER_POST_SUCCESS,
    payload: success,
  };
};

const deleteCommunityProMemberPostFailure = (error) => {
  return {
    type: DELETE_PROMEMBER_POST_FAILURE,
    payload: error,
  };
};

export const deleteCommunityProMemberPost = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityProMemberPostRequest());
    axios
      .post("/deleteCommunityProMemberPost", data)
      .then((response) => {
        const deleteCommunityProMemberPost = response.data;
        dispatch(
          deleteCommunityProMemberPostSuccess(deleteCommunityProMemberPost)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityProMemberPostFailure(errorMsg));
      });
  };
};

export const createCommunityProMemberLikePostReset = () => {
  return {
    type: CREATE_PROMEMBER_LIKEPOST_RESET,
  };
};

const createCommunityProMemberLikePostRequest = () => {
  return {
    type: CREATE_PROMEMBER_LIKEPOST_REQUEST,
  };
};

const createCommunityProMemberLikePostSuccess = (success) => {
  return {
    type: CREATE_PROMEMBER_LIKEPOST_SUCCESS,
    payload: success,
  };
};

const createCommunityProMemberLikePostFailure = (error) => {
  return {
    type: CREATE_PROMEMBER_LIKEPOST_FAILURE,
    payload: error,
  };
};

export const createCommunityProMemberLikePost = (data) => {
  return (dispatch) => {
    dispatch(createCommunityProMemberLikePostRequest());
    axios
      .post("/createCommunityProMemberLikePost", data)
      .then((response) => {
        const createCommunityProMemberLikePost = response.data;
        dispatch(
          createCommunityProMemberLikePostSuccess(
            createCommunityProMemberLikePost
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityProMemberLikePostFailure(errorMsg));
      });
  };
};

export const createCommunityProMemberCommentReset = () => {
  return {
    type: CREATE_PROMEMBER_COMMENT_RESET,
  };
};

const createCommunityProMemberCommentRequest = () => {
  return {
    type: CREATE_PROMEMBER_COMMENT_REQUEST,
  };
};

const createCommunityProMemberCommentSuccess = (success) => {
  return {
    type: CREATE_PROMEMBER_COMMENT_SUCCESS,
    payload: success,
  };
};

const createCommunityProMemberCommentFailure = (error) => {
  return {
    type: CREATE_PROMEMBER_COMMENT_FAILURE,
    payload: error,
  };
};

export const createCommunityProMemberComment = (data) => {
  return (dispatch) => {
    dispatch(createCommunityProMemberCommentRequest());

    axios
      .post("/createCommunityProMemberComment", data)
      .then((response) => {
        const createCommunityProMemberComment = response.data;
        dispatch(
          createCommunityProMemberCommentSuccess(
            createCommunityProMemberComment
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityProMemberCommentFailure(errorMsg));
      });
  };
};

export const updateCommunityProMemberCommentReset = () => {
  return {
    type: UPDATE_PROMEMBER_COMMENT_RESET,
  };
};

const updateCommunityProMemberCommentRequest = () => {
  return {
    type: UPDATE_PROMEMBER_COMMENT_REQUEST,
  };
};

const updateCommunityProMemberCommentSuccess = (success) => {
  return {
    type: UPDATE_PROMEMBER_COMMENT_SUCCESS,
    payload: success,
  };
};

const updateCommunityProMemberCommentFailure = (error) => {
  return {
    type: UPDATE_PROMEMBER_COMMENT_FAILURE,
    payload: error,
  };
};

export const updateCommunityProMemberComment = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityProMemberCommentRequest());

    axios
      .post("/updateCommunityProMemberComment", data)
      .then((response) => {
        const updateCommunityProMemberComment = response.data;
        dispatch(
          updateCommunityProMemberCommentSuccess(
            updateCommunityProMemberComment
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityProMemberCommentFailure(errorMsg));
      });
  };
};

export const deleteCommunityProMemberCommentReset = () => {
  return {
    type: DELETE_PROMEMBER_COMMENT_RESET,
  };
};

const deleteCommunityProMemberCommentRequest = () => {
  return {
    type: DELETE_PROMEMBER_COMMENT_REQUEST,
  };
};

const deleteCommunityProMemberCommentSuccess = (success) => {
  return {
    type: DELETE_PROMEMBER_COMMENT_SUCCESS,
    payload: success,
  };
};

const deleteCommunityProMemberCommentFailure = (error) => {
  return {
    type: DELETE_PROMEMBER_COMMENT_FAILURE,
    payload: error,
  };
};

export const deleteCommunityProMemberComment = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityProMemberCommentRequest());
    axios
      .post("/deleteCommunityProMemberComment", data)
      .then((response) => {
        const deleteCommunityProMemberComment = response.data;
        dispatch(
          deleteCommunityProMemberCommentSuccess(
            deleteCommunityProMemberComment
          )
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityProMemberCommentFailure(errorMsg));
      });
  };
};

const createCommunityProMemberReplyRequest = () => {
  return {
    type: CREATE_PROMEMBER_REPLY_REQUEST,
  };
};

const createCommunityProMemberReplySuccess = (success) => {
  return {
    type: CREATE_PROMEMBER_REPLY_SUCCESS,
    payload: success,
  };
};

const createCommunityProMemberReplyFailure = (error) => {
  return {
    type: CREATE_PROMEMBER_REPLY_FAILURE,
    payload: error,
  };
};

export const createCommunityProMemberReply = (data) => {
  return (dispatch) => {
    dispatch(createCommunityProMemberReplyRequest());
    axios
      .post("/createCommunityProMemberReply", data)
      .then((response) => {
        const createCommunityProMemberReply = response.data;
        dispatch(
          createCommunityProMemberReplySuccess(createCommunityProMemberReply)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityProMemberReplyFailure(errorMsg));
      });
  };
};
export const updateCommunityProMemberReplyReset = () => {
  return {
    type: UPDATE_PROMEMBER_REPLY_RESET,
  };
};

const updateCommunityProMemberReplyRequest = () => {
  return {
    type: UPDATE_PROMEMBER_REPLY_REQUEST,
  };
};

const updateCommunityProMemberReplySuccess = (success) => {
  return {
    type: UPDATE_PROMEMBER_REPLY_SUCCESS,
    payload: success,
  };
};

const updateCommunityProMemberReplyFailure = (error) => {
  return {
    type: UPDATE_PROMEMBER_REPLY_FAILURE,
    payload: error,
  };
};

export const updateCommunityProMemberReply = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityProMemberReplyRequest());

    axios
      .post("/updateCommunityProMemberReply", data)
      .then((response) => {
        const updateCommunityProMemberReply = response.data;
        dispatch(
          updateCommunityProMemberReplySuccess(updateCommunityProMemberReply)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityProMemberReplyFailure(errorMsg));
      });
  };
};

export const deleteCommunityProMemberReplyReset = () => {
  return {
    type: DELETE_PROMEMBER_REPLY_RESET,
  };
};

const deleteCommunityProMemberReplyRequest = () => {
  return {
    type: DELETE_PROMEMBER_REPLY_REQUEST,
  };
};

const deleteCommunityProMemberReplySuccess = (success) => {
  return {
    type: DELETE_PROMEMBER_REPLY_SUCCESS,
    payload: success,
  };
};

const deleteCommunityProMemberReplyFailure = (error) => {
  return {
    type: DELETE_PROMEMBER_REPLY_FAILURE,
    payload: error,
  };
};

export const deleteCommunityProMemberReply = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityProMemberReplyRequest());
    axios
      .post("/deleteCommunityProMemberReply", data)
      .then((response) => {
        const deleteCommunityProMemberReply = response.data;
        dispatch(
          deleteCommunityProMemberReplySuccess(deleteCommunityProMemberReply)
        );
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityProMemberReplyFailure(errorMsg));
      });
  };
};
