import { 
    FETCH_QUESTION_BANK_FAILURE, 
    FETCH_QUESTION_BANK_REQUEST, 
    FETCH_QUESTION_BANK_SUCCESS,
    FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE, 
    FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST, 
    FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS,
    CREATE_PRACTICE_TEST_RESULT_FAILURE,
    CREATE_PRACTICE_TEST_RESULT_REQUEST,
    CREATE_PRACTICE_TEST_RESULT_RESET,
    CREATE_PRACTICE_TEST_RESULT_SUCCESS

} from "./DATTypes"
import axios from "axios";

const fetchQuestionBankRequest = () => {
    return {
        type: FETCH_QUESTION_BANK_REQUEST,
    }
}

const fetchQuestionBankSuccess = success => {
    return {
        type: FETCH_QUESTION_BANK_SUCCESS,
        payload: success
    }
}

const fetchQuestionBankFailure = error => {
    return {
        type: FETCH_QUESTION_BANK_FAILURE,
        payload : error
    }
}

export const fetchQuestionBank = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchQuestionBankRequest());
        
        
        axios.post('/getQuestionBank' , data)
        .then(response => {
            const questionBank = response.data
            dispatch(fetchQuestionBankSuccess(questionBank))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchQuestionBankFailure(errorMsg))
        })
        
    }

}


export const createPracticeTestResultReset = () => {
    return {
        type: CREATE_PRACTICE_TEST_RESULT_RESET,
    }
}

const createPracticeTestResultRequest = () => {
    return {
        type: CREATE_PRACTICE_TEST_RESULT_REQUEST,
    }
}

const createPracticeTestResultSuccess = success => {
    return {
        type: CREATE_PRACTICE_TEST_RESULT_SUCCESS,
        payload: success
    }
}

const createPracticeTestResultFailure = error => {
    return {
        type: CREATE_PRACTICE_TEST_RESULT_FAILURE,
        payload : error
    }
}

export const createPracticeTestResult = (data) => {

    return (dispatch) => {

        dispatch(createPracticeTestResultRequest());
        
        
        
        axios.post('/createPracticeTestResult', data)
        .then(response => {
            const PracticeTestResult = response.data
            dispatch(createPracticeTestResultSuccess(PracticeTestResult))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createPracticeTestResultFailure(errorMsg))
        })
        
    }

}

const fetchPracticeTestAttemptsRequest = () => {
    return {
        type: FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST,
    }
}

const fetchPracticeTestAttemptsSuccess = success => {
    return {
        type: FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS,
        payload: success
    }
}

const fetchPracticeTestAttemptsFailure = error => {
    return {
        type: FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE,
        payload : error
    }
}

export const fetchPracticeTestAttempts = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchPracticeTestAttemptsRequest());
        
        
        axios.post('/getPracticeTestAttempts' , data)
        .then(response => {
            const questionBank = response.data
            dispatch(fetchPracticeTestAttemptsSuccess(questionBank))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchPracticeTestAttemptsFailure(errorMsg))
        })
        
    }

}