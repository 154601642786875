import {
    CREATE_REPORTED_ISSUE_REQUEST, 
    CREATE_REPORTED_ISSUE_SUCCESS, 
    CREATE_REPORTED_ISSUE_FAILURE, 
    CREATE_REPORTED_ISSUE_RESET,

} from './SupportTypes'

const initialReportedIssueState = {
    reportedissue: [],
    loading : false
}

export const createReportedIssueReducer = (state = initialReportedIssueState, action) => {
    switch(action.type){
        case CREATE_REPORTED_ISSUE_RESET:
            return {
                loading: false,
                reportedissue: []
            }
        case CREATE_REPORTED_ISSUE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_REPORTED_ISSUE_SUCCESS:
            return {
                loading: false,
                reportedissue: [action.payload]
            }
        case CREATE_REPORTED_ISSUE_FAILURE:
            return {
                loading : false,
                reportedissue : [],
                error: action.payload
            }
        default: return state;
    }
}