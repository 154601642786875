import { FETCH_SCHOOL_SEARCH_FAILURE, FETCH_SCHOOL_SEARCH_SUCCESS, FETCH_SCHOOL_SEARCH_REQUEST } from "./SchoolSearchTypes"

const initialFetchSchoolSearchState = {
    schoolsearch : [],
    loading: false,

}

export const fetchSchoolSearchReducer = (state = initialFetchSchoolSearchState, action) => {
    switch (action.type) {
        case FETCH_SCHOOL_SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SCHOOL_SEARCH_SUCCESS:
            return {
                loading: false,
                schoolsearch: action.payload
            }
        case FETCH_SCHOOL_SEARCH_FAILURE:
            return {
                loading: false,
                schoolsearch: [],
                error: action.payload
            }
        default: return state;
    }
}