import React, {useRef, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';

import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    Badge,
    Input,
    Image,
    Form,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    TabContent, TabPane, Nav, NavItem, NavLink, 
} from "reactstrap";

import classnames from 'classnames';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { getUserData } from 'redux/UserAuthentication/LoginActions';
import ProMemberPost from 'components/Community/ProMemberPost';

const SearchPost = (props) => {

    const [posts, setPost] = useState([])
    useEffect(() => {
        setPost(props.items)
    }, [props.items]);

  return (
      <>
          <Row className=" mt-3 justify-content-center">
              {/* <Col lg='8' >  <p className="mb-3">Matched Posts: { posts.length}</p></Col> */}
             
              <Col lg='8' className="p-0" >
                 
                  
        {
            posts.length > 0 ?
                  posts.map((val, key) => {
                    return (
                        <ProMemberPost key={val.postid} item={val} />
                      )
                  })      
                :
            ''
              }
              </Col>
        </Row>
        
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }   
}

export default connect(mapStateToProps)(SearchPost)

