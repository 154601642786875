export const CREATE_PROFILE_PICTURE_REQUEST = 'CREATE_PROFILE_PICTURE_REQUEST';
export const CREATE_PROFILE_PICTURE_SUCCESS = 'CREATE_PROFILE_PICTURE_SUCCESS';
export const CREATE_PROFILE_PICTURE_FAILURE = 'CREATE_PROFILE_PICTURE_FAILURE';
export const CREATE_PROFILE_PICTURE_RESET = 'CREATE_PROFILE_PICTURE_RESET';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const UPDATE_USER_PROFILE_RESET = 'UPDATE_USER_PROFILE_RESET';

export const FETCH_UNIVERSITIES_REQUEST = 'FETCH_UNIVERSITIES_REQUEST';
export const FETCH_UNIVERSITIES_SUCCESS = 'FETCH_UNIVERSITIES_SUCCESS';
export const FETCH_UNIVERSITIES_FAILURE = 'FETCH_UNIVERSITIES_FAILURE';

export const UPDATE_USER_PHONE_NUMBER_REQUEST = 'UPDATE_USER_PHONE_NUMBER_REQUEST';
export const UPDATE_USER_PHONE_NUMBER_SUCCESS = 'UPDATE_USER_PHONE_NUMBER_SUCCESS';
export const UPDATE_USER_PHONE_NUMBER_FAILURE = 'UPDATE_USER_PHONE_NUMBER_FAILURE';
export const UPDATE_USER_PHONE_NUMBER_RESET = 'UPDATE_USER_PHONE_NUMBER_RESET';

export const UPDATE_USER_COMMUNITY_SETTINGS_REQUEST = 'UPDATE_USER_COMMUNITY_SETTINGS_REQUEST';
export const UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS = 'UPDATE_USER_COMMUNITY_SETTINGS_SUCCESS';
export const UPDATE_USER_COMMUNITY_SETTINGS_FAILURE = 'UPDATE_USER_COMMUNITY_SETTINGS_FAILURE';
export const UPDATE_USER_COMMUNITY_SETTINGS_RESET = 'UPDATE_USER_COMMUNITY_SETTINGS_RESET';