import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { collection,getDocs, query, where, onSnapshot, doc} from "firebase/firestore";
import {
    Badge,
} from "reactstrap";
const UserWithImage = (props) => {
    
    const [userArr, setUserInfo] = useState([]);


    useEffect(() => {
         const fetchUser = async () => {
                const userCollection = collection(db, "users");
                const getUser = query(userCollection, where("handle", "==", props.userHandle));
                const querySnapshot = await getDocs(getUser);
                querySnapshot.forEach((doc) => {
                    let user = doc.data()
                    setUserInfo(user)
                });
            }
            fetchUser()
    }, [props])

    return (
         
        <>
            {
                userArr ?
                    <div className="media m-1">
                        {
                            (typeof userArr.profilePicture != "undefined") ?
                                <img  src={ 'https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/'+userArr.profilePicture }  className="mr-3 rounded-circle" alt="..."  style={{
                                width: '30px',
                                height: '30px'
                                }} />
                                :
                            <div className="rounded-circle profile-letter-all-users mr-3 " style={{ width: '30px', height: '30px' }}>
                                {
                                    userArr.name ?   userArr.name.trim()[0] : ''
                                    
                                }
                            </div>    
                        }
                            
                        <div className=" d-flex align-items-center ">
                            <h5 className="mt-0 mb-0">{userArr.name ? userArr.name : ''}  {userArr.coach || userArr.coach == 'true' ?  <Badge className="ml-2 text-dark"  color="info" pill>Coach</Badge> : ''}</h5>
                        </div>
                    </div>
                : ''
            }     
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
       
    }  
}

export default connect(mapStateToProps)(UserWithImage)
