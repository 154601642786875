import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector  } from 'react-redux';
import { createAnswersForSecondaryEssays,createAnswersForSecondaryEssaysReset, deleteResponseForSecondaryEssay, deleteResponseForSecondaryEssayReset } from 'redux/SecondaryEssays/SecondaryEssaysActions';
import Swal from 'sweetalert2'
import ReactPlayer from 'react-player';
import { Col, Row } from 'reactstrap';

const SecondaryEssaysAccordion = (props) => {

    const dispatch = useDispatch();
    const [items, setItems] = useState([{ 
        filename : '',
        secondaryEssaysId : '',
        downloadUrl: '',
        title: '', 
        body: '', 
        schoolSearchId : '',
        userId : '',
        video : '',
        docId: '', 
        collapsed: true, 
        updatedAt: '', 
    }]);

    const [dataIndex, setDataIndex] = useState();
    const [delDataIndex, setDelDataIndex] = useState();

    const addItem = () => {
        setItems([...items, { 
            filename : '',
            secondaryEssaysId : '',
            downloadUrl: '',
            title: '', 
            body: '', 
            schoolSearchId : '',
            userId : '',
            video : '',
            docId: '', 
            collapsed: true, 
            updatedAt: '',
         }]);

        
    };



    useEffect(() => {
        if(props.fetchResponseForSecondaryEssays.responseforsecondaryessays.length && typeof props.fetchResponseForSecondaryEssays.error === "undefined"){
           
            let updatedArray = [];
            props.fetchResponseForSecondaryEssays.responseforsecondaryessays.map((item, index) => {
                
                if(item.schoolSearchId == props.schoolSearchId && item.secondaryEssaysId == props.secondaryEssaysId ){
                    
                    updatedArray = [...updatedArray , { 
                        filename : item.filename,
                        secondaryEssaysId : item.secondaryEssaysId,
                        downloadUrl: item.downloadUrl,
                        title: item.title, 
                        body: item.body, 
                        schoolSearchId : item.schoolSearchId,
                        userId : item.userId,
                        video : '',
                        docId: item.responseSecondaryEssayId, 
                        collapsed: true, 
                        updatedAt: item.updatedAt,
                    }]
                    
                    
                }
                
            });
            if(updatedArray.length){
                setItems([...updatedArray ]);
            }
            else{
                
                setItems([...[{ 
                    filename : '',
                    secondaryEssaysId : '',
                    downloadUrl: '',
                    title: '', 
                    body: '', 
                    schoolSearchId : '',
                    userId : '',
                    video : '',
                    docId: '', 
                    collapsed: true, 
                    updatedAt: '', 
                }]])
                
            }
            
            
        }
    }, [props.fetchResponseForSecondaryEssays.responseforsecondaryessays])


    const countWords = (str) => {
        str = str.trim();
  
        // Return 0 if the string is empty
        if (str === "") {
          return 0;
        }
        
        // Split the string into an array of words using whitespace as the delimiter
        const words = str.split(/\s+/);
        
        // Return the number of words
        return words.length;

    }
    useEffect(() => {
        if(props.createAnswersForSecondaryEssays.loading){
            Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
                
              }
            });
        
          }
        else{

            if(props.createAnswersForSecondaryEssays.answersforsecondaryessays.length && typeof props.createAnswersForSecondaryEssays.error === "undefined" &&
            typeof dataIndex !== "undefined"){
                
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully saved response!',
                    icon : 'success',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    const updatedArray = items.map((item, key) => {
                        if (key === dataIndex) {
                            return { 
                                filename : props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].filename,
                                secondaryEssaysId : props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].secondaryEssaysId,
                                downloadUrl: props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].downloadUrl,
                                title: props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].title, 
                                body: props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].body, 
                                schoolSearchId : props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].schoolSearchId,
                                userId : props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].userId,
                                video : '',
                                docId: props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].responseSecondaryEssayId, 
                                collapsed: false, 
                                updatedAt: props.createAnswersForSecondaryEssays.answersforsecondaryessays[0].updatedAt,
                            }

                            
                        }
                        return item;
                    });

                 
                    // Update the state with the new array
                    setItems(updatedArray);


                    dispatch(createAnswersForSecondaryEssaysReset());
                })
            
            }
        
            else if(typeof props.createAnswersForSecondaryEssays.error !== "undefined"){
    
            Swal.fire({
                title:'Failed!',
                text:'Failed to save response!',
                icon :'error',
                timer: 3000
                
                }
            ).then((result) => {
                dispatch(createAnswersForSecondaryEssaysReset());
            })    
                    
            
            }
        }
    }, [props.createAnswersForSecondaryEssays,dataIndex])
    

    const toggleCollapse = (index) => {
        const updatedItems = items.map((item, i) => ({
            ...item,
            collapsed: i === index ? !item.collapsed : true,
        }));
        setItems(updatedItems);
    };

    const handleTitleChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].title = event.target.value;
        setItems(updatedItems);
    };
    
    const handleBodyChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].body = event.target.value;
        setItems(updatedItems);

       
    };

    const handleVideoChange = (event, index) => {
        const updatedItems = [...items];
        updatedItems[index].video = event.target.files[0];


        // Check the file size
        const fileSizeInMB = updatedItems[index].video.size / (1024 * 1024);
        const maxSizeInMB = 10;
        if (fileSizeInMB > maxSizeInMB) {
            Swal.fire({
                title: 'Failed!',
                text: 'File size exceeds the maximum limit of 10MB.',
                icon : 'error',
                timer: 3000
                
                }
            )
            return;
        }
    
        // Check if the file type is video
        const allowedTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];
        if (!allowedTypes.includes(updatedItems[index].video.type)) {
            

            Swal.fire({
                title: 'Failed!',
                text: 'Only video files are allowed.',
                icon : 'error',
                timer: 3000
                
                }
            )

            return;
        }

      


        setItems(updatedItems);
    };

    useEffect(() => {
       
    }, [dataIndex])
    
    
    const onClickSaveResponse = async(e, data) => {
        e.preventDefault();
        setDataIndex(data.index)
        
        const formData = new FormData();
        formData.append('title', items[data.index].title);
        formData.append('body', items[data.index].body);
        formData.append('file', items[data.index].video);
        formData.append('filename', items[data.index].filename);
        formData.append('schoolSearchId', props.schoolSearchId);
        formData.append('secondaryEssaysId', props.secondaryEssaysId);
        formData.append('userId', props.userDetails.userId);
        formData.append('docId', items[data.index].docId);

        dispatch(createAnswersForSecondaryEssays(formData))
   
    };

    const onClickDeleteResponse = async(e, data) => {
        e.preventDefault();
        setDelDataIndex(data.index)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteResponseForSecondaryEssay(data))
        
            }
        })

        
   
    };

    useEffect(() => {
        if(props.deleteResponseForSecondaryEssay.loading){
          Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
              Swal.showLoading()
              
            }
          });
      
        }
        else{
          if(props.deleteResponseForSecondaryEssay.responseforsecondaryessays.length && typeof props.deleteResponseForSecondaryEssay.error === "undefined" &&
          typeof delDataIndex !== "undefined"){
              
            Swal.fire({
                title: 'Success!',
                text: 'Successfully deleted response!',
                icon : 'success',
                timer: 3000      
              }
            )
            setItems(prevItems => prevItems.filter((item, key ) => key !== delDataIndex));
            dispatch(deleteResponseForSecondaryEssayReset());
            
            
          }
        
          else if(typeof props.deleteResponseForSecondaryEssay.error !== "undefined"){
              
              Swal.fire({
                  title:'Failed!',
                  text:'Failed to delete response!',
                  icon :'error',
                  timer: 3000 
                  }
              )            
              dispatch(deleteResponseForSecondaryEssayReset());
          }
        }
      }, [props.deleteResponseForSecondaryEssay, delDataIndex])


    

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    const onClickRemoveVideo = (e, data) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                const updatedArray = items.map((item, key) => {

                if (key === data.index) {
                    return { ...item, ...{ filename: "" } };
                }
                    return item;
                });

                setItems(updatedArray);
        
            }
        })


    }

    return (
        <div className=''>
            {items.map((item, index) => (
                <div key={index} className="card mt-2 ">
                    {/* <div className="card-header bg-secondary" id={`heading${index}`}>
                        <h2 className="mb-0">
                        <button
                            className="btn btn-link shadow-none w-100  text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${index}`}
                            aria-expanded={!item.collapsed}
                            aria-controls={`collapse${index}`}
                            onClick={() => toggleCollapse(index)}
                        >
                            <span className='h3 text-warning'>{item.title ? item.title : "New Item for secondary essays"}</span> {item.updatedAt ? <><br/><span className='text-default'> {"last updated on "  + (new Date(item.updatedAt).toLocaleString(undefined, options) + "" )} </span></> : ""}
                            <i className="ni ni-bold-down float-right"></i>
                        </button>
                        </h2>
                    </div>
                    <div
                        id={`collapse${index}`}
                        className={`collapse ${!item.collapsed ? 'show' : ''}`}
                        aria-labelledby={`heading${index}`}
                        data-parent="#accordionExample"
                    > */}
                        <div className="card-body opacity-8">
                        {/* <span className='font-weight-bold'>Question :</span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Paste The Secondary Essay Prompt'
                            value={item.title}
                            onChange={(event) => handleTitleChange(event, index)}
                        />
                        <br /> */}
                        <span className='font-weight-bold'>Upload Video (<span className='font-italic'>if required</span>) :</span>
                        <div className="custom-file text-center mb-4">
                            
                            <input
                                type="file"
                                className="custom-file-input"
                                accept="video/*"
                                onChange={(event) => handleVideoChange(event, index)}
                            />
                            <label className="custom-file-label" >{item.video ? <>Selected video file: <span className='text-primary'>{item.video.name}</span></> : "Select video file (max 10mb)"}</label>
                        </div>

                        { item.filename ?
                            <Row>
                                <Col lg="3"></Col>
                                <Col lg="6 text-center"> 
                                    <div className='player-wrapper'>
                                        <ReactPlayer
                                        url={'https://storage.googleapis.com/pre-dental-tracker.appspot.com/video-response-secondary-essays/' + item.filename}
                                        className='react-player'
                                        controls
                                        width='100%'
                                        height='100%'
                                        />
                                    </div>
                                    <a href={"https://storage.googleapis.com/pre-dental-tracker.appspot.com/video-response-secondary-essays/" + item.filename} download={item.filename}>
                                    <i className="fas fa-download"></i> Download
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href="" className='text-danger' onClick={(e) => onClickRemoveVideo(e,{index: index})}>
                                     <i className="fas fa-trash-alt"></i> Remove
                                    </a>
                                    
                                </Col>
                                <Col lg="3"></Col>
                        
                            </Row>
                            : ""
                        }

                        <br />
                        <br />

                        <span className='font-weight-bold'>Response for the secondary essay :</span>
                        <textarea
                            rows="5"
                            className="form-control "
                            value={item.body}
                            placeholder='Type your answer here...'
                            onChange={(event) => handleBodyChange(event, index)}
                            maxLength={props.characterLimit}
                        />
                        <div>Character count : {items[index]['body'].length}/{props.characterLimit} | Word Count : {countWords(items[index]['body'])}</div>
                            <button className="btn btn-success mt-4" disabled={props.adminUser.admin ? "true" : ""} onClick={e => onClickSaveResponse(e, {
                                index: index, 
                                docId : item.docId})}>
                                <i className="fas fa-save"></i> &nbsp;Save
                            </button>
                            {/* {item.docId ? <button className="btn btn-danger mt-4" onClick={e => onClickDeleteResponse(e, {
                                index: index, 
                                docId : item.docId})}>
                                <i className="fas fa-trash-alt"></i>&nbsp;Delete
                            </button> : ""} */}
                        </div>
                    </div>
                // </div>
            ))}
            {/* <div className='text-center'>
                <button className="btn btn-primary mt-4" disabled={props.adminUser.admin ? "true" : ""} onClick={addItem}>
                <i className="fa fa-plus"></i>&nbsp;&nbsp;Add Another Response
                </button>
            </div> */}
        </div>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        createAnswersForSecondaryEssays : state.createAnswersForSecondaryEssays,
        fetchResponseForSecondaryEssays : state.fetchResponseForSecondaryEssays,
        deleteResponseForSecondaryEssay : state.deleteResponseForSecondaryEssay,
        adminUser : state.adminUser
    }
      
}
  
export default connect(mapStateToProps)(SecondaryEssaysAccordion)