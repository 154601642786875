import {
    CREATE_DAT_COURSE_REQUEST,
    CREATE_DAT_COURSE_FAILURE,
    CREATE_DAT_COURSE_RESET,
    CREATE_DAT_COURSE_SUCCESS,
    FETCH_DAT_COURSES_FAILURE,
    FETCH_DAT_COURSES_SUCCESS,
    FETCH_DAT_COURSES_REQUEST,
    UPDATE_DAT_COURSE_FAILURE,
    UPDATE_DAT_COURSE_REQUEST,
    UPDATE_DAT_COURSE_RESET,
    UPDATE_DAT_COURSE_SUCCESS,
    DELETE_DAT_COURSE_FAILURE,
    DELETE_DAT_COURSE_REQUEST,
    DELETE_DAT_COURSE_RESET,
    DELETE_DAT_COURSE_SUCCESS,
    CREATE_DAT_COURSE_CATEGORY_REQUEST,
    CREATE_DAT_COURSE_CATEGORY_FAILURE,
    CREATE_DAT_COURSE_CATEGORY_RESET,
    CREATE_DAT_COURSE_CATEGORY_SUCCESS,
    CREATE_DAT_COURSE_LESSONS_REQUEST,
    CREATE_DAT_COURSE_LESSONS_FAILURE,
    CREATE_DAT_COURSE_LESSONS_RESET,
    CREATE_DAT_COURSE_LESSONS_SUCCESS,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET,
    UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS,

    UPDATE_DAT_COURSE_CATEGORY_REQUEST,
    UPDATE_DAT_COURSE_CATEGORY_FAILURE,
    UPDATE_DAT_COURSE_CATEGORY_RESET,
    UPDATE_DAT_COURSE_CATEGORY_SUCCESS,

    UPDATE_DAT_COURSE_LESSONS_REQUEST,
    UPDATE_DAT_COURSE_LESSONS_FAILURE,
    UPDATE_DAT_COURSE_LESSONS_RESET,
    UPDATE_DAT_COURSE_LESSONS_SUCCESS,

    DELETE_DAT_COURSE_LESSONS_REQUEST,
    DELETE_DAT_COURSE_LESSONS_FAILURE,
    DELETE_DAT_COURSE_LESSONS_RESET,
    DELETE_DAT_COURSE_LESSONS_SUCCESS,

    DELETE_DAT_COURSE_CATEGORY_REQUEST,
    DELETE_DAT_COURSE_CATEGORY_FAILURE,
    DELETE_DAT_COURSE_CATEGORY_RESET,
    DELETE_DAT_COURSE_CATEGORY_SUCCESS,

    UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET,
    UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS,

    FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST,
    FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE,
    FETCH_DAT_MARKED_COMPLETE_LESSON_RESET,
    FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS,

    CREATE_DAT_BUNDLECOURSE_REQUEST, 
    CREATE_DAT_BUNDLECOURSE_SUCCESS, 
    CREATE_DAT_BUNDLECOURSE_FAILURE,
    CREATE_DAT_BUNDLECOURSE_RESET, 

    FETCH_DAT_BUNDLECOURSE_REQUEST, 
    FETCH_DAT_BUNDLECOURSE_SUCCESS, 
    FETCH_DAT_BUNDLECOURSE_FAILURE,
    FETCH_DAT_BUNDLECOURSE_RESET, 

} from "./ManageDatCoursesTypes"

const initialCreateDatCourseState = {
    datcourses: [],
    loading : false
}

export const createDatCourseReducer = (state = initialCreateDatCourseState, action) => {
    switch(action.type){
        case CREATE_DAT_COURSE_RESET:
            return {
                loading: false,
                datcourses: []
            }
        case CREATE_DAT_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_DAT_COURSE_SUCCESS:
            return {
                loading: false,
                datcourses: [action.payload]
            }
        case CREATE_DAT_COURSE_FAILURE:
            return {
                loading : false,
                datcourses : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchDatCoursesState = {
    datcourses : [],
    loading: false,

}

export const fetchDatCoursesReducer = (state = initialFetchDatCoursesState, action) => {
    switch (action.type) {
        case FETCH_DAT_COURSES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DAT_COURSES_SUCCESS:
            return {
                loading: false,
                datcourses: action.payload
            }
        case FETCH_DAT_COURSES_FAILURE:
            return {
                loading: false,
                datcourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateDatCourseState = {
    datcourses: [],
    loading : false
}

export const updateDatCourseReducer = (state = initialUpdateDatCourseState, action) => {
    switch(action.type){
        case UPDATE_DAT_COURSE_RESET:
            return {
                loading: false,
                datcourses: []
            }
        case UPDATE_DAT_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAT_COURSE_SUCCESS:
            return {
                loading: false,
                datcourses: [action.payload]
            }
        case UPDATE_DAT_COURSE_FAILURE:
            return {
                loading : false,
                datcourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialDeleteDatCourseState = {
    datcourses: [],
    loading : false
}

export const deleteDatCourseReducer = (state = initialDeleteDatCourseState, action) => {
    switch(action.type){
        case DELETE_DAT_COURSE_RESET:
            return {
                loading: false,
                datcourses: []
            }
        case DELETE_DAT_COURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_DAT_COURSE_SUCCESS:
            return {
                loading: false,
                datcourses: [action.payload]
            }
        case DELETE_DAT_COURSE_FAILURE:
            return {
                loading : false,
                datcourses: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialCreateDatCourseCategoryState = {
    datcourse: [],
    loading : false
}

export const createDatCourseCategoryReducer = (state = initialCreateDatCourseCategoryState, action) => {
    switch(action.type){
        case CREATE_DAT_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case CREATE_DAT_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_DAT_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case CREATE_DAT_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialCreateDatCourseLessonsState = {
    datcourse: [],
    loading : false
}

export const createDatCourseLessonsReducer = (state = initialCreateDatCourseLessonsState, action) => {
    switch(action.type){
        case CREATE_DAT_COURSE_LESSONS_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case CREATE_DAT_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_DAT_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case CREATE_DAT_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateDatCourseCategoryOrderState = {
    datcourse: [],
    loading : false
}

export const updateDatCourseCategoryOrderReducer = (state = initialUpdateDatCourseCategoryOrderState, action) => {
    switch(action.type){
        case UPDATE_DAT_COURSE_CATEGORY_ORDER_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case UPDATE_DAT_COURSE_CATEGORY_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAT_COURSE_CATEGORY_ORDER_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case UPDATE_DAT_COURSE_CATEGORY_ORDER_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialUpdateDatCourseCategoryState = {
    datcourse: [],
    loading : false
}

export const updateDatCourseCategoryReducer = (state = initialUpdateDatCourseCategoryState, action) => {
    switch(action.type){
        case UPDATE_DAT_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case UPDATE_DAT_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAT_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case UPDATE_DAT_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateDatCourseLessonsState = {
    datcourse: [],
    loading : false
}

export const updateDatCourseLessonsReducer = (state = initialUpdateDatCourseLessonsState, action) => {
    switch(action.type){
        case UPDATE_DAT_COURSE_LESSONS_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case UPDATE_DAT_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAT_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case UPDATE_DAT_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteDatCourseLessonsState = {
    datcourse: [],
    loading : false
}

export const deleteDatCourseLessonsReducer = (state = initialDeleteDatCourseLessonsState, action) => {
    switch(action.type){
        case DELETE_DAT_COURSE_LESSONS_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case DELETE_DAT_COURSE_LESSONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_DAT_COURSE_LESSONS_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case DELETE_DAT_COURSE_LESSONS_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialDeleteDatCourseCategoryState = {
    datcourse: [],
    loading : false
}

export const deleteDatCourseCategoryReducer = (state = initialDeleteDatCourseCategoryState, action) => {
    switch(action.type){
        case DELETE_DAT_COURSE_CATEGORY_RESET:
            return {
                loading: false,
                datcourse: []
            }
        case DELETE_DAT_COURSE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_DAT_COURSE_CATEGORY_SUCCESS:
            return {
                loading: false,
                datcourse: [action.payload]
            }
        case DELETE_DAT_COURSE_CATEGORY_FAILURE:
            return {
                loading : false,
                datcourse : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUpdateMarkedCompleteLessonState = {
    markedcompletelesson: [],
    loading : false
}

export const updateDatMarkedCompleteLessonReducer = (state = initialUpdateMarkedCompleteLessonState, action) => {
    switch(action.type){
        case UPDATE_DAT_MARKED_COMPLETE_LESSON_RESET:
            return {
                loading: false,
                markedcompletelesson: []
            }
        case UPDATE_DAT_MARKED_COMPLETE_LESSON_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_DAT_MARKED_COMPLETE_LESSON_SUCCESS:
            return {
                loading: false,
                markedcompletelesson: [action.payload]
            }
        case UPDATE_DAT_MARKED_COMPLETE_LESSON_FAILURE:
            return {
                loading : false,
                markedcompletelesson : [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchDatMarkedCompleteLessonState = {
    markedcompletelesson: [],
    loading : false
}

export const fetchDatMarkedCompleteLessonReducer = (state = initialFetchDatMarkedCompleteLessonState, action) => {
    switch(action.type){
        case FETCH_DAT_MARKED_COMPLETE_LESSON_RESET:
            return {
                loading: false,
                markedcompletelesson: []
            }
        case FETCH_DAT_MARKED_COMPLETE_LESSON_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DAT_MARKED_COMPLETE_LESSON_SUCCESS:
            return {
                loading: false,
                markedcompletelesson: [action.payload]
            }
        case FETCH_DAT_MARKED_COMPLETE_LESSON_FAILURE:
            return {
                loading : false,
                markedcompletelesson : [],
                error: action.payload
            }
        default: return state;
    }
}

const initialCreateDatBundleCourseState = {
    loading: false,
    bundlecourse: [],
}
export const createDatBundleCoursesReducer = (state = initialCreateDatBundleCourseState, action) => {
    switch (action.type) {
        case CREATE_DAT_BUNDLECOURSE_RESET:
            return {
                loading: false,
                bundlecourse: [],
            }
        case CREATE_DAT_BUNDLECOURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_DAT_BUNDLECOURSE_SUCCESS:
            return {
                loading: false,
                bundlecourse: [action.payload]
            }
        case CREATE_DAT_BUNDLECOURSE_FAILURE:
            return {
                loading: false,
                bundlecourse: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialFetchDatBundleCoursesState = {
    bundlecourses : [],
    loading: false,

}

export const fetchDatBundleCoursesReducer = (state = initialFetchDatBundleCoursesState, action) => {
    switch (action.type) {
        case FETCH_DAT_BUNDLECOURSE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DAT_BUNDLECOURSE_SUCCESS:
            return {
                loading: false,
                bundlecourses: action.payload
            }
        case FETCH_DAT_BUNDLECOURSE_FAILURE:
            return {
                loading: false,
                bundlecourses: [],
                error: action.payload
            }
        default: return state;
    }
}