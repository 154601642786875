import { 
    OPEN_MCAT_SCORE_MODAL,
    CLOSE_MCAT_SCORE_MODAL,
    CREATE_MCAT_SCORE_REQUEST, 
    CREATE_MCAT_SCORE_SUCCESS, 
    CREATE_MCAT_SCORE_FAILURE,
    CREATE_MCAT_SCORE_RESET,
    FETCH_MCAT_SCORES_REQUEST, 
    FETCH_MCAT_SCORES_SUCCESS, 
    FETCH_MCAT_SCORES_FAILURE,
    EDIT_MCAT_SCORE,
    NEW_MCAT_SCORE,
    UPDATE_MCAT_SCORE_REQUEST, 
    UPDATE_MCAT_SCORE_SUCCESS, 
    UPDATE_MCAT_SCORE_FAILURE,
    UPDATE_MCAT_SCORE_RESET,
    DELETE_MCAT_SCORE_REQUEST,
    DELETE_MCAT_SCORE_SUCCESS, 
    DELETE_MCAT_SCORE_FAILURE,
    DELETE_MCAT_SCORE_RESET  } from "./McatScoresTypes";

const initialCreateMcatScoreState = {
    loading: false,
    mcatscore: [],
}

const initialUpdateMcatScoreState = {
    loading: false,
    mcatscore: [],
}

const initialDeleteMcatScoreState = {
    loading: false,
    mcatscore: [],
}

const initialFetchMcatScoresState = {
    mcatscores : [],
    loading: false,

}

const initialModalMcatScoreState = {
    status: false
}

const initialEditMcatScoreState = {
    status: false,
    mcatScoreId : ''
}

const initialNewMcatScoreState = {
    status: false,
}

export const createMcatScoresReducer = (state = initialCreateMcatScoreState, action) => {
    switch (action.type) {
        case CREATE_MCAT_SCORE_RESET:
            return {
                loading: false,
                mcatscore: [],
            }
        case CREATE_MCAT_SCORE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_MCAT_SCORE_SUCCESS:
            return {
                loading: false,
                mcatscore: [action.payload]
            }
        case CREATE_MCAT_SCORE_FAILURE:
            return {
                loading: false,
                mcatscore: [],
                error: action.payload
            }
        default: return state;
    }
}

export const fetchMcatScoresReducer = (state = initialFetchMcatScoresState, action) => {
    switch (action.type) {
        case FETCH_MCAT_SCORES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MCAT_SCORES_SUCCESS:
            return {
                loading: false,
                mcatscores: action.payload
            }
        case FETCH_MCAT_SCORES_FAILURE:
            return {
                loading: false,
                mcatscores: [],
                error: action.payload
            }
        default: return state;
    }
}

export const updateMcatScoreReducer = (state = initialUpdateMcatScoreState, action) => {
    switch (action.type) {
        case UPDATE_MCAT_SCORE_RESET:
            return {
                loading: false,
                mcatscore: [],
            }
        case UPDATE_MCAT_SCORE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_MCAT_SCORE_SUCCESS:
            return {
                loading: false,
                mcatscore: [action.payload]
            }
        case UPDATE_MCAT_SCORE_FAILURE:
            return {
                loading: false,
                mcatscore: [],
                error: action.payload
            }
        default: return state;
    }
}

export const editMcatScoreReducer = (state = initialEditMcatScoreState, action) => {
    switch (action.type) {
        case EDIT_MCAT_SCORE:
            return {
                status: action.payload.status,
                mcatScoreId: action.payload.mcatScoreId
            }
        default: return state;
    }
}

export const newMcatScoreReducer = (state = initialNewMcatScoreState, action) => {
    switch (action.type) {
        case NEW_MCAT_SCORE:
            return {
                status: action.payload.status,
            }
        default: return state;
    }
}

export const updateMcatScoreModalStatusReducer = (state = initialModalMcatScoreState, action) => {
    switch (action.type) {
        case OPEN_MCAT_SCORE_MODAL:
            return {
                status : true
            }
        case CLOSE_MCAT_SCORE_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}


export const deleteMcatScoreReducer = (state = initialDeleteMcatScoreState, action) => {
    switch (action.type) {
        case DELETE_MCAT_SCORE_RESET:
            return {
                loading: false,
                mcatscore: [],
            }
        case DELETE_MCAT_SCORE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_MCAT_SCORE_SUCCESS:
            return {
                loading: false,
                mcatscore: [action.payload]
            }
        case DELETE_MCAT_SCORE_FAILURE:
            return {
                loading: false,
                mcatscore: [],
                error: action.payload
            }
        default: return state;
    }
}