import axios from "axios";
import { 
    OPEN_MCAT_SCORE_MODAL,
    CLOSE_MCAT_SCORE_MODAL,
    CREATE_MCAT_SCORE_REQUEST, 
    CREATE_MCAT_SCORE_SUCCESS, 
    CREATE_MCAT_SCORE_FAILURE,
    CREATE_MCAT_SCORE_RESET,
    FETCH_MCAT_SCORES_REQUEST, 
    FETCH_MCAT_SCORES_SUCCESS, 
    FETCH_MCAT_SCORES_FAILURE,
    EDIT_MCAT_SCORE,
    NEW_MCAT_SCORE,
    UPDATE_MCAT_SCORE_REQUEST, 
    UPDATE_MCAT_SCORE_SUCCESS, 
    UPDATE_MCAT_SCORE_FAILURE,
    UPDATE_MCAT_SCORE_RESET,
    DELETE_MCAT_SCORE_REQUEST,
    DELETE_MCAT_SCORE_SUCCESS, 
    DELETE_MCAT_SCORE_FAILURE,
    DELETE_MCAT_SCORE_RESET  } from "./McatScoresTypes";



export const createMcatScoreReset = () => {
    return {
        type: CREATE_MCAT_SCORE_RESET,
    }
}

const createMcatScoreRequest = () => {
    return {
        type: CREATE_MCAT_SCORE_REQUEST,
    }
}

const createMcatScoreSuccess = success => {
    return {
        type: CREATE_MCAT_SCORE_SUCCESS,
        payload: success
    }
}

const createMcatScoreFailure = error => {
    return {
        type: CREATE_MCAT_SCORE_FAILURE,
        payload : error
    }
}


export const createMcatScore = (data) => {

    return (dispatch) => {

        dispatch(createMcatScoreRequest());
        
        
        


        if(data['inputBCPM'] == "Yes"){
            data['inputBCPM'] = true;
        }   
        else{
            data['inputBCPM'] = false;
        }

        if(data['inputQuarter'] == "Yes"){
            data['inputQuarter'] = true;
        }   
        else{
            data['inputQuarter'] = false;
        }

        axios.post('/storeMcatScore', data)
        .then(response => {
            const mcatScore = response.data
            dispatch(createMcatScoreSuccess(mcatScore))
            dispatch(fetchMcatScore({route: "", userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createMcatScoreFailure(errorMsg))
        })
        
    }

}




const fetchMcatScoreRequest = () => {
    return {
        type: FETCH_MCAT_SCORES_REQUEST,
    }
}

const fetchMcatScoreSuccess = success => {
    return {
        type: FETCH_MCAT_SCORES_SUCCESS,
        payload: success
    }
}

const fetchMcatScoreFailure = error => {
    return {
        type: FETCH_MCAT_SCORES_FAILURE,
        payload : error
    }
}

export const fetchMcatScore = (data) => {

    const fetchMcatScoreRoute = data.route;
    
    return (dispatch) => {
        
        dispatch(fetchMcatScoreRequest());
        
        
        axios.post('/getMcatScores' + fetchMcatScoreRoute , data)
        .then(response => {
            const mcatScore = response.data
            dispatch(fetchMcatScoreSuccess(mcatScore))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchMcatScoreFailure(errorMsg))
        })
        
    }

}


export const updateMcatScoreReset = () => {
    return {
        type: UPDATE_MCAT_SCORE_RESET,
    }
}

const updateMcatScoreRequest = () => {
    return {
        type: UPDATE_MCAT_SCORE_REQUEST,
    }
}

const updateMcatScoreSuccess = success => {
    return {
        type: UPDATE_MCAT_SCORE_SUCCESS,
        payload: success
    }
}

const updateMcatScoreFailure = error => {
    return {
        type: UPDATE_MCAT_SCORE_FAILURE,
        payload : error
    }
}

export const updateMcatScore = (data) => {

    return (dispatch) => {

        dispatch(updateMcatScoreRequest());
        
       
       


        if(data['inputBCPM'] == "Yes"){
            data['inputBCPM'] = true;
        }   
        else{
            data['inputBCPM'] = false;
        }

        if(data['inputQuarter'] == "Yes"){
            data['inputQuarter'] = true;
        }   
        else{
            data['inputQuarter'] = false;
        }
        
        axios.post('/updateMcatScore', data)
        .then(response => {
            const mcatScore = response.data
            dispatch(updateMcatScoreSuccess(mcatScore))
            dispatch(fetchMcatScore({route: "",userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(updateMcatScoreFailure(errorMsg))
        })
        
    }

}



const openMcatScoreModal = status => {
    return {
        type: OPEN_MCAT_SCORE_MODAL,
        payload : status
    }
}

const closeMcatScoreModal = status => {
    return {
        type: CLOSE_MCAT_SCORE_MODAL,
        payload : status
    }
}

export const updateMcatScoreModal = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openMcatScoreModal(data))

        } 
        else{

            dispatch(closeMcatScoreModal(data))

        } 
    }

}

export const editMcatScore = data => {
    
    return {
        type: EDIT_MCAT_SCORE,
        payload : data
    }
}

export const newMcatScore = data => {
    
    return {
        type: NEW_MCAT_SCORE,
        payload : data
    }
}



export const deleteMcatScoreReset = () => {
    return {
        type: DELETE_MCAT_SCORE_RESET,
    }
}

const deleteMcatScoreRequest = () => {
    return {
        type: DELETE_MCAT_SCORE_REQUEST,
    }
}

const deleteMcatScoreSuccess = success => {
    return {
        type: DELETE_MCAT_SCORE_SUCCESS,
        payload: success
    }
}

const deleteMcatScoreFailure = error => {
    return {
        type: DELETE_MCAT_SCORE_FAILURE,
        payload : error
    }
}

export const deleteMcatScore = (data) => {

    return (dispatch) => {

        dispatch(deleteMcatScoreRequest());
        
        axios.post('/deleteMcatScore', data)
        .then(response => {
            const mcatScore = response.data
            dispatch(deleteMcatScoreSuccess(mcatScore))
            dispatch(fetchMcatScore({route: "", userId: data['userId'], handle: data['handle']}))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteMcatScoreFailure(errorMsg))
        })
        
    }

}