import { SAVE_MANUAL_DEXTERITY_RESET, SAVE_MANUAL_DEXTERITY_REQUEST, SAVE_MANUAL_DEXTERITY_SUCCESS, SAVE_MANUAL_DEXTERITY_FAILURE, FETCH_MANUAL_DEXTERITY_FAILURE, FETCH_MANUAL_DEXTERITY_REQUEST, FETCH_MANUAL_DEXTERITY_SUCCESS } from './ManualDexterityTypes'
import axios from 'axios'

const manualDexterityRequest = () => {
    return {
        type: SAVE_MANUAL_DEXTERITY_REQUEST,
    }
}

export const manualDexterity = (userData) => (dispatch) => {

    dispatch(manualDexterityRequest());
    axios.post('/postManualDexterity', userData)
        .then(res => {
            dispatch({
                type: SAVE_MANUAL_DEXTERITY_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SAVE_MANUAL_DEXTERITY_FAILURE,
                payload: err.response.data
            })
        })
}


export const manualDexterityReset = () => {
    return {
        type: SAVE_MANUAL_DEXTERITY_RESET,
    }
}


const fetchManualDexterityRequest = () => {
    return {
        type: FETCH_MANUAL_DEXTERITY_REQUEST,
    }
}

const fetchManualDexteritySuccess = success => {
    return {
        type: FETCH_MANUAL_DEXTERITY_SUCCESS,
        payload: success
    }
}

const fetchManualDexterityFailure = error => {
    return {
        type: FETCH_MANUAL_DEXTERITY_FAILURE,
        payload : error
    }
}

export const fetchManualDexterity = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchManualDexterityRequest());
        
        
        axios.post('/getManualDexterity' , data)
        .then(response => {
            const manualDexterity = response.data
            dispatch(fetchManualDexteritySuccess(manualDexterity))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchManualDexterityFailure(errorMsg))
        })
        
    }

}
