import { 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE, 
    CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET,
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE, 
    CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST,
    FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS,
    FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS, 
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE,
    DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET ,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS, 
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE,
    DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET ,
    UPDATE_SELECTED_SECONDARY_STATUS_REQUEST,
    UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS, 
    UPDATE_SELECTED_SECONDARY_STATUS_FAILURE,
    UPDATE_SELECTED_SECONDARY_STATUS_RESET 
 } from "./SecondaryEssaysTypes";
import axios from "axios";

export const createSelectSchoolForSecondaryEssaysReset = () => {
    return {
        type: CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_RESET,
    }
}

const createSelectSchoolForSecondaryEssaysRequest = () => {
    return {
        type: CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST,
    }
}

const createSelectSchoolForSecondaryEssaysSuccess = success => {
    return {
        type: CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS,
        payload: success
    }
}

const createSelectSchoolForSecondaryEssaysFailure = error => {
    return {
        type: CREATE_SELECT_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE,
        payload : error
    }
}

export const createSelectSchoolForSecondaryEssays = (data) => {

    return (dispatch) => {

        dispatch(createSelectSchoolForSecondaryEssaysRequest());
        
        
        
        axios.post('/storeSelectSchoolForSecondaryEssays', data)
        .then(response => {
            const selectschoolforsecondaryessays = response.data
            dispatch(createSelectSchoolForSecondaryEssaysSuccess(selectschoolforsecondaryessays))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createSelectSchoolForSecondaryEssaysFailure(errorMsg))
        })
        
    }

}


const fetchSelectSchoolForSecondaryEssaysRequest = () => {
    return {
        type: FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_REQUEST,
    }
}

const fetchSelectSchoolForSecondaryEssaysSuccess = success => {
    return {
        type: FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_SUCCESS,
        payload: success
    }
}

const fetchSelectSchoolForSecondaryEssaysFailure = error => {
    return {
        type: FETCH_SELECTED_SCHOOL_FOR_SECONDARY_ESSAYS_FAILURE,
        payload : error
    }
}

export const fetchSelectSchoolForSecondaryEssays = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchSelectSchoolForSecondaryEssaysRequest());
        
        
        axios.post('/getSelectSchoolForSecondaryEssays' , data)
        .then(response => {
            const selectSchoolForSecondaryEssays = response.data
            dispatch(fetchSelectSchoolForSecondaryEssaysSuccess(selectSchoolForSecondaryEssays))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchSelectSchoolForSecondaryEssaysFailure(errorMsg))
        })
        
    }

}


export const createAnswersForSecondaryEssaysReset = () => {
    return {
        type: CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_RESET,
    }
}

const createAnswersForSecondaryEssaysRequest = () => {
    return {
        type: CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_REQUEST,
    }
}

const createAnswersForSecondaryEssaysSuccess = success => {
    return {
        type: CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_SUCCESS,
        payload: success
    }
}

const createAnswersForSecondaryEssaysFailure = error => {
    return {
        type: CREATE_ANSWERS_FOR_SECONDARY_ESSAYS_FAILURE,
        payload : error
    }
}

export const createAnswersForSecondaryEssays = (data) => {

    return (dispatch) => {

        dispatch(createAnswersForSecondaryEssaysRequest());
        
        
        
        axios.post('/storeAnswerForSecondaryEssays', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const answersforsecondaryessays = response.data
            dispatch(createAnswersForSecondaryEssaysSuccess(answersforsecondaryessays))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createAnswersForSecondaryEssaysFailure(errorMsg))
        })
        
    }

}



export const fetchResponseForSecondaryEssaysReset = () => {
    return {
        type: FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_RESET,
    }
}

const fetchResponseForSecondaryEssaysRequest = () => {
    return {
        type: FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_REQUEST,
    }
}

const fetchResponseForSecondaryEssaysSuccess = success => {
    return {
        type: FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_SUCCESS,
        payload: success
    }
}

const fetchResponseForSecondaryEssaysFailure = error => {
    return {
        type: FETCH_RESPONSE_FOR_SECONDARY_ESSAYS_FAILURE,
        payload : error
    }
}

export const fetchResponseForSecondaryEssays = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchResponseForSecondaryEssaysRequest());
        
        
        axios.post('/getResponseForSecondaryEssays' , data)
        .then(response => {
            const responseForSecondaryEssays = response.data
            dispatch(fetchResponseForSecondaryEssaysSuccess(responseForSecondaryEssays))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchResponseForSecondaryEssaysFailure(errorMsg))
        })
        
    }

}


export const deleteSelectedSchoolForSecondaryEssayReset = () => {
    return {
        type: DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_RESET,
    }
}

const deleteSelectedSchoolForSecondaryEssayRequest = () => {
    return {
        type: DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_REQUEST,
    }
}

const deleteSelectedSchoolForSecondaryEssaySuccess = success => {
    return {
        type: DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_SUCCESS,
        payload: success
    }
}

const deleteSelectedSchoolForSecondaryEssayFailure = error => {
    return {
        type: DELETE_SELECTED_SCHOOL_FOR_SECONDARY_ESSAY_FAILURE,
        payload : error
    }
}

export const deleteSelectedSchoolForSecondaryEssay = (data) => {

    return (dispatch) => {

        dispatch(deleteSelectedSchoolForSecondaryEssayRequest());
        
        axios.post('/deleteSelectedSchoolForSecondaryEssay', data)
        .then(response => {
            const selectSchoolForSecondaryEssays = response.data
            dispatch(deleteSelectedSchoolForSecondaryEssaySuccess(selectSchoolForSecondaryEssays))
      
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteSelectedSchoolForSecondaryEssayFailure(errorMsg))
        })
        
    }

}


export const deleteResponseForSecondaryEssayReset = () => {
    return {
        type: DELETE_RESPONSE_FOR_SECONDARY_ESSAY_RESET,
    }
}

const deleteResponseForSecondaryEssayRequest = () => {
    return {
        type: DELETE_RESPONSE_FOR_SECONDARY_ESSAY_REQUEST,
    }
}

const deleteResponseForSecondaryEssaySuccess = success => {
    return {
        type: DELETE_RESPONSE_FOR_SECONDARY_ESSAY_SUCCESS,
        payload: success
    }
}

const deleteResponseForSecondaryEssayFailure = error => {
    return {
        type: DELETE_RESPONSE_FOR_SECONDARY_ESSAY_FAILURE,
        payload : error
    }
}

export const deleteResponseForSecondaryEssay = (data) => {

    return (dispatch) => {

        dispatch(deleteResponseForSecondaryEssayRequest());
        
        axios.post('/deleteResponseSecondaryEssay', data)
        .then(response => {
            const responseForSecondaryEssays = response.data
            dispatch(deleteResponseForSecondaryEssaySuccess(responseForSecondaryEssays))
      
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteResponseForSecondaryEssayFailure(errorMsg))
        })
        
    }

}



const updateSecondaryStatusRequest = () => {
    return {
        type: UPDATE_SELECTED_SECONDARY_STATUS_REQUEST,
    }
}

export const updateSecondaryStatus = (userData) => (dispatch) => {

    dispatch(updateSecondaryStatusRequest());
    axios.post('/updateSecondaryStatus', userData)
        .then(res => {
            dispatch({
                type: UPDATE_SELECTED_SECONDARY_STATUS_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_SELECTED_SECONDARY_STATUS_FAILURE,
                payload: err.response.data
            })
        })
}


export const updateSecondaryStatusReset = () => {
    return {
        type: UPDATE_SELECTED_SECONDARY_STATUS_RESET,
    }
}
