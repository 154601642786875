import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col, Progress } from "reactstrap";
import { connect } from 'react-redux';
import {fetchWorkActivity} from 'redux/WorkActivities/WorkActivitiesActions';
import { useDispatch } from 'react-redux';
const TotalWorkActivities = (props) => {

    const [totalWorkActivityHours, setTotalWorkActivityHours] = useState(0)


    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            route : "",
            userId: props.userDetails.userId
        }
       
        dispatch(fetchWorkActivity(data))
    },[])

    useEffect(() => {
        if(props.fetchWorkActivities.workactivities.length){
            let totalWorkActivityHoursVal = 0;
        
            setTotalWorkActivityHours(totalWorkActivityHoursVal)

            props.fetchWorkActivities.workactivities.map((value, index) => {
                
                    
                    totalWorkActivityHoursVal += parseFloat(value.completedHours)
                    
                    // setTotalWorkActivityHours(totalWorkActivityHoursVal);
                    
                    if(value.repeated){
                        
                        value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {
                            
                            const repeatedCompletedHours =  parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                            // setTotalWorkActivityHours(prevTotal => prevTotal + repeatedCompletedHours);
                            
                            totalWorkActivityHoursVal += repeatedCompletedHours;
                            
                        })
                    }
                    

                    // if(value.experienceType == "Community Service/Volunteer - Medical/Clinical"){
                    //     totalWorkActivityHoursVal += parseFloat(value.completedHours)
                    //     //   setVolunteerCompletedHours(volunteerCompletedHoursVal);
                    //       if(value.repeated){
                    //           value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {
    
                    //                 const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                    //                 totalWorkActivityHoursVal += repeatedCompletedHours;
                    //           })
                    //       }
    
                    //       // if(value.anticipated){
                    //       //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {
    
                    //       //         volunteerCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                    //       //         setVolunteerCompletedHours((prevTotal) => prevTotal + volunteerCompletedHoursVal);
                    //       //     })
                    //       // }
                    // }


                    setTotalWorkActivityHours(totalWorkActivityHoursVal);
                    // if(value.anticipated){
                    //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

                    //         totalWorkActivityHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
                    //         setTotalWorkActivityHours((prevTotal) => prevTotal + totalWorkActivityHoursVal);
                    //     })
                    // }

                    
                    
            })

            
        }

        
       

    }, [props.fetchWorkActivities])

   

    const getCompletedHours = (partialName, data) => {

        let sum = 0;
        for (const key in data) {
            if (key.includes('CompletedHours')) {
                sum += parseInt(data[key]);
            }
        }
        return sum
    }

  return (
    <>
        <Card className="card-stats mb-4 mb-xl-0 w-100">
            <CardBody>
            <Row>
                <div className="col">
                <CardTitle
                    tag="h5"
                    className="text-uppercase text-muted mb-0"
                >
                    TOTAL WORK/ACTIVITY HOURS
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{totalWorkActivityHours}</span>
                </div>
                <Col className="col-auto">
                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                    <i className="fas fa-user-md" />
                </div>
                </Col>
            </Row>
            
            </CardBody>
        </Card>
    </>
  )
}

const mapStateToProps = state => {
    return {
        fetchWorkActivities : state.fetchWorkActivities,
        userDetails : state.loginAuth.userDetails,
    }
  }

export default connect(mapStateToProps)(TotalWorkActivities)