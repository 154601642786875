import React, { useEffect } from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal, 
    ModalBody,
    ModalFooter
  } from "reactstrap";
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPassword, updateForgotPassword, forgotPasswordReset } from 'redux/UserAuthentication/ForgotPasswordActions';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';

const ForgotPasswordModal = (props) => {

    const dispatch = useDispatch();

    // let toggleForgotPassword = props.toggleForgotPassword;
    let modalForgotPassword = props.modalForgotPassword;
    let toggleForgotPassword = props.toggleForgotPassword;

    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

    const messageRequired = "This field is required.";

    useEffect(() => {
        register("inputEmail", { required: messageRequired });



        if(typeof props.email != "undefined"){
            setValue("inputEmail",props.email)
        }

    }, [])
    
    
    const onSubmit = (data) => {

        data['email'] = data.inputEmail;
       
        dispatch(forgotPassword(data));

    }

    if(props.forgotPassword.loading){
        // dispatch(updateForgotPassword(false));
        // toggleForgotPassword();
        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
            Swal.showLoading()
            
            }
        });
    }
    else{
    
      
        
        if(typeof props.forgotPassword.forgotpassword.modalSuccess !== "undefined"){
              
            Swal.fire({
                title: 'Success!',
                text: 'Recovery Email Sent. Please check your email!',
                icon : 'success',
                timer: 3000
            })
            dispatch(forgotPasswordReset());
            
            
        }
  
        else if(typeof props.forgotPassword.error !== "undefined"){
          
            Swal.fire({
                title:'Failed!',
                text:'Something went wrong. Check email address!',
                icon :'error',
                timer: 3000
            })           
            dispatch(forgotPasswordReset());
            
        }
  
      
  
    }

    return (
        <div>
        
            <Modal className="modal-dialog-centered" size="lg" isOpen={modalForgotPassword}  toggle={toggleForgotPassword}>
            <Form  onSubmit={handleSubmit(onSubmit)}>
            <ModalBody className='modal-body p-0'>
            <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{typeof props.changePassword != "undefined" ? "Change Password" : "Forgot Password"}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                        
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                    
                    {/* <h6 className="heading-small text-muted mb-4">
                        Course information
                    </h6> */}
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="inputEmail"
                                >
                                    Email <span className='text-danger'>*</span>
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="inputEmail"
                                    name="inputEmail"
                                    onChange={e => setValue("inputEmail", e.target.value)}
                                    type="text"
                                    value={watch("inputEmail")}
                                    disabled={typeof props.email != "undefined" ? "disabled" : ""}

                                    />
                                        
                                
                                { errors.inputEmail && <small className="text-danger">{errors.inputEmail.message}</small> }
                                </FormGroup>
                                
                            </Col>
                        </Row>
                    </div>
                    
                </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" type='submit'>
                    Send Recovery Email
                </Button>{' '}
                <Button color="secondary" onClick={toggleForgotPassword}>
                    Cancel
                </Button>
            </ModalFooter>
            </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
      forgotPassword : state.forgotPassword,
      
    }
}

export default connect(mapStateToProps)(ForgotPasswordModal)