export const FETCH_ALL_USERS_REQUEST = "FETCH_ALL_USERS_REQUEST";
export const FETCH_ALL_USERS_FAILURE = "FETCH_ALL_USERS_FAILURE";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";

export const FETCH_USER_DETAILS_REQUEST = "FETCH_USER_DETAILS_REQUEST";
export const FETCH_USER_DETAILS_FAILURE = "FETCH_USER_DETAILS_FAILURE";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";

export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST";
export const UPDATE_USER_DETAILS_FAILURE = "UPDATE_USER_DETAILS_FAILURE";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_RESET = "UPDATE_USER_DETAILS_RESET";

export const UPDATE_MULTI_USERS_REQUEST = "UPDATE_MULTI_USERS_REQUEST";
export const UPDATE_MULTI_USERS_FAILURE = "UPDATE_MULTI_USERS_FAILURE";
export const UPDATE_MULTI_USERS_SUCCESS = "UPDATE_MULTI_USERS_SUCCESS";
export const UPDATE_MULTI_USERS_RESET = "UPDATE_MULTI_USERS_RESET";
