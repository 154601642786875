
import { OPEN_FORGOT_PASSWORD_MODAL, CLOSE_FORGOT_PASSWORD_MODAL, FORGOT_PASSWORD_RESET, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from "./ForgotPasswordTypes"

const initialModalForgotPasswordState = {
    status: false
}

const initialForgotPasswordState = {
    loading: false,
    forgotpassword: [],
}

export const updateForgotPasswordModalStatusReducer = (state = initialModalForgotPasswordState, action) => {
    switch (action.type) {
        case OPEN_FORGOT_PASSWORD_MODAL:
            return {
                status : true
            }
        case CLOSE_FORGOT_PASSWORD_MODAL:
            return {
                status : false
            }
        default: return state;
    }
}

export const forgotPasswordReducer  = (state = initialForgotPasswordState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_RESET:
            return {
                loading: false,
                forgotpassword: [],
            }
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                loading: false,
                forgotpassword: action.payload
            }
        case FORGOT_PASSWORD_FAILURE:
            return {
                loading: false,
                forgotpassword: [],
                error: action.payload
            }
        default:
            return state;
    }
}