import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import { connect, useDispatch } from "react-redux";
import {
    Card,
    CardSubtitle,
    CardText,
    CardBody,
    CardTitle,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    CardImg,
    Media,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse
} from "reactstrap";
import ReactPlayer from "react-player";

const CourseItemDetails = (props) => {

    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);

    const addZeroes = num => num.toLocaleString("en",{minimumFractionDigits: 2})

    const [courseCategory,setCourseCategory ] = useState(props.item)
    const [videoUrl, setUrl] = useState("");
    const [play, setPlay] = useState(false);

return (
    <>
       <Card className="mt-3 p-1 border-0 ">

            <Button color="success" onClick={toggle} >
                {props.index}. {courseCategory.text} 
            </Button>
            <Collapse isOpen={isOpen} >
                {
                    typeof courseCategory.lessons !== "undefined" ? 
                        courseCategory.lessons.length ? 
                            courseCategory.lessons.map((lesson,l) => {
                                return <Card key={l} className="m-2" role="button">
                                    <CardBody className="p-3">
                                        <h3>{lesson.title}</h3>
                                        <ReactPlayer url={lesson.video} controls={true}></ReactPlayer>
                                    </CardBody>
                                </Card>
                            })
                        :  <Card className="m-2" role="button"><CardBody>No Lessons Yet..</CardBody></Card>
                    : "No Lessons"
                }
            
            </Collapse>
       </Card>
     
  
        

    </>
)


}

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
  };
};

export default connect(mapStateToProps)(CourseItemDetails);