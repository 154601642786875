export const FETCH_STUDENT_REQUEST_TUTOR_REQUEST = 'FETCH_STUDENT_REQUEST_TUTOR_REQUEST';
export const FETCH_STUDENT_REQUEST_TUTOR_SUCCESS = 'FETCH_STUDENT_REQUEST_TUTOR_SUCCESS';
export const FETCH_STUDENT_REQUEST_TUTOR_FAILURE = 'FETCH_STUDENT_REQUEST_TUTOR_FAILURE';
export const FETCH_STUDENT_REQUEST_TUTOR_RESET = 'FETCH_STUDENT_REQUEST_TUTOR_RESET';


export const APPROVE_STUDENT_REQUEST_TUTOR_REQUEST = 'APPROVE_STUDENT_REQUEST_TUTOR_REQUEST';
export const APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS = 'APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS';
export const APPROVE_STUDENT_REQUEST_TUTOR_FAILURE = 'APPROVE_STUDENT_REQUEST_TUTOR_FAILURE';
export const APPROVE_STUDENT_REQUEST_TUTOR_RESET = 'APPROVE_STUDENT_REQUEST_TUTOR_RESET';

export const DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST = 'DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST';
export const DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS = 'DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS';
export const DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE = 'DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE';
export const DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET = 'DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET';

export const FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST = 'FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST';
export const FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS = 'FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS';
export const FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE = 'FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE';
export const FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET = 'FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET';

export const OPEN_UTV_MODAL = 'OPEN_UTV_MODAL';
export const CLOSE_UTV_MODAL = 'CLOSE_UTV_MODAL';
export const EDIT_TUTOR_REQUEST = 'EDIT_TUTOR_REQUEST';



export const FETCH_SRT_DETAILS_FAILURE = 'FETCH_SRT_DETAILS_FAILURE';
export const FETCH_SRT_DETAILS_REQUEST = 'FETCH_SRT_DETAILS_REQUEST';
export const FETCH_SRT_DETAILS_RESET = 'FETCH_SRT_DETAILS_RESET';
export const FETCH_SRT_DETAILS_SUCCESS = 'FETCH_SRT_DETAILS_SUCCESS';


export const CREATE_STR_UPLOAD_REQUEST = 'CREATE_STR_UPLOAD_REQUEST';
export const CREATE_STR_UPLOAD_SUCCESS = 'CREATE_STR_UPLOAD_SUCCESS';
export const CREATE_STR_UPLOAD_FAILURE = 'CREATE_STR_UPLOAD_FAILURE';
export const CREATE_STR_UPLOAD_RESET = 'CREATE_STR_UPLOAD_RESET';

