import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

import LifeTimePlanUsers from "components/Pages/LifeTimePlanUsers";
import ManageUsersModal from "components/Modal/ManageUsersModal";
import {fetchUniversities}  from "redux/Users/UsersActions";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import { customDropdown } from "util/reactCustomStyles";
import classnames from "classnames";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";

const ManageAdmins = (props) => {
  	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
  	const [dataAdmin, setAdminUsers] = useState([]);
  	const [filterAdmin, setFilterAdmin] = useState([]);

  	useEffect(() => {
        if(props.item.length){
            setAdminUsers(props.item)
            setFilterAdmin(props.item)
        }
        setLoading(false)
        Swal.close()
    },[props.item])

	const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }

	const columns = [
		{
			name: "Name",
			// sortable: true,
			// wrap: true,
			width: '250px',
			selector: (row) => {
				return(
					<Media className="align-items-center">
						{
							typeof row.profilePicture != "undefined" ? (
							<div className="card-profile-div-sm card-profile-div-md">
								<div className="card-profile-image">
									<div
									className="profile-picture-sm profile-picture-md"
									style={{
										width: "38px",
										height: "38px",
										backgroundImage:
											'url("https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/' +
											row.profilePicture +
											'")',
									}}
									>
										
									</div>
								</div>
							</div>
							) : 
							(
							<div className="rounded-circle profile-letter-all-users mr-2" 
							style={{
							width: "30px",
							height: "30px",
								fontSize: "16px"
								}}>
								{row.name !== undefined ? row.name.trim()[0].toUpperCase() : "1" }
							</div>
							)
						}
						<span className="mb-0 text-sm">{row.name}</span>
					</Media>
				)
			}
		},
		{
			name: "Email",
			sortable: true,
			selector: (row) => {
				return row.email
			}
		},
		{
			name: "Role",
			selector: (row) => {
				return row.admin ? 'Admin' : 'Student'
			}
		},
		{
			name: "Entry Date",
			sortable: true,
			selector: (row) => {
				return convertDate(row.createdAt);
			}
		},
		{
			name: "Action",
			cell: (row) => (
                <ManageUsersModal item={row}/>
			),
			center: "true"
		},
	];

	const tableHeaderstyle = {
		headCells: {
		style: {
			fontWeight: "bold",
			fontSize: "14px",
			// backgroundColor: "#ccc",
		},
		},
	};

	useEffect(() => {
		const result = dataAdmin.filter((item) => {
			if(item){
				return (
					modifiedSearch(item.email).match(search.toLocaleLowerCase()) || 
					modifiedSearch(item.name).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.matriculated).match(search.toLocaleLowerCase()) ||
					modifiedSearch(item.mycoach).match(search.toLocaleLowerCase()) ||
					convertDate(item.createdAt).toLowerCase().match(search.toLocaleLowerCase()) 
				)
			}
		});
		
    	setFilterAdmin(result);
		
	}, [search]);

	const modifiedSearch = (item) =>  {
		if(item !== undefined){
			if(item.name !== undefined){
				return  item.name.toLowerCase()
			} 
			return  item.toLowerCase()
		}else{
			return '';
		}
	}


  return (
		<>
            <Row>
                <Col lg="12">
                    <Card className="shadow">
                        <CardBody>
							<p className="float-right" >Total Count: {dataAdmin.length}  </p>
                           	<CardTitle tag="h4" >MANAGE ADMIN USERS </CardTitle>
                            <CardSubtitle tag="h5" className="mb-2 text-muted">List of admin users </CardSubtitle>
                            
                            { 
                            
                                loading ? 
                                    <div className="m-5">
                                        <Alert color="secondary" className="text-center">
                                            Collecting data.. Please wait while loading {" "}
                                            <Spinner size="sm" color="primary" />{" "}
                                        </Alert>
                                    </div>
                                : dataAdmin.length ? 
                                        <DataTable
                                            progressPending={loading}
                                            customStyles={tableHeaderstyle}
                                            columns={columns}
                                            data={filterAdmin}
                                            pagination
                                            wrap
                                            // selectableRows
                                            // fixedHeader
                                            selectableRowsHighlight
                                            highlightOnHover
                                            subHeader
                                            subHeaderComponent={
                                            <input
                                                type="text"
                                                className="w-50 form-control"
                                                placeholder="Search a keyword..."
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            }
                                            subHeaderAlign="right"
                                        />
                                : 
                                <Alert color="primary">No data available .</Alert>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
               
		</>
	);
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    fetchCoaches: state.fetchCoaches,
    fetchUniversities : state.fetchUniversities,
  };
};

export default connect(mapStateToProps)(ManageAdmins);
