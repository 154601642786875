import { FETCH_SCHOOL_SEARCH_FAILURE, FETCH_SCHOOL_SEARCH_SUCCESS, FETCH_SCHOOL_SEARCH_REQUEST } from "./SchoolSearchTypes"
import axios from 'axios'

const fetchSchoolSearchRequest = () => {
    return {
        type: FETCH_SCHOOL_SEARCH_REQUEST,
    }
}

const fetchSchoolSearchSuccess = success => {
    return {
        type: FETCH_SCHOOL_SEARCH_SUCCESS,
        payload: success
    }
}

const fetchSchoolSearchFailure = error => {
    return {
        type: FETCH_SCHOOL_SEARCH_FAILURE,
        payload : error
    }
}

export const fetchSchoolSearch = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchSchoolSearchRequest());
        
        
        axios.post('/getSchoolsSearch' , data)
        .then(response => {
            const schoolSearch = response.data
            dispatch(fetchSchoolSearchSuccess(schoolSearch))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchSchoolSearchFailure(errorMsg))
        })
        
    }

}