export const CREATE_ACHIEVEMENT_REQUEST = 'CREATE_ACHIEVEMENT_REQUEST';
export const CREATE_ACHIEVEMENT_SUCCESS = 'CREATE_ACHIEVEMENT_SUCCESS';
export const CREATE_ACHIEVEMENT_FAILURE = 'CREATE_ACHIEVEMENT_FAILURE';
export const CREATE_ACHIEVEMENT_RESET = 'CREATE_ACHIEVEMENT_RESET';

export const FETCH_ACHIEVEMENTS_REQUEST = 'FETCH_ACHIEVEMENTS_REQUEST';
export const FETCH_ACHIEVEMENTS_SUCCESS = 'FETCH_ACHIEVEMENTS_SUCCESS';
export const FETCH_ACHIEVEMENTS_FAILURE = 'FETCH_ACHIEVEMENTS_FAILURE';

export const UPDATE_ACHIEVEMENT_REQUEST = 'UPDATE_ACHIEVEMENT_REQUEST';
export const UPDATE_ACHIEVEMENT_SUCCESS = 'UPDATE_ACHIEVEMENT_SUCCESS';
export const UPDATE_ACHIEVEMENT_FAILURE = 'UPDATE_ACHIEVEMENT_FAILURE';
export const UPDATE_ACHIEVEMENT_RESET = 'UPDATE_ACHIEVEMENT_RESET';

export const OPEN_ACHIEVEMENT_MODAL = 'OPEN_ACHIEVEMENT_MODAL'
export const CLOSE_ACHIEVEMENT_MODAL = 'CLOSE_ACHIEVEMENT_MODAL'

export const EDIT_ACHIEVEMENT = 'EDIT_ACHIEVEMENT';
export const NEW_ACHIEVEMENT = 'NEW_ACHIEVEMENT';


export const DELETE_ACHIEVEMENT_REQUEST = 'DELETE_ACHIEVEMENT_REQUEST';
export const DELETE_ACHIEVEMENT_SUCCESS = 'DELETE_ACHIEVEMENT_SUCCESS';
export const DELETE_ACHIEVEMENT_FAILURE = 'DELETE_ACHIEVEMENT_FAILURE';
export const DELETE_ACHIEVEMENT_RESET = 'DELETE_ACHIEVEMENT_RESET';


export const UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST = 'UPDATE_IMPORTANT_ACHIEVEMENT_REQUEST';
export const UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS = 'UPDATE_IMPORTANT_ACHIEVEMENT_SUCCESS';
export const UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE = 'UPDATE_IMPORTANT_ACHIEVEMENT_FAILURE';
export const UPDATE_IMPORTANT_ACHIEVEMENT_RESET = 'UPDATE_IMPORTANT_ACHIEVEMENT_RESET';