import { FETCH_GRADE_RESET, FETCH_GRADE_REQUEST, FETCH_GRADE_SUCCESS, FETCH_GRADE_FAILURE } from "./GradesTypes"
import axios from 'axios'
export const fetchGradeReset = () => {
    return {
        type: FETCH_GRADE_RESET,
    }
}

const fetchGradeRequest = () => {
    return {
        type: FETCH_GRADE_REQUEST,
    }
}

const fetchGradeSuccess = success => {
    return {
        type: FETCH_GRADE_SUCCESS,
        payload: success
    }
}

const fetchGradeFailure = error => {
    return {
        type: FETCH_GRADE_FAILURE,
        payload : error
    }
}

export const fetchGrade = (data) => {

    return (dispatch) => {

        dispatch(fetchGradeRequest());
        
        axios.post('/getGrades', data)
        .then(response => {
            const grade = response.data
            dispatch(fetchGradeSuccess(grade))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchGradeFailure(errorMsg))
        })
        
    }

}