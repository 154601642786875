import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import moment from 'moment-timezone';
import {
    Input,
} from "reactstrap";


import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { app, db } from "../../firebase";
import { getDocs, collection, query, where, onSnapshot, doc, orderBy, limit } from "firebase/firestore";
import ProMemberComment from 'components/Community/ProMemberComment';
const Comments = (props) => {

    const dispatch = useDispatch()
    const [commentsArr, setComments] = useState([])

    useEffect(() => {
        const unsub = onSnapshot(query(collection(db, "communityProMemberComments"), where("postid", "==",props.item.postid ), orderBy("createdAt","desc")), (snapshot) =>
            setComments(snapshot.docs.map((doc) => ({ ...doc.data(), commentDocId: doc.id })))
        );
        return unsub
    },[props]);
    
    return (
        <>
            {
                commentsArr.length > 0 ?
                    commentsArr.map((comment, key) => {
                        return (                 
                            <ProMemberComment
                                key={comment.commentDocId}
                                item={comment}
                                postDetails={props.item}
                            />
                        )      
                    })
                : ''
            }          
        </>
    )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(Comments)
