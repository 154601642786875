import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form';
import { useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { resetPasswordReset, resetPassword } from "redux/UserAuthentication/ResetPasswordActions";
import ForgotPasswordModal from "components/Modal/ForgotPasswordModal";
import { updateForgotPassword } from "redux/UserAuthentication/ForgotPasswordActions";

const ForgotPassword = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
  
  const modalForgotPassword = props.updateForgotPasswordModalStatus.status;

  const toggleForgotPassword = () =>{
    dispatch(updateForgotPassword(!props.updateForgotPasswordModalStatus.status));
    
  } 

  useEffect(() =>{
    const messageRequired = "This field is required.";

    register("email", { 
        required: messageRequired, 
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address."
        } 
    }); 
    register("inputNewPassword", { required: messageRequired })

    
  },[])

  
  const getRecoveryToken = () => {
    var params = {};
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
   
    return params;
  }
  
  const onSubmit = (data) => {

    let actionCode = getRecoveryToken().oobCode

    const userData = {
      actionCode,
      email: data.email,
      newPassword: data.inputNewPassword
    }
    
    dispatch(resetPassword(userData));
  }


  if(props.resetPassword.loading){
    Swal.fire({
      allowOutsideClick : false,
      didOpen: () => {
        Swal.showLoading()
        
      }
    });

  }
  else{
    
    
    if(typeof props.resetPassword.resetpassword.success !== "undefined"){
      Swal.fire({
          title: 'Success!',
          text: 'Password successfully reset!',
          icon : 'success',
          timer: 3000
          
        }
      ).then(function (result) {
          
        history.push("/auth/login");
      
      });

      dispatch(resetPasswordReset());
      
    }

    else if(typeof props.resetPassword.error !== "undefined"){

      if(props.resetPassword.error.hasOwnProperty('general')){
        Swal.fire({
          title:'Failed!',
          text:props.resetPassword.error.general,
          icon :'error',
          timer: 3000
          
        })

        
      }
      else{
        Swal.fire({
          title:'Failed!',
          text:'Something went wrong, Please try again!',
          icon :'error',
          timer: 3000
          
        })
      }
          
      dispatch(resetPasswordReset());       
      
    }

  }



 

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center ">
              <strong>Reset Password</strong>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit(onSubmit)} role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    autoComplete="new-email"
                    name="email"
                    onChange={e => setValue("email", e.target.value)}
                  />
                </InputGroup>
                { errors.email && <small className="text-danger">{errors.email.message}</small> }
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="New Password"
                    type="password"
                    autoComplete="new-password"
                    name="inputNewPassword"
                    onChange={e => setValue("inputNewPassword", e.target.value)}
                  />
                </InputGroup>
                { errors.inputNewPassword && <small className="text-danger">{errors.inputNewPassword.message}</small> }
              </FormGroup>
              
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Reset
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => {e.preventDefault(); toggleForgotPassword()}}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
              onClick={(e) => {e.preventDefault(); history.push("/auth/register");}}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
      {/* <CoursesModal toggleForgotPassword={toggleForgotPassword} modalForgotPassword={modalForgotPassword} /> */}
      <ForgotPasswordModal modalForgotPassword={modalForgotPassword} toggleForgotPassword={toggleForgotPassword}  /> 
    </>
  );
};

const mapStateToProps = state => {
  return {
    resetPassword : state.resetPassword,
    updateForgotPasswordModalStatus : state.updateForgotPasswordModalStatus,
   
  }
}

export default connect(mapStateToProps)(ForgotPassword);
