import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form } from 'reactstrap';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Months from 'components/Options/Months';
import Years from 'components/Options/Years';
import ExperienceTypes from 'components/Options/WorkActivities/ExperienceTypes';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'
import { createWorkActivity, createWorkActivityReset, editWorkActivity, newWorkActivity,updateWorkActivity, updateWorkActivityReset } from 'redux/WorkActivities/WorkActivitiesActions';

import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import { experienceType, workActivitiesType, monthsList, yearsList } from 'util/DataList';
import { optionCountries } from "util/DataList";

import { City, State, Country } from 'country-state-city';
const WorkActivitiesModal = (props) => {

    let toggleAddWorkActivities = props.toggleAddWorkActivities;
    let modalAddWorkActivities = props.modalAddWorkActivities;

    const dispatch = useDispatch();
    const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const messageRequired = "This field is required.";
    const messageHoursValidation = "Hours must be between 0 and 99999";
    
    const [workactivityId, setWorkactivityId] = useState('');


    const [years, setYears] = useState([]);

    const validationForStartDate = (value, inputEndDateName, inputStartDateName, sign) => {

        let endDate = new Date(Date.parse("1 " + months[getValues(inputEndDateName+"EndMonth") - 1 ] + " " + getValues(inputEndDateName+"EndYear")));
        let startDate =  new Date(Date.parse("1 " + months[getValues(inputStartDateName+"StartMonth") - 1 ] + " " + value));
        
        if(sign == "less-than"){
          return endDate <= startDate;
        }
        else{
          return endDate >= startDate;
        }
    }


    const yesNoArr = [{"label" : "Yes", "value" : "Yes"},{"label" : "No", "value" : "No"}];

    const statusArr = [
      {"label" : "Full time", "value" : "Full time"},
      {"label" : "Part Time", "value" : "Part Time"},
      {"label" : "Temporary", "value" : "Temporary"},
      {"label" : "Per Diem", "value" : "Per Diem"}
    ]

    const typeOfRecognitionArr = [
      {"label" : "Compensated", "value" : "Compensated"},
      {"label" : "Received Academic Credit", "value" : "Received Academic Credit"},
      {"label" : "Volunteer", "value" : "Volunteer"}
    ]

    useEffect(() => {

        setYears(yearsList());
        
        register("inputExperienceType", { required: messageRequired });

        register("inputOrgName", { required: messageRequired });
        register("inputOrgAddress");
        register("inputOrgAddress2");
        register("inputOrgCountry", { required: messageRequired });
        register("inputOrgState", { required: messageRequired });
        register("inputOrgCity");

        register("inputSupervisorFirstName");
        register("inputSupervisorLastName");
        register("inputSupervisorTitle");
        register("inputSupervisorContactPhone");
    
        register("inputSupervisorContactEmail", { 
          // required: messageRequired, 
          pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address."
          } 
        }); 
        
        register("inputExperienceDatesStartMonth", { required: messageRequired });
        register("inputExperienceDatesStartYear", { 
          required: messageRequired, 
          validate: { 
            validationFirstScenario : value => validationForStartDate(value, "inputExperienceDates", "inputExperienceDates", "greater-than")
          }
        });
        register("inputExperienceDatesEndMonth", { required: messageRequired });
        register("inputExperienceDatesEndYear", { required: messageRequired });


        register("inputExperienceDatesCurrentExperience"
          , { required: messageRequired }
        ); 
        register("inputExperienceDatesStatus"
          , { required: messageRequired }
        );


         

        register("inputExperienceDetailsTitle" , { required: messageRequired });
        register("inputExperienceDetailsTypeOfRecognition" , { required: messageRequired });
        register("inputExperienceDetailsAverageWeeklyHours" , { required: messageRequired });
        register("inputExperienceDetailsNumberOfWeeks" , { required: messageRequired });
        register("inputExperienceDetailsTotalHours" );
        register("inputExperienceDetailsDescription" , { required: messageRequired });
        register("inputExperienceDetailsReleaseAuthorization" , { required: messageRequired });

        

    },[])    


    useEffect(() => {
      if(props.editWorkActivity.status){
          
        const workActivityData  = findWorkActivity(props.fetchWorkActivities.workactivities,props.editWorkActivity.workactivityId);

        console.log(workActivityData)
        setWorkactivityId(props.editWorkActivity.workactivityId);

        setValue("inputExperienceType", workActivityData.experienceType)
        
        setValue("inputOrgName", workActivityData.orgName)
        setValue("inputOrgAddress", workActivityData.orgAddress)
        setValue("inputOrgAddress2", workActivityData.orgAddress2)
        setValue("inputOrgCountry", workActivityData.orgCountry)
        setValue("inputOrgState", workActivityData.orgState)
        setValue("inputOrgCity", workActivityData.orgCity)

        setValue("inputSupervisorFirstName", workActivityData.supervisorFirstName)
        setValue("inputSupervisorLastName", workActivityData.supervisorLastName)
        setValue("inputSupervisorTitle", workActivityData.supervisorTitle)
        setValue("inputSupervisorContactPhone", workActivityData.supervisorContactPhone)
        setValue("inputSupervisorContactEmail", workActivityData.supervisorContactEmail)

        setValue("inputExperienceDatesStartMonth", workActivityData.experienceDatesStartMonth)
        setValue("inputExperienceDatesStartYear", workActivityData.experienceDatesStartYear)
        setValue("inputExperienceDatesEndMonth", workActivityData.experienceDatesEndMonth)
        setValue("inputExperienceDatesEndYear", workActivityData.experienceDatesEndYear)
        setValue("inputExperienceDatesCurrentExperience", workActivityData.experienceDatesCurrentExperience)
        setValue("inputExperienceDatesStatus", workActivityData.experienceDatesStatus)

        setValue("inputExperienceDetailsTitle", workActivityData.experienceDetailsTitle)
        setValue("inputExperienceDetailsTypeOfRecognition", workActivityData.experienceDetailsTypeOfRecognition)
        setValue("inputExperienceDetailsAverageWeeklyHours", workActivityData.experienceDetailsAverageWeeklyHours)
        setValue("inputExperienceDetailsNumberOfWeeks", workActivityData.experienceDetailsNumberOfWeeks)
        setValue("inputExperienceDetailsTotalHours", workActivityData.experienceDetailsTotalHours)
        setValue("inputExperienceDetailsDescription", workActivityData.experienceDetailsDescription)
        setValue("inputExperienceDetailsReleaseAuthorization", workActivityData.experienceDetailsReleaseAuthorization)

        setValue("inputExperienceDetailsTotalHours", workActivityData.experienceDetailsAverageWeeklyHours * workActivityData.experienceDetailsNumberOfWeeks)

        dispatch(editWorkActivity({
            status : false,
            workactivityId : ""
        }));

      }

      

    },[props.editWorkActivity])
    
    useEffect(() => {
      if(props.newWorkActivity.status){
       
        
        setWorkactivityId('');

        setValue("inputExperienceType", "")
        setValue("inputOrgName", "")
        setValue("inputOrgAddress", "")
        setValue("inputOrgAddress2", "")
        setValue("inputOrgCountry", "")
        setValue("inputOrgState", "")
        setValue("inputOrgCity", "")

        setValue("inputSupervisorFirstName", "")
        setValue("inputSupervisorLastName", "")
        setValue("inputSupervisorTitle", "")
        setValue("inputSupervisorContactPhone", "")
        setValue("inputSupervisorContactEmail", "")

        setValue("inputExperienceDatesStartMonth", "")
        setValue("inputExperienceDatesStartYear", "")
        setValue("inputExperienceDatesEndMonth", "")
        setValue("inputExperienceDatesEndYear", "")
        setValue("inputExperienceDatesCurrentExperience", "")
        setValue("inputExperienceDatesStatus", "")

        setValue("inputExperienceDetailsTitle", "")
        setValue("inputExperienceDetailsTypeOfRecognition", [])
        setValue("inputExperienceDetailsAverageWeeklyHours", "")
        setValue("inputExperienceDetailsNumberOfWeeks", "")
        setValue("inputExperienceDetailsTotalHours", "")
        setValue("inputExperienceDetailsDescription", "")
        setValue("inputExperienceDetailsReleaseAuthorization", "")
        
        dispatch(newWorkActivity({status : false}));
      

      }
    }, [props.newWorkActivity])
    

    if(props.createWorkActivities.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createWorkActivities.workactivity.length && typeof props.createWorkActivities.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Experience!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createWorkActivityReset());
        
      }

      else if(typeof props.createWorkActivities.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Experience!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createWorkActivityReset());       
        
      }

    }


    if(props.updateWorkActivities.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateWorkActivities.workactivity.length && typeof props.updateWorkActivities.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Experience!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateWorkActivityReset());
        
        
      }

      else if(typeof props.updateWorkActivities.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Experience!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateWorkActivityReset());
        
      }

      

    }


    const findWorkActivity = (arr,workactivityId) => {
      
      return arr.find(x => x.workActivityId === workactivityId);
    }

    


    const onSubmit = (data) => {
      
      // data.preventDefault();
  
      
      // let inputExperienceDetailsTypeOfRecognitionArr = [];
      // data.inputExperienceDetailsTypeOfRecognition.map((value) => {
        
      //   inputExperienceDetailsTypeOfRecognitionArr.push(value.value)
      // })

      // data.inputExperienceDetailsTypeOfRecognition = inputExperienceDetailsTypeOfRecognitionArr

      // console.log(data.inputExperienceDetailsTypeOfRecognition)
      // return false
      data['userId'] = props.userDetails.userId;
      
      if(workactivityId){
        data['docId'] = workactivityId;
        
        
        dispatch(updateWorkActivity(data));
      }
      else{
        
        dispatch(createWorkActivity(data));
      }
      
    }


    const countWords = (str) => {
      str = str.trim();

      // Return 0 if the string is empty
      if (str === "") {
        return 0;
      }
      
      // Split the string into an array of words using whitespace as the delimiter
      const words = str.split(/\s+/);
      
      // Return the number of words
      return words.length;

    }

 


    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [cityList, setCityList] = useState([]);
    const [stateList, setStateList] = useState([]);

    const handleCountryChange = (selectedOption) => {
      setValue("inputOrgCountry", selectedOption.value)
      setSelectedCountry(selectedOption.value);
      setSelectedCity('');
     
      // if (selectedCountry) {
      //   const city = City.getCitiesOfCountry(selectedCountry);
      //   setCityList(city.map((state) => state.name))
      // }
  
    };

    const handleStateChange = (selectedOption) => {
      setValue("inputOrgState", selectedOption.value)
      setSelectedState(selectedOption.value);
    };

    const handleCityChange = (selectedOption) => {
      setValue("inputOrgCity", selectedOption.value)
      setSelectedCity(selectedOption.value);
    };

    useEffect(() => {
      if (selectedCountry) {
        const country = Country.getCountryByCode(selectedCountry);
        const state = State.getStatesOfCountry(country.isoCode);
        const states = [...new Set(state.map((state) => {return {value:state.isoCode, label: state.name}}))];
        
        setStateList(states)

        
      }
    }, [selectedCountry])

    useEffect(() => {
    
      if (selectedState) {
        const country = Country.getCountryByCode(selectedCountry);
        const city = City.getCitiesOfState(country.isoCode,selectedState);
        const cities = [...new Set(city.map((city) => {return {value:city.name, label: city.name}}))];
        setCityList(cities)
      }

      

    }, [selectedState])


    const handleExperienceDetailsTypeOfRecognition = (e) =>{

      
      setValue("inputExperienceDetailsTypeOfRecognition", e)

    }

    const handleOnChangeForTotalHours = (inputName, e) => {

        setValue(inputName, e.target.value)

        setValue("inputExperienceDetailsTotalHours", getValues("inputExperienceDetailsAverageWeeklyHours") * getValues("inputExperienceDetailsNumberOfWeeks"))
    }

    return (
        
      <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddWorkActivities} toggle={toggleAddWorkActivities} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{workactivityId ? "Update" : "Add" }  Experience</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    Experience Type
                  </h6>
                  <div className="pl-lg-4">
                    <Row>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceType"
                          >
                            What type of experience do you want to add? <span className='text-danger'>*</span>
                          </label>

                          <Select 
                            placeholder='Select Experience Type'
                            options={experienceType} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceType", e.value)}
                            
                            value={experienceType.find((option) => option.value === watch("inputExperienceType"))}
                          />

                        { errors.inputExperienceType && <small className="text-danger">{errors.inputExperienceType.message}</small> }
                        </FormGroup>
                        
                      </Col>
                    

                    </Row>
                  </div>
                 
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Organization
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgName"
                          >
                            Name <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputOrgName"
                            name="inputOrgName"
                            value={watch("inputOrgName")}
                            onChange={e => setValue("inputOrgName", e.target.value)}
                            type="text"
                          />
                          { errors.inputOrgName && <small className="text-danger">{errors.inputOrgName.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgAddress"
                          >
                            Address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputOrgAddress"
                            name="inputOrgAddress"
                            value={watch("inputOrgAddress")}
                            onChange={e => setValue("inputOrgAddress", e.target.value)}
                            type="text"
                          />
                          
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgAddress2"
                          >
                            Address 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputOrgAddress2"
                            name="inputOrgAddress2"
                            value={watch("inputOrgAddress2")}
                            onChange={e => setValue("inputOrgAddress2", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgCountry"
                          >
                            Country <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder="Select Country" 
                            options={optionCountries} 
                            styles={customDropdown }  
                            onChange={handleCountryChange}
                            
                            value={optionCountries.find((option) => option.value === watch("inputOrgCountry"))}
                          />
                          { errors.inputOrgCountry && <small className="text-danger">{errors.inputOrgCountry.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgState"
                          >
                            State <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder="Select State" 
                            options={stateList} 
                            styles={customDropdown }  
                            onChange={handleStateChange}
                            
                            value={stateList.find((option) => option.value === watch("inputOrgState"))}
                          />
                          { errors.inputOrgState && <small className="text-danger">{errors.inputOrgState.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrgCity"
                          >
                            City
                          </label>
                          <Select 
                            placeholder="Select City" 
                            options={cityList} 
                            styles={customDropdown }  
                            onChange={handleCityChange}
                            
                            value={cityList.find((option) => option.value === watch("inputOrgCity"))}
                          />
                        </FormGroup>
                      </Col>
                      
                    </Row>
                  </div>
                  

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Supervisor
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSupervisorFirstName"
                          >
                            First Name 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSupervisorFirstName"
                            name="inputSupervisorFirstName"
                            value={watch("inputSupervisorFirstName")}
                            onChange={e => setValue("inputSupervisorFirstName", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSupervisorLastName"
                          >
                            Last Name 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSupervisorLastName"
                            name="inputSupervisorLastName"
                            value={watch("inputSupervisorLastName")}
                            onChange={e => setValue("inputSupervisorLastName", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSupervisorTitle"
                          >
                            Title 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSupervisorTitle"
                            name="inputSupervisorTitle"
                            value={watch("inputSupervisorTitle")}
                            onChange={e => setValue("inputSupervisorTitle", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSupervisorContactPhone"
                          >
                            Contact Phone 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSupervisorContactPhone"
                            name="inputSupervisorContactPhone"
                            value={watch("inputSupervisorContactPhone")}
                            onChange={e => setValue("inputSupervisorContactPhone", e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputSupervisorContactEmail"
                          >
                            Contact Email 
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputSupervisorContactEmail"
                            name="inputSupervisorContactEmail"
                            value={watch("inputSupervisorContactEmail")}
                            onChange={e => setValue("inputSupervisorContactEmail", e.target.value)}
                            type="text"
                          />
                          { errors.inputSupervisorContactEmail && <small className="text-danger">{errors.inputSupervisorContactEmail.message}</small> }
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Experience Dates
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDatesStartMonth"
                          >
                            Start Date <span className='text-danger'>*</span>
                          </label>

                          <Select 
                            placeholder='Select Month'
                            options={monthsList} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesStartMonth", e.value)}
                            
                            value={monthsList.find((option) => option.value === watch("inputExperienceDatesStartMonth"))}
                          />
                          { errors.inputExperienceDatesStartMonth && <small className="text-danger">{errors.inputExperienceDatesStartMonth.message}</small> }

                          <Select 
                            placeholder='Select Year'
                            className='mt-2'
                            
                            options={years} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesStartYear", e.value)}
                            
                            value={years.find((option) => option.value === watch("inputExperienceDatesStartYear"))}
                          />

                          { errors.inputExperienceDatesStartYear && <small className="text-danger">{errors.inputExperienceDatesStartYear.message}</small> }
                          {errors.inputExperienceDatesStartYear && errors.inputExperienceDatesStartYear.type === "validationFirstScenario" && (<small className="text-danger">Your 1st start date must begin before it's end date.</small>)}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDatesEndMonth"
                          >
                            End Date <span className='text-danger'>*</span>
                          </label>
                         

                          <Select 
                            placeholder='Select Month'
                            
                            
                            options={monthsList} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesEndMonth", e.value)}
                            
                            value={monthsList.find((option) => option.value === watch("inputExperienceDatesEndMonth"))}
                          />

                          { errors.inputExperienceDatesEndMonth && <small className="text-danger">{errors.inputExperienceDatesEndMonth.message}</small> }
                        

                          <Select 
                            placeholder='Select Year'
                            className='mt-2'
                            options={years} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesEndYear", e.value)}
                            
                            value={years.find((option) => option.value === watch("inputExperienceDatesEndYear"))}
                          />

                          { errors.inputExperienceDatesEndYear && <small className="text-danger">{errors.inputExperienceDatesEndYear.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDatesCurrentExperience"
                          >
                            Current Experience <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder='Select Current Experience'
                            className='mt-2'
                            
                            options={yesNoArr} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesCurrentExperience", e.value)}
                            
                            value={yesNoArr.find((option) => option.value === watch("inputExperienceDatesCurrentExperience"))}
                          />
                          { errors.inputExperienceDatesCurrentExperience && <small className="text-danger">{errors.inputExperienceDatesCurrentExperience.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDatesStatus"
                          >
                            Status <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder='Select Status'
                            className='mt-2'
                            options={statusArr} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDatesStatus", e.value)}
                            
                            value={statusArr.find((option) => option.value === watch("inputExperienceDatesStatus"))}
                          />
                          { errors.inputExperienceDatesStatus && <small className="text-danger">{errors.inputExperienceDatesStatus.message}</small> }
                        </FormGroup>
                      </Col>
                      
                    </Row>
                  </div>
                  
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Experience Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsTitle"
                          >
                            Title <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputExperienceDetailsTitle"
                            name="inputExperienceDetailsTitle"
                            value={watch("inputExperienceDetailsTitle")}
                            onChange={e => setValue("inputExperienceDetailsTitle", e.target.value)}
                            type="text"
                          />
                          { errors.inputExperienceDetailsTitle && <small className="text-danger">{errors.inputExperienceDetailsTitle.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsTypeOfRecognition"
                          >
                            Type of Recognition <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder='Select Type of Recognition'
                            className='mt-2'
                            isMulti
                            options={typeOfRecognitionArr} 
                            styles={customDropdown }  
                            onChange={(e) => handleExperienceDetailsTypeOfRecognition(e)}
                            
                            value={watch("inputExperienceDetailsTypeOfRecognition")}
                          />
                          { errors.inputExperienceDetailsTypeOfRecognition && <small className="text-danger">{errors.inputExperienceDetailsTypeOfRecognition.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsAverageWeeklyHours"
                          >
                            Average Weekly Hours <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputExperienceDetailsAverageWeeklyHours"
                            name="inputExperienceDetailsAverageWeeklyHours"
                            value={watch("inputExperienceDetailsAverageWeeklyHours")}
                            onChange={e => handleOnChangeForTotalHours("inputExperienceDetailsAverageWeeklyHours" , e)}
                            type="number"
                          />
                          { errors.inputExperienceDetailsAverageWeeklyHours && <small className="text-danger">{errors.inputExperienceDetailsAverageWeeklyHours.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsNumberOfWeeks"
                          >
                            Number of Weeks <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputExperienceDetailsNumberOfWeeks"
                            name="inputExperienceDetailsNumberOfWeeks"
                            value={watch("inputExperienceDetailsNumberOfWeeks")}
                            onChange={e => handleOnChangeForTotalHours("inputExperienceDetailsNumberOfWeeks" , e)}
                            type="number"
                          />
                          { errors.inputExperienceDetailsNumberOfWeeks && <small className="text-danger">{errors.inputExperienceDetailsNumberOfWeeks.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsTotalHours"
                          >
                            Total Hours (AWH x NoW) <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputExperienceDetailsTotalHours"
                            name="inputExperienceDetailsTotalHours"
                            value={watch("inputExperienceDetailsTotalHours")}
                            type="number"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      
                    
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsDescription">
                            Description / Key Responsibilities <span className='text-danger'>*</span>
                          </label>
                            <Input
                                id="inputExperienceDetailsDescription"
                                name="inputExperienceDetailsDescription"
                                value={watch("inputExperienceDetailsDescription")}
                                placeholder=""
                                rows="5"
                                type="textarea"
                                onChange={e => setValue("inputExperienceDetailsDescription", e.target.value)}
                            />
                            <div className='text-sm'>Character count : {(typeof getValues("inputExperienceDetailsDescription") == "undefined")  ? "0" : getValues("inputExperienceDetailsDescription").length} | Word Count : {countWords((typeof getValues("inputExperienceDetailsDescription") == "undefined")  ? "" : getValues("inputExperienceDetailsDescription"))}</div>
                            { errors.inputExperienceDetailsDescription && <small className="text-danger">{errors.inputExperienceDetailsDescription.message}</small> }   
                        </FormGroup>
                      </Col>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputExperienceDetailsReleaseAuthorization"
                          >
                            Release Authorization (May we contact this organization?) <span className='text-danger'>*</span>
                          </label>
                          <Select 
                            placeholder='Select Release Authorization'
                            className='mt-2'
                            options={yesNoArr} 
                            styles={customDropdown }  
                            onChange={(e) => setValue("inputExperienceDetailsReleaseAuthorization", e.value)}
                            
                            value={yesNoArr.find((option) => option.value === watch("inputExperienceDetailsReleaseAuthorization"))}
                          />
                          { errors.inputExperienceDetailsReleaseAuthorization && <small className="text-danger">{errors.inputExperienceDetailsReleaseAuthorization.message}</small> }
                        </FormGroup>
                      </Col>
                      
                    </Row>
                  </div>

              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            {props.adminUser.admin ? "" :
              <>
              <Button color="primary" type='submit'>
              {workactivityId ? "Update" : "Save" } 
              </Button>{' '}
              <Button color="secondary" onClick={toggleAddWorkActivities}>
                Cancel
              </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
    );

}

const mapStateToProps = state => {
  return {
    createWorkActivities : state.createWorkActivities,
    updateWorkActivities : state.updateWorkActivities,
    editWorkActivity : state.editWorkActivity,
    fetchWorkActivities : state.fetchWorkActivities,
    newWorkActivity : state.newWorkActivity,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser

  }
}


export default connect(mapStateToProps)(WorkActivitiesModal)