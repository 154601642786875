export const FETCH_QUESTION_BANK_REQUEST = 'FETCH_QUESTION_BANK_REQUEST';
export const FETCH_QUESTION_BANK_SUCCESS = 'FETCH_QUESTION_BANK_SUCCESS';
export const FETCH_QUESTION_BANK_FAILURE = 'FETCH_QUESTION_BANK_FAILURE';


export const CREATE_PRACTICE_TEST_RESULT_REQUEST = 'CREATE_PRACTICE_TEST_RESULT_REQUEST';
export const CREATE_PRACTICE_TEST_RESULT_SUCCESS = 'CREATE_PRACTICE_TEST_RESULT_SUCCESS';
export const CREATE_PRACTICE_TEST_RESULT_FAILURE = 'CREATE_PRACTICE_TEST_RESULT_FAILURE';
export const CREATE_PRACTICE_TEST_RESULT_RESET = 'CREATE_PRACTICE_TEST_RESULT_RESET';

export const FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST = 'FETCH_PRACTICE_TEST_ATTEMPTS_REQUEST';
export const FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS = 'FETCH_PRACTICE_TEST_ATTEMPTS_SUCCESS';
export const FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE = 'FETCH_PRACTICE_TEST_ATTEMPTS_FAILURE';