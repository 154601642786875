export const CREATE_COMMUNITY_POST_REQUEST = "CREATE_COMMUNITY_POST_REQUEST";
export const CREATE_COMMUNITY_POST_SUCCESS = "CREATE_COMMUNITY_POST_SUCCESS";
export const CREATE_COMMUNITY_POST_FAILURE = "CREATE_COMMUNITY_POST_FAILURE";
export const CREATE_COMMUNITY_POST_RESET = "CREATE_COMMUNITY_POST_RESET";

export const UPDATE_COMMUNITY_POST_REQUEST = "UPDATE_COMMUNITY_POST_REQUEST";
export const UPDATE_COMMUNITY_POST_SUCCESS = "UPDATE_COMMUNITY_POST_SUCCESS";
export const UPDATE_COMMUNITY_POST_FAILURE = "UPDATE_COMMUNITY_POST_FAILURE";
export const UPDATE_COMMUNITY_POST_RESET = "UPDATE_COMMUNITY_POST_RESET";

export const DELETE_COMMUNITY_POST_REQUEST = "DELETE_COMMUNITY_POST_REQUEST";
export const DELETE_COMMUNITY_POST_SUCCESS = "DELETE_COMMUNITY_POST_SUCCESS";
export const DELETE_COMMUNITY_POST_FAILURE = "DELETE_COMMUNITY_POST_FAILURE";
export const DELETE_COMMUNITY_POST_RESET = "DELETE_COMMUNITY_POST_RESET";

export const FETCH_ALL_COMMUNITY_POSTS_RESET =
  "FETCH_ALL_COMMUNITY_POSTS_RESET";
export const FETCH_ALL_COMMUNITY_POSTS_REQUEST =
  "FETCH_ALL_COMMUNITY_POSTS_REQUEST";
export const FETCH_ALL_COMMUNITY_POSTS_FAILURE =
  "FETCH_ALL_COMMUNITY_POSTS_FAILURE";
export const FETCH_ALL_COMMUNITY_POSTS_SUCCESS =
  "FETCH_ALL_COMMUNITY_POSTS_SUCCESS";

export const CREATE_COMMUNITY_COMMENT_REQUEST =
  "CREATE_COMMUNITY_COMMENT_REQUEST";
export const CREATE_COMMUNITY_COMMENT_SUCCESS =
  "CREATE_COMMUNITY_COMMENT_SUCCESS";
export const CREATE_COMMUNITY_COMMENT_FAILURE =
  "CREATE_COMMUNITY_COMMENT_FAILURE";
export const CREATE_COMMUNITY_COMMENT_RESET = "CREATE_COMMUNITY_COMMENT_RESET";

export const UPDATE_COMMUNITY_COMMENT_REQUEST =
  "UPDATE_COMMUNITY_COMMENT_REQUEST";
export const UPDATE_COMMUNITY_COMMENT_SUCCESS =
  "UPDATE_COMMUNITY_COMMENT_SUCCESS";
export const UPDATE_COMMUNITY_COMMENT_FAILURE =
  "UPDATE_COMMUNITY_COMMENT_FAILURE";
export const UPDATE_COMMUNITY_COMMENT_RESET = "UPDATE_COMMUNITY_COMMENT_RESET";

export const DELETE_COMMUNITY_COMMENT_REQUEST =
  "DELETE_COMMUNITY_COMMENT_REQUEST";
export const DELETE_COMMUNITY_COMMENT_SUCCESS =
  "DELETE_COMMUNITY_COMMENT_SUCCESS";
export const DELETE_COMMUNITY_COMMENT_FAILURE =
  "DELETE_COMMUNITY_COMMENT_FAILURE";
export const DELETE_COMMUNITY_COMMENT_RESET = "DELETE_COMMUNITY_COMMENT_RESET";

export const CREATE_COMMUNITY_REPLY_REQUEST = "CREATE_COMMUNITY_REPLY_REQUEST";
export const CREATE_COMMUNITY_REPLY_SUCCESS = "CREATE_COMMUNITY_REPLY_SUCCESS";
export const CREATE_COMMUNITY_REPLY_FAILURE = "CREATE_COMMUNITY_REPLY_FAILURE";
export const CREATE_COMMUNITY_REPLY_RESET = "CREATE_COMMUNITY_REPLY_RESET";

export const UPDATE_COMMUNITY_REPLY_REQUEST = "UPDATE_COMMUNITY_REPLY_REQUEST";
export const UPDATE_COMMUNITY_REPLY_SUCCESS = "UPDATE_COMMUNITY_REPLY_SUCCESS";
export const UPDATE_COMMUNITY_REPLY_FAILURE = "UPDATE_COMMUNITY_REPLY_FAILURE";
export const UPDATE_COMMUNITY_REPLY_RESET = "UPDATE_COMMUNITY_REPLY_RESET";

export const DELETE_COMMUNITY_REPLY_REQUEST = "DELETE_COMMUNITY_REPLY_REQUEST";
export const DELETE_COMMUNITY_REPLY_SUCCESS = "DELETE_COMMUNITY_REPLY_SUCCESS";
export const DELETE_COMMUNITY_REPLY_FAILURE = "DELETE_COMMUNITY_REPLY_FAILURE";
export const DELETE_COMMUNITY_REPLY_RESET = "DELETE_COMMUNITY_REPLY_RESET";

export const CREATE_COMMUNITY_LIKEPOST_REQUEST =
  "CREATE_COMMUNITY_LIKEPOST_REQUEST";
export const CREATE_COMMUNITY_LIKEPOST_SUCCESS =
  "CREATE_COMMUNITY_LIKEPOST_SUCCESS";
export const CREATE_COMMUNITY_LIKEPOST_FAILURE =
  "CREATE_COMMUNITY_LIKEPOST_FAILURE";
export const CREATE_COMMUNITY_LIKEPOST_RESET =
  "CREATE_COMMUNITY_LIKEPOST_RESET";

export const CREATE_COMMUNITY_SAVEPOST_REQUEST =
  "CREATE_COMMUNITY_SAVEPOST_REQUEST";
export const CREATE_COMMUNITY_SAVEPOST_SUCCESS =
  "CREATE_COMMUNITY_SAVEPOST_SUCCESS";
export const CREATE_COMMUNITY_SAVEPOST_FAILURE =
  "CREATE_COMMUNITY_SAVEPOST_FAILURE";
export const CREATE_COMMUNITY_SAVEPOST_RESET =
  "CREATE_COMMUNITY_SAVEPOST_RESET";

export const CREATE_COMMUNITY_LIKECOMMENT_REQUEST =
  "CREATE_COMMUNITY_LIKECOMMENT_REQUEST";
export const CREATE_COMMUNITY_LIKECOMMENT_SUCCESS =
  "CREATE_COMMUNITY_LIKECOMMENT_SUCCESS";
export const CREATE_COMMUNITY_LIKECOMMENT_FAILURE =
  "CREATE_COMMUNITY_LIKECOMMENT_FAILURE";
export const CREATE_COMMUNITY_LIKECOMMENT_RESET =
  "CREATE_COMMUNITY_LIKECOMMENT_RESET";

export const FETCH_COMMUNITY_LIKEDPOST_RESET =
  "FETCH_COMMUNITY_LIKEDPOST_RESET";
export const FETCH_COMMUNITY_LIKEDPOST_REQUEST =
  "FETCH_COMMUNITY_LIKEDPOST_REQUEST";
export const FETCH_COMMUNITY_LIKEDPOST_FAILURE =
  "FETCH_COMMUNITY_LIKEDPOST_FAILURE";
export const FETCH_COMMUNITY_LIKEDPOST_SUCCESS =
  "FETCH_COMMUNITY_LIKEDPOST_SUCCESS";

export const FETCH_LEADERBOARD_RESET = "FETCH_LEADERBOARD_RESET";
export const FETCH_LEADERBOARD_REQUEST = "FETCH_LEADERBOARD_REQUEST";
export const FETCH_LEADERBOARD_FAILURE = "FETCH_LEADERBOARD_FAILURE";
export const FETCH_LEADERBOARD_SUCCESS = "FETCH_LEADERBOARD_SUCCESS";
