import React, { useState, useEffect } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Col,
} from "reactstrap";
import Header from 'components/Headers/Header'
import Plans from './Plans';
import { connect } from 'react-redux';
const McatPowerbook = (props) => {

    const [showPlans, setShowPlans] = useState(true)
    useEffect(() => {
        const launchDate = "2023-08-19T15:00:00.000Z";
        const launchDateEnd = "2023-08-19T18:00:00.000Z";
        const userCreatedAt = new Date(props.userDetails.createdAt)
        const userCreatedAtSubtractedDate = new Date(userCreatedAt - 4 * 60 * 60 * 1000);
  
        if(userCreatedAtSubtractedDate.toISOString() >= launchDate && userCreatedAtSubtractedDate.toISOString() <= launchDateEnd){
            setShowPlans(false)
        }
        else{
            setShowPlans(true)
        }

    }, [])
    
  return (
    <>
        <Header></Header>
            <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div className='float-left'>
                                <h5 className="h3 mb-0">MCAT Powerbook</h5>
                            </div>
                        </CardHeader>
                        <Row className="pl-4 pr-4">
                            <Col lg='12'>
                                <div style={{ position: 'relative', width: '100%' }} className='mb-4'>
                                    <iframe
                                        src="https://drive.google.com/file/d/1752SI5clpVomsqqc8nzqvbuHHfN0T7xF/preview"
                                        width="100%"
                                        height="1000"
                                        title="Embedded Document"
                                    ></iframe>
                                    <img
                                        src="https://storage.googleapis.com/msgsndr/wig7yijC1mCvj5tHVvxz/media/6331f1b3bbea4f012835ec1f.png"
                                        style={{ width: '50px', height: '50px', position: 'absolute', right: '12px', top: '12px', background: '#fff' }}
                                        alt="Icon"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    
                </div>
            </Row>
        </Container>
        {showPlans ? <Plans></Plans> : ""}
        
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }
      
}

export default connect(mapStateToProps)(McatPowerbook)