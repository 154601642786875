/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
const UserHeader = (props) => {
  return (
    <>
      <div
        className="header bg-gradient-info pb-7 pt-5 pt-md-8"
    
      >
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">Hello  {props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].fullName.split(" ")[0] : props.userDetails.name.split(" ")[0]}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page. Take a moment to update your basic information and contact details.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = state  => {
  return {
      userDetails : state.loginAuth.userDetails,
      updateUserProfile : state.updateUserProfile,
  }
    
}

export default connect(mapStateToProps)(UserHeader);
