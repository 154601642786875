import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Media,
  Badge,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Form,
  Col,
  FormGroup,
  CardBody,
  Input,
  ModalHeader,
  ModalFooter,
  ModalBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  CustomInput,
  Alert,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  fetchAllUsers,
  fetchUserDetails,
  updateUserDetails,
  updateUserDetailsReset,
  updateMultiUsers,
  updateMultiUsersReset,
  
} from "redux/ManageUsers/ManageUsersAction";
import {fetchUniversities}  from "redux/Users/UsersActions";
import { fetchCoaches } from "redux/ScheduleTutor/ScheduleTutorActions";
import { customDropdown } from "util/reactCustomStyles";
import classnames from "classnames";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import { app, db } from "../../firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import moment from "moment-timezone";

const ManageUsersModal = (props) => {
  	const dispatch = useDispatch();

	const [checkUsers, setCheckUsers] = useState([]);
	const [school, setSchool] = useState('');
	const [universities, setUniversities] = useState([])

	useEffect(() => {
		register("name");

		register("mcatDate");
		register("tutorHours");
		register("inputUworld");
		register("inputAamc");
        register("inputBootcamp");
		register("email");
		register("homeAddress");

		register("tpcEntryDate");
		register("mcatDate");
		register("schoolYear");
		register("university");

		register("selectedCountry");
		register("selectedCity");
		register("selectedState");
		register("postalCode");
		register("tutorHours");
		register("shadowGoal");
		register("clinicalGoal");
		register("volunteerGoal");

		register("ultimateAdvisingStudent");
		register("coach");

		register("assignedCoach");

		register("matriculated");

		// dispatch(
		// fetchAllUsers({
		// 	route: "",
		// 	order: order,
		// 	by: by,
		// 	searchFullName: searchFullName,
		// 	subscriptionFilter: subscription,
		// })
		// );
	}, []);

	const {
		register,
		getValues,
		unregister,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();


	useEffect(() => {
		if (props.fetchUserDetails.userdetails.length) {
		setValue("name", props.fetchUserDetails.userdetails[0].name);

		setValue("email", props.fetchUserDetails.userdetails[0].email);
		setValue(
			"homeAddress",
			typeof props.fetchUserDetails.userdetails[0].homeAddress != "undefined"
			? props.fetchUserDetails.userdetails[0].homeAddress
			: ""
		);

		setValue(
			"tpcEntryDate",
			props.fetchUserDetails.userdetails[0].tpcEntryDate
		);
		setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
		setValue(
			"schoolYear",
			typeof props.fetchUserDetails.userdetails[0].schoolYear != "undefined"
			? props.fetchUserDetails.userdetails[0].schoolYear
			: ""
		);
		setValue(
			"university",
			typeof props.fetchUserDetails.userdetails[0].university != "undefined"
			? props.fetchUserDetails.userdetails[0].university
			: ""
		);

		setValue(
			"selectedCountry",
			typeof props.fetchUserDetails.userdetails[0].selectedCountry !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].selectedCountry
			: ""
		);
		setValue(
			"selectedCity",
			typeof props.fetchUserDetails.userdetails[0].selectedCity != "undefined"
			? props.fetchUserDetails.userdetails[0].selectedCity
			: ""
		);
		setValue(
			"selectedState",
			typeof props.fetchUserDetails.userdetails[0].selectedState !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].selectedState
			: ""
		);
		setValue(
			"postalCode",
			typeof props.fetchUserDetails.userdetails[0].postalCode != "undefined"
			? props.fetchUserDetails.userdetails[0].postalCode
			: ""
		);
		setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
		setValue("shadowGoal", props.fetchUserDetails.userdetails[0].shadowGoal);
		setValue(
			"clinicalGoal",
			props.fetchUserDetails.userdetails[0].clinicalGoal
		);
		setValue(
			"volunteerGoal",
			typeof props.fetchUserDetails.userdetails[0].volunteerGoal !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].volunteerGoal
			: 0
		);

		setValue("mcatDate", props.fetchUserDetails.userdetails[0].mcatDate);
		setValue("tutorHours", props.fetchUserDetails.userdetails[0].tutorHours);
		setValue(
			"inputUworld",
			props.fetchUserDetails.userdetails[0].subscriptions.includes("uworld")
			? "claimed"
			: "unclaimed"
		);
		setValue(
			"inputAamc",
			props.fetchUserDetails.userdetails[0].subscriptions.includes("aamc")
			? "claimed"
			: "unclaimed"
		);

        setValue(
			"inputBootcamp",
			props.fetchUserDetails.userdetails[0].subscriptions.includes("bootcamp")
			? "claimed"
			: "unclaimed"
		);

		setValue(
			"ultimateAdvisingStudent",
			typeof props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].ultimateAdvisingStudent
			: false
		);
		setValue(
			"coach",
			typeof props.fetchUserDetails.userdetails[0].coach != "undefined"
			? props.fetchUserDetails.userdetails[0].coach
			: false
		);

        setValue(
			"admin",
			typeof props.fetchUserDetails.userdetails[0].admin != "undefined"
			? props.fetchUserDetails.userdetails[0].admin
			: false
		);

		setValue(
			"assignedCoach",
			typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].assignedCoach
			: ""
		);
		if (
			typeof props.fetchUserDetails.userdetails[0].assignedCoach !=
			"undefined"
		) {
			setAssignedCoach(props.fetchUserDetails.userdetails[0].assignedCoach);
		} else {
			setAssignedCoach("");
		}

		setValue(
			"matriculated",
			typeof props.fetchUserDetails.userdetails[0].matriculated !=
			"undefined"
			? props.fetchUserDetails.userdetails[0].matriculated
			: ""
		);
		if (
			typeof props.fetchUserDetails.userdetails[0].matriculated !=
			"undefined"
		) {
			setSchool(props.fetchUserDetails.userdetails[0].matriculated);
		} else {
			setSchool("");
		}
		}
	}, [props.fetchUserDetails]);

    
	const [modalUpdateUser, setModalUpdateUser] = useState(false);
	const toggleUpdateUser = () => {
		setModalUpdateUser(!modalUpdateUser);
	};

	const editUserDetails = (e, data) => {
		e.preventDefault();
		setModalUpdateUser(data.modal);
		dispatch(fetchUserDetails({ userId: data.userId }));

		dispatch(fetchUniversities({}));

		setValue("name", "");
		setValue("email", "");
		setValue("homeAddress", "");
		setValue("tpcEntryDate", "");
		setValue("mcatDate", "");
		setValue("schoolYear", "");
		setValue("university", "");
		setValue("selectedCountry", "");
		setValue("selectedCity", "");
		setValue("selectedState", "");
		setValue("postalCode", "");
		setValue("tutorHours", "");
		setValue("shadowGoal", "");
		setValue("clinicalGoal", "");
		setValue("volunteerGoal", "");
		setValue("assignedCoach", "");

		// setValue("ultimateAdvisingStudent", "");
		// setValue("coach",  "");
	};

    const onSubmit = (data) => {
        data.subscriptions = [];

        if (data.inputAamc == "claimed") {
        data.subscriptions.push("aamc");
        }
        if (data.inputBootcamp == "claimed") {
        data.subscriptions.push("bootcamp");
        }
        if (data.inputUworld == "claimed") {
        data.subscriptions.push("uworld");
        }
        data.handle = props.fetchUserDetails.userdetails[0].handle;
        dispatch(updateUserDetails(data));
    };

    useEffect(() => {
        if (props.updateUserDetails.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            if(props.updateUserDetails.userdetails.length){
                Swal.fire({
                    title: "Success!",
                    text: "Successfully updated user!",
                    icon: "success",
                    timer: 3000,
                }).then(() => {
                    dispatch(updateUserDetailsReset());
                    dispatch(
                    fetchAllUsers({route: ""})
                    );
                });
            }
        }
    }, [props.updateUserDetails]);

	const handleViewUser = (e, userDetails) => {
		e.preventDefault();
		dispatch(getUserData({ user: userDetails }));
	};

    const handleAssignedCoach = (selectedOption) => {
        setAssignedCoach(selectedOption.value);
        setValue("assignedCoach", selectedOption.value);
    };

  	const [coaches, setCoaches] = useState([]);
  	const [assignedcoach, setAssignedCoach] = useState("");

    useEffect(() => {
        if (props.fetchCoaches.fetchcoaches.length) {
            const coachesArr = props.fetchCoaches.fetchcoaches[0];
            setCoaches(coachesArr);
        }
    }, [props.fetchCoaches]);


  useEffect(() => {
    if(props.fetchUniversities.universities.length){
      let universitiesList = props.fetchUniversities.universities[0].universities.sort()
      universitiesList = [...new Set(universitiesList.map((value, index) => {return {value:value, label: value}}))];
      setUniversities(universitiesList)
    }
  }, [props.fetchUniversities.universities])

	const handleUniversityChange = (selectedOption) => {
		setValue("matriculated", selectedOption.value);
		setSchool(selectedOption.value);
	};

 
	const convertDate = (date) => {
        return moment(date).utcOffset(0, false).format('ll');
    }


  return (
		<>

            <button
                onClick={(e) => handleViewUser(e, props.item)}
                className="btn btn-sm btn-success"
            >
                View
            </button>
            <button
                onClick={(e) =>
                    editUserDetails(e, {
                    modal: true,
                    userId: props.item.userId,
                    })
                }
                    className="btn btn-sm btn-primary"
                >
                Edit
            </button>
          
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalUpdateUser} toggle={toggleUpdateUser} >
            <Form  onSubmit={handleSubmit(onSubmit)}>
                <ModalBody className='modal-body p-0'>
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col xs="8"><h3 className="mb-0">Edit User</h3></Col>
                                <Col className="text-right" xs="4"></Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                            <h6 className="heading-small text-muted mb-4">
                                User Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="name"
                                            >
                                                Full Name
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="name"
                                                name="name"
                                                value={watch("name")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="email"
                                            >
                                                Email Address
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="email"
                                                name="email"
                                                value={watch("email")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="tpcEntryDate"
                                            >
                                                Entry Date
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="tpcEntryDate"
                                                name="tpcEntryDate"
                                                value={watch("tpcEntryDate")}
                                                
                                                type="date"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="mcatDate"
                                            >
                                                MCAT Date
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="mcatDate"
                                                name="mcatDate"
                                                value={watch("mcatDate")}
                                                
                                                type="date"
                                                onChange={e => setValue("mcatDate", e.target.value)}
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="schoolYear"
                                            >
                                                School Year
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="schoolYear"
                                                name="schoolYear"
                                                value={watch("schoolYear")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="university"
                                            >
                                                University
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="university"
                                                name="university"
                                                value={watch("university")}
                                                
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="admin"
                                            >
                                            Admin
                                                        </label>
                                            <select
                                                id="admin"
                                                className="form-control form-control-alternative"
                                                value={watch("admin")}
                                                onChange={e => setValue("admin", e.target.value)}
                                                >
                                                    <option value="false">No</option>
                                                    <option value="true">Yes</option>
                                            </select>

                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="coach"
                                                >
                                                Coach
                                            </label>
                                            <select
                                                id="coach"
                                                className="form-control form-control-alternative"
                                                value={watch("coach")}
                                                onChange={e => setValue("coach", e.target.value)}
                                                >
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                                Contact Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="homeAddress"
                                            >
                                                Address
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="homeAddress"
                                                name="homeAddress"
                                                value={watch("homeAddress")}
                                                
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="selectedCountry"
                                            >
                                                Country
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="selectedCountry"
                                                name="selectedCountry"
                                                value={watch("selectedCountry")}
                                                
                                                type="text"
                                                disabled="disabled"
                                            />  
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="selectedState"
                                            >
                                                State
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="selectedState"
                                                name="selectedState"
                                                value={watch("selectedState")}
                                            
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="selectedCity"
                                            >
                                                City
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="selectedCity"
                                                name="selectedCity"
                                                value={watch("selectedCity")}
                                            
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="postalCode"
                                            >
                                                Postal Code
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="postalCode"
                                                name="postalCode"
                                    
                                                value={watch("postalCode")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                                Other Information
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputUworld"
                                            >
                                                UWORLD Subscription
                                            </label>
                                            <select
                                                id="inputUworld"
                                                className="form-control form-control-alternative"
                                                

                                                value={watch("inputUworld")}
                                                onChange={e => setValue("inputUworld", e.target.value)}
                                                >
                                                <option value="claimed">Claimed</option>
                                                <option value="unclaimed">Unclaimed</option>
                                            </select>  
                                        </FormGroup>
                                    </Col>
                                    {/* <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputAamc"
                                            >
                                                AAMC Subscription
                                            </label>
                                            <select
                                                id="inputAamc"
                                                className="form-control form-control-alternative"
                                                
                                                value={watch("inputAamc")}

                                                onChange={e => setValue("inputAamc", e.target.value)}
                                                >
                                                <option value="claimed">Claimed</option>
                                                <option value="unclaimed">Unclaimed</option>
                                            </select>
                                            
                                        </FormGroup>
                                    </Col> */}
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="inputBootcamp"
                                            >
                                                Bootcamp Subscription Used
                                            </label>
                                            <select
                                                id="inputBootcamp"
                                                className="form-control form-control-alternative"
                                                
                                                value={watch("inputBootcamp")}

                                                onChange={e => setValue("inputBootcamp", e.target.value)}
                                                >
                                                <option value="claimed">Claimed</option>
                                                <option value="unclaimed">Unclaimed</option>
                                            </select>
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="tutorHours"
                                            >
                                                Premium Tutoring Hours
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="tutorHours"
                                                name="tutorHours"
                                                
                                                value={watch("tutorHours")}
                                                type="number"
                                                onChange={ e => setValue("tutorHours", e.target.value)}
                                            />   
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="shadowHours"
                                            >
                                                Shadow Hours
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="shadowHours"
                                                name="shadowHours"
                                                
                                                value={watch("shadowGoal")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="clinicalHours"
                                            >
                                                Clinical Hours
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="clinicalHours"
                                                name="clinicalHours"
                                                
                                                value={watch("clinicalGoal")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="volunteerHours"
                                            >
                                                Volunteer Hours
                                            </label>
                                            <Input
                                                className="form-control-alternative is-invalid "
                                                id="volunteerHours"
                                                name="volunteerHours"
                                                
                                                value={watch("volunteerGoal")}
                                                type="text"
                                                disabled="disabled"
                                            />
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">  
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="ultimateAdvisingStudent"
                                            >
                                                Ultimate Advising Student 
                                            </label>
                                            
                                            <select
                                                id="ultimateAdvisingStudent"
                                                className="form-control form-control-alternative"
                                                

                                                value={watch("ultimateAdvisingStudent")}
                                                onChange={e => setValue("ultimateAdvisingStudent", e.target.value)}
                                                >
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>

                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="assignedCoach"
                                            >
                                            Assigned Coach
                                            </label>
                                            <Select
                                            placeholder="Select Coach"
                                            options={coaches}
                                            styles={customDropdown }
                                            id="assignedCoach"
                                            name="assignedCoach"
                                            onChange={handleAssignedCoach}
                                            menuPortalTarget={document.body}
                                            value={coaches.find(
                                                (option) => option.value === assignedcoach
                                            )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="matriculated"
                                            >
                                            Matriculated
                                            </label>
                                            <Select 
                                            id="matriculated"
                                                name="matriculated"
                                                placeholder="Select school" 
                                                options={universities} 
                                                styles={customDropdown }  
                                                onChange={handleUniversityChange}
                                                menuPortalTarget={document.body}
                                                value={universities.find((option) => option.value === school)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type='submit'>
                    Update
                    </Button>{' '}
                    <Button color="secondary" onClick={toggleUpdateUser}>
                    Close
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>      
      
		</>
	);
};

const mapStateToProps = (state) => {
  return {
    fetchAllUsers: state.fetchAllUsers,
    fetchUserDetails: state.fetchUserDetails,
    updateUserDetails: state.updateUserDetails,
    updateMultiUsersReducer: state.updateMultiUsersReducer,
    fetchCoaches: state.fetchCoaches,
    fetchUniversities : state.fetchUniversities,
  };
};

export default connect(mapStateToProps)(ManageUsersModal);
