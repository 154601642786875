/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const TotalCourses = (props) => {
    const dispatch = useDispatch();

   
    const students =  props.students.filter((v,i) => props.students.indexOf(v) === i)
    
    return (
        <>
            <Row className="mb-4">
                <Col  xl="4" className="d-flex">
                    <Card className="card-stats mb-4 mb-xl-0 w-100">
                        <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                Total Courses
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                    
                                    {props.courses.length}
                                </span>
                            </div>
                            <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                    <i className="fas fa-atom" />
                                </div>
                            </Col>
                        </Row>
                        
                        </CardBody>
                    </Card>
                </Col>
                <Col  xl="4" className="d-flex">
                    <Card className="card-stats mb-4 mb-xl-0 w-100">
                        <CardBody>
                        <Row>
                            <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                Total Sales
            
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                    
                                    ${props.payments}
                                </span>
                            
                            </div>
                            <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                <i className="fas fa-coins" />
                            </div>
                            </Col>
                        </Row>
                        
                        </CardBody>
                    </Card>
                </Col>
                <Col  xl="4" className="d-flex">
                    <Card className="card-stats mb-4 mb-xl-0 w-100">
                        <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                >
                                Total Students
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                        
                                    {students.length}
                                </span>
                            </div>
                            <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                <i className="fas fa-users" />
                            </div>
                            </Col>
                        </Row>
                        
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = state => {
  return {
   
    userDetails : state.loginAuth.userDetails,
  }
}

export default connect(mapStateToProps)(TotalCourses);
