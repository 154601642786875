import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { app, db } from "../../firebase";
import { collection,getDocs, query, where, onSnapshot, doc} from "firebase/firestore";

const UserInfo = (props) => {
    
    const [userArr, setUserInfo] = useState([]);


    useEffect(() => {
         const fetchUser = async () => {
                const userCollection = collection(db, "users");
                const getUser = query(userCollection, where("handle", "==", props.userHandle));
                const querySnapshot = await getDocs(getUser);
                querySnapshot.forEach((doc) => {
                    let user = doc.data()
                    setUserInfo(user)
                });
            }
            fetchUser()
    }, [props])

    return (
         
        <>
            {
                userArr ?
                    <>
                      { userArr.name == props.userDetails.name ? 'You' : userArr.name}
                    </> 
                : ''
            }     
          
        </>

    )

}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
    }  
}

export default connect(mapStateToProps)(UserInfo)
