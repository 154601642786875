import React, { useEffect, useState } from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import BillingDetailsForm from './BillingDetailsForm';
import { createCustomer, createCustomerReset, createSubscription, createSubscriptionReset } from 'redux/Payments/PaymentsActions';
import { connect, useDispatch } from 'react-redux';

import Swal from 'sweetalert2';

import {
    Card,
    Container,
    Row,
    Col,
    CardBody,
    CardHeader
} from "reactstrap";

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchDetailsSubscription } from 'redux/Payments/PaymentsActions';
const SubscriptionForm = (props) => {
    const history = useHistory()
    const [plan , setPlan] = useState('predentalTrackerProPlanMonthly')

    const options = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true,
            
        },
        terms : {
            card:"never"
        },
        fields :{
            billingDetails :{
                name:"never"
            }
        }
    }

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    
    const handleError = (error) => {
        setLoading(false);
        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

       
        
        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // setLoading(true);

        let addressElement = elements.getElement('address');

        addressElement.getValue()
            .then(function(result) {
                if (result.complete) {
                    // Allow user to proceed to the next step
                    // Optionally, use value to store the address details
                    let details = result.value;
                    details.email = props.userDetails.email;
                    details.type = "subscription"
                    dispatch(createCustomer(details))
                    
                }
            }
        )

        setLoading(true);

        // // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();

        if (submitError) {
            handleError(submitError);
            return;
        }
    }


    useEffect(() => {
      setPlan(props.selectedPlan)
    }, [])
    

    useEffect(() => {
        if(props.createCustomer.createcustomer.length){
            
            dispatch(createSubscription(
                    {
                        customerId : props.createCustomer.createcustomer[0].customerId , 
                        priceId : plan,
                        email : props.userDetails.email,
                        type: "subscription"
                    }
                )
            )
        }
    }, [props.createCustomer])

    useEffect(async () => {
        if(props.createSubscription.createsubscription.length){
            dispatch(createCustomerReset())

            // // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit();

            if (submitError) {
                handleError(submitError);
                return;
            }

            const {type, clientSecret, subsId} = await props.createSubscription.createsubscription[0];
            const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

            // Confirm the Intent using the details collected by the Payment Element
            const {error} = await confirmIntent({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: 'https://www.predentalTracker.io',
                },
                redirect : "if_required"
            });

            if (error) {
                // This point is only reached if there's an immediate error when confirming the Intent.
                // Show the error to your customer (for example, "payment details incomplete").
                handleError(error);

                Swal.fire({
                    title:'Failed!',
                    text: error.message,
                    icon :'error',
                    timer: 3000
                    
                    }
                ).then((result) => {
                    
                    dispatch(createSubscriptionReset());
                    
                })

            } else {
                
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                Swal.fire({
                    title:'Success!',
                    text:'Subscription confirmed!',
                    icon :'success',
                    timer: 3000
                    
                    }
                ).then((result) => {

                    

                    let planSelected = "monthly";

                    if(plan == "predentalTrackerProPlanYearly"){
                        planSelected = "yearly"
                    }
                    else if(plan == "predentalTrackerProPlanLifetime"){
                        planSelected = "lifetime"
                    }

                    history.push({pathname : '/client/congrats-' + planSelected, state : {plan : planSelected }})
                    
                    dispatch(fetchDetailsSubscription({ subsId : subsId }))

                    dispatch(createSubscriptionReset());
                })
            }
        }
    }, [props.createSubscription])
    
   
    const handleSelectPlan = (e,plan) => {
        e.preventDefault();
        setPlan(plan)

    }
    return (
        <Row>
            <Col xl="1" lg="1" md="1"></Col>
            <Col xl="5" lg="12" md="12" className='mb-3'>
            
                <Card className='border-0 shadow w-100'>
                    <CardHeader className={plan == "predentalTrackerProPlanMonthly" ? "bg-info" : plan == "predentalTrackerProPlanYearly" ? "bg-warning" : plan == "predentalTrackerProPlanLifetime" ? "bg-danger" : "" }>
                        <h1 className="h2 mb-0 text-center text-white">{plan == "predentalTrackerProPlanMonthly" ? "Pro Plan Monthly" : plan == "predentalTrackerProPlanYearly" ? "Pro Plan Yearly" : plan == "predentalTrackerProPlanLifetime" ? "Pro Plan Lifetime" : "" }</h1>
                        <h2 className="display-2 mb-0 text-center text-white">{plan == "predentalTrackerProPlanMonthly" ? "$15" : plan == "predentalTrackerProPlanYearly" ? "$150" : plan == "predentalTrackerProPlanLifetime" ? "$297" : "" }</h2>
                        <p className='display-4 font-italic text-center text-white'>{plan == "predentalTrackerProPlanMonthly" ? "per month" : plan == "predentalTrackerProPlanYearly" ? "per year" : plan == "predentalTrackerProPlanLifetime" ? "one-time" : "" }</p>
                        {plan == "predentalTrackerProPlanYearly" ? "" : plan == "predentalTrackerProPlanLifetime" ? "" : ""}
                        
                    </CardHeader>
                    <CardBody>
                        <Row className="pl-2 pr-2 h-100">
                            <Col lg='12 text-center' className='d-flex align-items-center flex-column h-100' >
                            <h3 className="h3 mb-0 text-dark mb-3">{plan == "predentalTrackerProPlanMonthly" ? "Comprehensive package offering full access to all features and functionalities of the service, catering to users who want an all-inclusive experience with no limitations." : plan == "predentalTrackerProPlanYearly" ? "Comprehensive package offering full access to all features and functionalities of the service, catering to users who want an all-inclusive experience with no limitations." : plan == "predentalTrackerProPlanLifetime" ? "Unlock unlimited access to all features and services for a lifetime. Enjoy the convenience of a one-time payment while experiencing the full range of benefits without any time constraints." : "" }</h3>
                            
                                {/* <FormGroup>
                                    <Input
                                        className="form-control-alternative"
                                        id="inputPersonalStatement"
                                        name="inputPersonalStatement"
                                        placeholder="Write a text here ..."
                                        rows="15"
                                        value={watch("inputPersonalStatement")}
                                        onChange={e => setValue("inputPersonalStatement", e.target.value)}
                                        type="textarea"
                                    />
                                    <div className='text-sm'>Character count : {(typeof getValues("inputPersonalStatement") == "undefined")  ? "0" : getValues("inputPersonalStatement").length} | Word Count : {countWords((typeof getValues("inputPersonalStatement") == "undefined")  ? "0" : getValues("inputPersonalStatement"))}</div>
                                </FormGroup> */}
                            </Col>
                        </Row>
                    </CardBody>
                    
                </Card>
                        
            </Col>
            <Col xl="5" lg="12" md="12">
                <Card className='border-0 shadow '>
                    <CardBody>
                        <form onSubmit={handleSubmit}>
                            <p className='h1 text-dark text-center'>Subscription Form</p>
                            <p className='h2 text-dark'>Plans</p>
                            
                            <Row>
                                {/* <Col md="12 mb-3">
                                    <button className={plan == "predentalTrackerProPlanMonthly" ? 'btn btn-info w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'predentalTrackerProPlanMonthly')}>Pro Plan: $15/month</button>
                                </Col> */}
                                <Col md="12 mb-3">
                                    <button className={plan == "predentalTrackerProPlanYearly" ? 'btn btn-warning w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'predentalTrackerProPlanYearly')}>Ultimate Plan: $150/year</button>
                                </Col>
                                <Col md="12">
                                    <button className={plan == "predentalTrackerProPlanLifetime" ? 'btn btn-danger w-100' : 'btn btn-secondary w-100' } onClick={e => handleSelectPlan(e,'predentalTrackerProPlanLifetime')}>Lifetime Plan: $297 (One-time payment)</button>
                                </Col>
                            </Row>
                            <br/>
                            
                            <p className='h2 text-dark'>Billing</p>
                            <BillingDetailsForm/>
                            <br/>
                            <p className='h2 text-dark'>Payment</p>
                            <PaymentElement options={options}  />
                            <br/>
                            <button className='btn btn-primary' disabled={!stripe || loading}>Subscribe</button>
                            
                            
                        </form>
                    </CardBody>
                </Card>
            </Col>
            <Col xl="1" lg="1" md="1"></Col>
        </Row>
    )
}

const mapStateToProps = state => {
    return {
        createCustomer : state.createCustomer,
        createSubscription : state.createSubscription,
        userDetails : state.loginAuth.userDetails,

    }
}

export default connect(mapStateToProps)(SubscriptionForm)