/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { fetchGrade } from "redux/Grades/GradesActions";

const CoursesHeader = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if(props.userDetails.handle){
        const userDetails = props.userDetails;
        dispatch(fetchGrade({handle : userDetails.handle}));
    }
  }, [props.userDetails])

  const grade = useSelector(state => state.fetchGrade.grade);
  return (
    <>
        <Row>
            <Col  xl="6" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                Science GPA
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                
                                {grade.length == 0 ? "0.0" : Number.isInteger(grade[0].cumulativeScience) ? grade[0].cumulativeScience.toFixed(1) : grade[0].cumulativeScience}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fas fa-atom" />
                            </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col>
            <Col  xl="6" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            Cumulative GPA
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{grade.length == 0 ? "0.0" :   Number.isInteger(grade[0].cumulativeTotal) ? grade[0].cumulativeTotal.toFixed(1) : grade[0].cumulativeTotal}</span>
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-coins" />
                        </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col>
            {/* <Col  xl="4" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            All Other/Non-Science GPA
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{grade.length == 0 ? "0.0" :  Number.isInteger(grade[0].cumulativeOther) ? grade[0].cumulativeOther.toFixed(1) : grade[0].cumulativeOther}</span>
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                        </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col> */}
        </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    fetchGrade : state.fetchGrade,
    userDetails : state.loginAuth.userDetails,
  }
}

export default connect(mapStateToProps)(CoursesHeader);
