import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container
  } from "reactstrap";
import SchoolCard from 'components/Card/SchoolSearch/SchoolCard';

import { useDispatch } from 'react-redux';
import { fetchSchoolSearch } from 'redux/SchoolSearch/SchoolSearchActions';

import { connect } from 'react-redux';
import Nouislider from "nouislider-react";
import LazyLoad from 'react-lazyload';
import Swal from 'sweetalert2'
import  Select  from 'react-select';
import {customDropdownWithBorder} from "util/reactCustomStyles"
import Plans from './Plans';
const SchoolSearch = (props) => {

  const dispatch = useDispatch();

  const [medicalSchoolList, setMedicalSchoolList] = useState([]);

  const [medicalSchoolStateList, setMedicalSchoolStateList] = useState([]);


  const [stateProvinceFilter, setStateProvinceFilter] = useState("");
  const [publicPrivateFilter, setPublicPrivateFilter] = useState("");
  const [gPAFilter, setGPAFilter] = useState({min:0, max:10});
  const [dATFilter, setDATFilter] = useState({min:0, max:30});
  const [classSizeFilter, setClassSizeFilter] = useState({min:0, max:500});
  const [schoolTypeFilter, setSchoolTypeFilter] = useState({schoolType:[]});
  const [campusTypeFilter, setCampusTypeFilter] = useState({campusType:[]});
  const [searchSchoolName, setSearchSchoolName] = useState("");
  const [requireCasperFilter, setRequireCasperFilter] = useState({requireCasper:[]});
  useEffect(() => {

    dispatch(fetchSchoolSearch({userId:props.userDetails.userId}));

  }, []);

  useEffect(() => {
        
    setMedicalSchoolList(props.fetchSchoolSearch.schoolsearch);

    let state = [];

    props.fetchSchoolSearch.schoolsearch.map((value, key)=>{
      state.push(value.overview.state);
    });

    state.sort();
    state = [...new Set(state)];
    // state = Array.from(new Set(state.map((s)=> {return {value:s.name, label: s.name}})));
    let states = [...new Set(state.map((value,index) => {return {value:value, label: value}}))];
    
    states.unshift({value:"" , label : "ALL"})
    setMedicalSchoolStateList(states);
    
  }, [props.fetchSchoolSearch])

  useEffect(async () => {

   
    filterStateProvince(props.fetchSchoolSearch.schoolsearch, stateProvinceFilter)
      

  }, [stateProvinceFilter, publicPrivateFilter,gPAFilter,dATFilter,classSizeFilter,searchSchoolName]);

 
  const filterStateProvince = (data, filter) => {
    let medicalSchoolListData = data;

    if(filter){
      
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state == filter);
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.state != filter);
    }

    
    filterTypeOfInstitution(medicalSchoolListData, publicPrivateFilter)
  }

  const filterTypeOfInstitution = (data, filter) => {
    

    let medicalSchoolListData = data;

    if(filter){
      
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.publicPrivate == filter);
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.publicPrivate != filter);
    }

    // setMedicalSchoolList(medicalSchoolListData);
    filterGPA(medicalSchoolListData, gPAFilter);

  }

  const filterGPA = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 3){
      medicalSchoolListData = medicalSchoolListData.filter(e =>{ 
        
        let gpa = e.gpa.Total;
        gpa = gpa == null || gpa == "" || gpa == "0.00" ? '10.0' : gpa;

        return (parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)) || gpa == ""
      
      });
    }
    else{

      

      medicalSchoolListData = medicalSchoolListData.filter(e => 
        {
          let gpa = e.gpa.Total;
          gpa = gpa == null || gpa == "" || gpa == "0.00" ? '10.0' : gpa;

          return parseFloat(gpa) >= parseFloat(filter.min) && parseFloat(gpa) <= parseFloat(filter.max)
      
      });
    }
    
      

    
    filterDAT(medicalSchoolListData,dATFilter);
    
  }

  const filterDAT = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 30){
      medicalSchoolListData = medicalSchoolListData.filter(e => {

        let dat = e.datScore["Total Science"];
        dat = dat == null || dat == "" || dat == "0.0" ? '30' : dat;

        // if(filter.min == 0 && Object.keys(e.datScore).length === 0){
        //   return true
        // }
        // if(filter.max == 30 && Object.keys(e.datScore).length === 0){
        //   return true
        // }

        return (parseFloat(dat) >= parseFloat(filter.min) && parseFloat(dat) <= parseFloat(filter.max)) || dat == ""}
        
        );
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => {
        
        let dat = e.datScore["Total Science"];
        dat = dat == null || dat == "" || dat == "0.0" ? '30' : dat;

        // if(filter.min == 0 && Object.keys(e.datScore).length === 0){
        //   return true
        // }
        // if(filter.max == 30 && Object.keys(e.datScore).length === 0){
        //   return true
        // }

        return parseFloat(dat) >= parseFloat(filter.min) && parseFloat(dat) <= parseFloat(filter.max)
      
      });
    }
    
    
    
    filterClassSize(medicalSchoolListData,classSizeFilter);
  }

  const filterClassSize = (data, filter) => {
    let medicalSchoolListData = data;
    
    if(parseFloat(filter.min) == 40){
      medicalSchoolListData = medicalSchoolListData.filter(e => (parseInt(e.overview.total) >= 0 && parseInt(e.overview.total) <= parseInt(filter.max)) || e.overview.total == null || e.overview.total == "");
    }
    else{
      medicalSchoolListData = medicalSchoolListData.filter(e => parseInt(e.overview.total) >= parseInt(filter.min) && parseInt(e.overview.total) <= parseInt(filter.max));
    }
    
      
  
    searchNameOfSchool(medicalSchoolListData, searchSchoolName);
    
    // setMedicalSchoolList(medicalSchoolListData);
  }


  const searchNameOfSchool = (data, filter) => {
    let medicalSchoolListData = data;
    
    
    if(filter){
      medicalSchoolListData = medicalSchoolListData.filter(e => e.overview.schoolName.toLowerCase().includes(filter.toLowerCase()));
    }
    

    setMedicalSchoolList(medicalSchoolListData);
    // setMedicalSchoolList(medicalSchoolListData);
  }

  const getSearchSchoolName = (data) => {
    
    setSearchSchoolName(data.target.value)
  }

  const getStateProvince = (data) => {
    
    setStateProvinceFilter(data.value)
  }

  const getPublicPrivate = (data) => {
    
    setPublicPrivateFilter(data.value)
  }

  const getCampusType = (data) => {
    
    let {campusType} = campusTypeFilter;
    if(data.target.checked){

      setCampusTypeFilter({
        campusType: [...campusType, data.target.value],
      });
    }
    else{

      setCampusTypeFilter({
        campusType: campusType.filter(e => e !== data.target.value)
      });

    }

    
  }

  const getSchoolType = (data) => {
    
    
  
    let {schoolType} = schoolTypeFilter;
    if(data.target.checked){

      setSchoolTypeFilter({
        schoolType: [...schoolType, data.target.value],
      });
    }
    else{

      setSchoolTypeFilter({
        schoolType: schoolType.filter(e => e !== data.target.value)
      });

    }


  }

  const getRequireCasper = (data) => {
    
    
  
    let {requireCasper} = requireCasperFilter;
    if(data.target.checked){
      
      setRequireCasperFilter({
        requireCasper: [...requireCasper, data.target.value],
      });
    }
    else{

      setRequireCasperFilter({
        requireCasper: requireCasper.filter(e => e !== data.target.value)
      });

    }
    

  }


  const getGPA = (data) => {
    
    setGPAFilter({min:parseFloat(data[0]),max:parseFloat(data[1])})
  }

  const getDAT = (data) => {
    setDATFilter({min:parseInt(data[0]),max:parseInt(data[1])})
  }

  const getClassSize = (data) => {
    setClassSizeFilter({min:parseInt(data[0]),max:parseInt(data[1])})
  }


  useEffect(() => {
    if(props.fetchSchoolSearch.loading){
      
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });
    }
    else{
      Swal.close()
    }
    

  }, [props.fetchSchoolSearch])
  

  return (
    <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              
              <Card className="card-profile shadow mt-4 p-4">
                
                  <Row>
                    
                    <Col md="6"><FormGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Enter Institution Name" type="text" onChange={data => getSearchSchoolName(data)}/>
                        </InputGroup>
                      </FormGroup></Col>
                    <Col md="6">
                      
                    </Col>

                    <Col md="12">
                      <h2 className="mb-2 text-primary">
                        FILTERS
                      </h2>
                    </Col>
                  </Row>

                  
                  <Row>
                  
                    <Col md="6">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                        <FormGroup>
                          <label className="form-control-label text-default" htmlFor="customCheck1">
                            STATE OR PROVINCE
                          </label>
                          {/* <Input
                            id="state-or-province"
                            placeholder="name@example.com"
                            type="select"
                            onChange={(data) => getStateProvince(data)}
                            >
                              <option  value="" >ALL</option>
                            {
                              medicalSchoolStateList.map((value, key)=>{

                                return <option key={key} value={value} >{value}</option>
                              })
                            }

                          </Input> */}
                          <Select 
                            placeholder="Select State or Province" 
                            options={medicalSchoolStateList} 
                            styles={customDropdownWithBorder }  
                            onChange={getStateProvince}
                            menuPortalTarget={document.body}
                            value={medicalSchoolStateList.find((option) => option.value === stateProvinceFilter)}
                          />
                        </FormGroup>
                        </Col>

                        <Col md="12">
                        <FormGroup>
                          <label className="form-control-label text-default" htmlFor="customCheck1">
                            TYPES OF INSTITUTION
                          </label>
                          {/* <Input
                            id="in-state-annual-tuition"
                            placeholder="name@example.com"
                            type="select"
                            onChange={(data) => getInStateAnnualTuition(data)}
                          >
                            <option  value="" >ALL</option>
                            <option  value="10000" >$10,000 - $20,000</option>
                            <option  value="20001" >$20,001 - $30,000</option>
                            <option  value="30001" >$30,001 - $40,000</option>
                            <option  value="40001" >$40,001 - $50,000</option>
                            <option  value="50001" >$50,001+</option>
                          </Input> */}

                          <Select 
                            menuPortalTarget={document.body}
                            placeholder="Select Type of institution" 
                            options={[
                              { value: '', label: 'ALL' },
                              { value: 'Public', label: 'Public' },
                              { value: 'Private', label: 'Private' },
                              { value: 'Private State-related', label: 'Private State-related' }
                            ]} 
                            styles={customDropdownWithBorder }  
                            onChange={getPublicPrivate}
                            value={[
                              { value: '', label: 'ALL' },
                              { value: 'Public', label: 'Public' },
                              { value: 'Private', label: 'Private' },
                              { value: 'Private State-related', label: 'Private State-related' }
                            ].find((option) => option.value === publicPrivateFilter)}
                          />

                        </FormGroup>
                        </Col>

                      </Row>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="gpa"
                          >
                            GPA <span className='text-warning'>&nbsp;{parseFloat(gPAFilter.min)} - {parseFloat(gPAFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 10 }} start={[0, 10]} connect  onSlide={(data) => getGPA(data)}/>
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="dat"
                          >
                            DAT <span className='text-warning'>&nbsp;{parseInt(dATFilter.min)} - {parseInt(dATFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 30 }} start={[0, 30]} connect onSlide={(data) => getDAT(data)} />
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="class-size"
                          >
                            CLASS SIZE <span className='text-warning'>&nbsp;{parseInt(classSizeFilter.min)} - {parseInt(classSizeFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 500 }} start={[0, 500]} connect onSlide={(data) => getClassSize(data)} />
                        </Col>
                      </Row>
                      </div>
                    </Col>

                    {/* <Col md="6">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="4">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            SCHOOL TYPE
                          </label>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="md-school-type"
                              type="checkbox"
                              value="MD"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="md-school-type">
                              MD
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="do-school-type"
                              type="checkbox"
                              value="DO"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="do-school-type">
                              DO
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="canadian-school-type"
                              type="checkbox"
                              value="CANADIAN"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="canadian-school-type">
                              CANADIAN
                            </label>
                          </div>

                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="international-school-type"
                              type="checkbox"
                              value="INTERNATIONAL"
                              onChange={(data) => getSchoolType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="international-school-type">
                              INTERNATIONAL
                            </label>
                          </div>
                        </Col>

                        <Col md="4">
                          <label
                            className="form-control-label text-default"
                            htmlFor="median-gpa"
                          >
                            CAMPUS TYPE
                          </label>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="urban-campus-type"
                              type="checkbox"
                              value="URBAN"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="urban-campus-type">
                              URBAN
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="suburban-campus-type"
                              type="checkbox"
                              value="SUBURBAN"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="suburban-campus-type">
                              SUBURBAN
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id="rural-campus-type"
                              type="checkbox"
                              value="RURAL"
                              onChange={(data) => getCampusType(data)}
                            />
                            <label className="custom-control-label form-control-label" htmlFor="rural-campus-type">
                              RURAL
                            </label>
                          </div>
                        </Col>

                        <Col lg="4">
                          <label
                              className="form-control-label text-default"
                              htmlFor="median-gpa"
                            >
                              REQUIRE
                            </label>
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="casper-require"
                                type="checkbox"
                                value="Y"
                                onChange={(data) => getRequireCasper(data)}
                              />
                              <label className="custom-control-label form-control-label" htmlFor="casper-require">
                                CASPER
                              </label>
                            </div>
                        </Col>

                      </Row>
                      </div>
                    </Col> */}

                    {/* <Col md="4">
                      <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="gpa"
                          >
                            GPA <span className='text-warning'>&nbsp;{parseFloat(gPAFilter.min)} - {parseFloat(gPAFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 10 }} start={[0, 10]} connect  onSlide={(data) => getMedianGPA(data)}/>
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="dat"
                          >
                            DAT <span className='text-warning'>&nbsp;{parseInt(dATFilter.min)} - {parseInt(dATFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 30 }} start={[0, 30]} connect onSlide={(data) => getMedianMCAT(data)} />
                        </Col>
                        <Col md="12">
                          <label
                            className="form-control-label text-default"
                            htmlFor="class-size"
                          >
                            CLASS SIZE <span className='text-warning'>&nbsp;{parseInt(classSizeFilter.min)} - {parseInt(classSizeFilter.max)}</span>
                          </label>
                          <Nouislider range={{ min: 0, max: 500 }} start={[0, 500]} connect onSlide={(data) => getClassSize(data)} />
                        </Col>
                      </Row>
                      </div>
                    </Col> */}
                  </Row>
                
              </Card>
            </div>
          </Container>
          <Container fluid className='mt-3 text-center'>
            <h3 className='text-secondary'>Dental Schools - {medicalSchoolList.length} results</h3>
            <h3 className='text-secondary'>Click on a school name to view more details.</h3>
          </Container>
          
        </div>
        
        <Container className="mt--7" fluid>
        
            {
              medicalSchoolList.sort((a, b) => {
                const nameA = a.overview.schoolName.toLowerCase();
                const nameB = b.overview.schoolName.toLowerCase();
              
                if (nameA < nameB) {
                  return -1; // a should be placed before b
                }
                if (nameA > nameB) {
                  return 1; // a should be placed after b
                }
                return 0; // names are equal, no change in order
              })
              .map((value, key)=>{
                return (<LazyLoad key={key} height={100} >
                  <SchoolCard key={key+"1"} medicalSchoolDetails={value} placeholder={<h4>Loading...</h4>}></SchoolCard>
                </LazyLoad>)
                // return 
              })
            }
           
        </Container>
        <Plans></Plans>
    </>
  )
}

const mapStateToProps = state  => {
  return {
      fetchSchoolSearch : state.fetchSchoolSearch,
      userDetails : state.loginAuth.userDetails,
      
  }
    
}

export default connect(mapStateToProps)(SchoolSearch);
