import { SAVE_PERSONAL_STATEMENT_RESET, SAVE_PERSONAL_STATEMENT_REQUEST, SAVE_PERSONAL_STATEMENT_SUCCESS, SAVE_PERSONAL_STATEMENT_FAILURE, FETCH_PERSONAL_STATEMENT_FAILURE, FETCH_PERSONAL_STATEMENT_REQUEST, FETCH_PERSONAL_STATEMENT_SUCCESS } from './PersonalStatementTypes'
import axios from 'axios'

const personalStatementRequest = () => {
    return {
        type: SAVE_PERSONAL_STATEMENT_REQUEST,
    }
}

export const personalStatement = (userData) => (dispatch) => {

    dispatch(personalStatementRequest());
    axios.post('/postStatement', userData)
        .then(res => {
            dispatch({
                type: SAVE_PERSONAL_STATEMENT_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SAVE_PERSONAL_STATEMENT_FAILURE,
                payload: err.response.data
            })
        })
}


export const personalStatementReset = () => {
    return {
        type: SAVE_PERSONAL_STATEMENT_RESET,
    }
}


const fetchPersonalStatementRequest = () => {
    return {
        type: FETCH_PERSONAL_STATEMENT_REQUEST,
    }
}

const fetchPersonalStatementSuccess = success => {
    return {
        type: FETCH_PERSONAL_STATEMENT_SUCCESS,
        payload: success
    }
}

const fetchPersonalStatementFailure = error => {
    return {
        type: FETCH_PERSONAL_STATEMENT_FAILURE,
        payload : error
    }
}

export const fetchPersonalStatement = (data) => {
    
    return (dispatch) => {
        
        dispatch(fetchPersonalStatementRequest());
        
        
        axios.post('/getPersonalStatements' , data)
        .then(response => {
            const personalStatement = response.data
            dispatch(fetchPersonalStatementSuccess(personalStatement))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchPersonalStatementFailure(errorMsg))
        })
        
    }

}
