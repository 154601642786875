export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const CREATE_CUSTOMER_RESET = 'CREATE_CUSTOMER_RESET';

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const CREATE_SUBSCRIPTION_RESET = 'CREATE_SUBSCRIPTION_RESET';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_RESET = 'CANCEL_SUBSCRIPTION_RESET';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const UPDATE_SUBSCRIPTION_RESET = 'UPDATE_SUBSCRIPTION_RESET';

export const RESUME_SUBSCRIPTION_REQUEST = 'RESUME_SUBSCRIPTION_REQUEST';
export const RESUME_SUBSCRIPTION_SUCCESS = 'RESUME_SUBSCRIPTION_SUCCESS';
export const RESUME_SUBSCRIPTION_FAILURE = 'RESUME_SUBSCRIPTION_FAILURE';
export const RESUME_SUBSCRIPTION_RESET = 'RESUME_SUBSCRIPTION_RESET';

export const FETCH_DETAILS_SUBSCRIPTION_REQUEST = 'FETCH_DETAILS_SUBSCRIPTION_REQUEST';
export const FETCH_DETAILS_SUBSCRIPTION_SUCCESS = 'FETCH_DETAILS_SUBSCRIPTION_SUCCESS';
export const FETCH_DETAILS_SUBSCRIPTION_FAILURE = 'FETCH_DETAILS_SUBSCRIPTION_FAILURE';
export const FETCH_DETAILS_SUBSCRIPTION_RESET = 'FETCH_DETAILS_SUBSCRIPTION_RESET';

export const OPEN_MANAGE_SUBSCRIPTION_MODAL = 'OPEN_MANAGE_SUBSCRIPTION_MODAL';
export const CLOSE_MANAGE_SUBSCRIPTION_MODAL = 'CLOSE_MANAGE_SUBSCRIPTION_MODAL';

export const OPEN_BUY_TUTORING_HOURS_MODAL = 'OPEN_BUY_TUTORING_HOURS_MODAL';
export const CLOSE_BUY_TUTORING_HOURS_MODAL = 'CLOSE_BUY_TUTORING_HOURS_MODAL';

export const OPEN_BUY_TPC_COURSE_MODAL = 'OPEN_BUY_TPC_COURSE_MODAL';
export const CLOSE_BUY_TPC_COURSE_MODAL = 'CLOSE_BUY_TPC_COURSE_MODAL';

export const CREATE_TUTORING_HOURS_PAYMENT_REQUEST = 'CREATE_TUTORING_HOURS_PAYMENT_REQUEST';
export const CREATE_TUTORING_HOURS_PAYMENT_SUCCESS = 'CREATE_TUTORING_HOURS_PAYMENT_SUCCESS';
export const CREATE_TUTORING_HOURS_PAYMENT_FAILURE = 'CREATE_TUTORING_HOURS_PAYMENT_FAILURE';
export const CREATE_TUTORING_HOURS_PAYMENT_RESET = 'CREATE_TUTORING_HOURS_PAYMENT_RESET';

export const UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST = 'UPDATE_TUTORING_HOURS_AVAILABLE_REQUEST';
export const UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS = 'UPDATE_TUTORING_HOURS_AVAILABLE_SUCCESS';
export const UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE = 'UPDATE_TUTORING_HOURS_AVAILABLE_FAILURE';
export const UPDATE_TUTORING_HOURS_AVAILABLE_RESET = 'UPDATE_TUTORING_HOURS_AVAILABLE_RESET';


export const CREATE_TPC_COURSE_PAYMENT_REQUEST = 'CREATE_TPC_COURSE_PAYMENT_REQUEST';
export const CREATE_TPC_COURSE_PAYMENT_SUCCESS = 'CREATE_TPC_COURSE_PAYMENT_SUCCESS';
export const CREATE_TPC_COURSE_PAYMENT_FAILURE = 'CREATE_TPC_COURSE_PAYMENT_FAILURE';
export const CREATE_TPC_COURSE_PAYMENT_RESET = 'CREATE_TPC_COURSE_PAYMENT_RESET';


export const FETCH_PAYMENT_RECORD_REQUEST = 'FETCH_PAYMENT_RECORD_REQUEST';
export const FETCH_PAYMENT_RECORD_SUCCESS = 'FETCH_PAYMENT_RECORD_SUCCESS';
export const FETCH_PAYMENT_RECORD_FAILURE = 'FETCH_PAYMENT_RECORD_FAILURE';
export const FETCH_PAYMENT_RECORD_RESET = 'FETCH_PAYMENT_RECORD_RESET';