import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // for month view
import googleCalendarPlugin from '@fullcalendar/google-calendar'; // to fetch from Google Calendar
import {
    Container,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";

const GoogleCalendarWidget = () => {
    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const toggle = () => setModal(!modal);

    const handleEventClick = (info) => {
        info.jsEvent.preventDefault();
        console.log(info)
        // Set the clicked event's details in the state
        setSelectedEvent(info.event);
        toggle(); // Open modal
    };

    const StringToHTML = ({ htmlString }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
    };

    return (
        <>
            <Card className='w-100 p-4 shadow mb-5'>
                <div className='w-100'>
                    
                    <FullCalendar
                        plugins={[dayGridPlugin, googleCalendarPlugin]}
                        initialView="dayGridMonth"
                        footerToolbar={true}
                        eventDisplay="block"
                        googleCalendarApiKey="AIzaSyDQ_BmfIYtyM1jZjpht6TQvmpyIm5P64jQ"
                        events={{
                            googleCalendarId: 'thepredentalconsultants@gmail.com',
                        }}
                        eventDidMount={(info) => {
                            // Dynamically style the event based on its title
                            if (info.event.title.includes('DAT Class')) {
                                info.el.style.backgroundColor = '#2dce89'; // Red
                                info.el.style.borderColor = '#2dce89';
                            } else if (info.event.title.includes('Office Hours')) {
                                info.el.style.backgroundColor = '#11cdef'; // Green
                                info.el.style.borderColor = '#11cdef';
                            } else if (info.event.title.includes('Urgent')) {
                                info.el.style.backgroundColor = '#f1c40f'; // Yellow
                                info.el.style.borderColor = '#f1c40f';
                            }
                        }}
                        eventClick={handleEventClick}
                    />
                </div>
                {/* support@thepremedconsultants.com
                3884274c6be5aa5aef4fe68a8c40795dc3454f1064d1e6be4b75fc3aafa6c961@group.calendar.google.com */}
            </Card>

            {/* Modal to display event details */}
            <Modal isOpen={modal} toggle={toggle} className=' modal-lg'>
                <ModalHeader toggle={toggle}>Event Details</ModalHeader>
                <ModalBody>
                    {selectedEvent && (
                        <div >
                            <p className='text-center text-warning'><strong className='font-weight-bold text-default'>Title :</strong> {selectedEvent.title}</p>
                            <p className='text-center'><strong className='font-weight-bold text-default'>Start :</strong> {selectedEvent.start.toLocaleString()}</p>
                            <p className='text-center'><strong className='font-weight-bold text-default'>End :</strong> {selectedEvent.end ? selectedEvent.end.toLocaleString() : 'N/A'}</p>
                            
                            {selectedEvent.extendedProps.location && (
                                <p className='text-center'><a href={selectedEvent.extendedProps.location} className='btn btn-primary' target="_blank">Join Meeting</a></p>
                            )}
                            

                            
                            <hr></hr>
                            <p><strong className='font-weight-bold text-default'>Description :</strong></p>
                            {/* Display Google Meet link if available */}
                            <pre>
                            <StringToHTML htmlString={selectedEvent.extendedProps.description || 'No description available'} />
                            </pre>
                            
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    {selectedEvent && (
                        <a className='btn btn-success' target='__blank' href={selectedEvent.url} onClick={toggle}>See Full Details</a>
                    )}
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default GoogleCalendarWidget;
