import { 
    FETCH_ALL_USERS_FAILURE,
    FETCH_ALL_USERS_REQUEST,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_USER_DETAILS_FAILURE,
    FETCH_USER_DETAILS_REQUEST,
    FETCH_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAILURE,
    UPDATE_USER_DETAILS_REQUEST,
    UPDATE_USER_DETAILS_RESET,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_MULTI_USERS_FAILURE,
    UPDATE_MULTI_USERS_REQUEST,
    UPDATE_MULTI_USERS_RESET,
    UPDATE_MULTI_USERS_SUCCESS,
} from "./ManageUsersTypes";

const initialFetchAllUsersState = {
    allusers : [],
    loading: false,
}

export const fetchAllUsersReducer = (state = initialFetchAllUsersState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_USERS_SUCCESS:
            return {
                loading: false,
                allusers: action.payload
            }
        case FETCH_ALL_USERS_FAILURE:
            return {
                loading: false,
                allusers: [],
                error: action.payload
            }
        default: return state;
    }
}


const initialFetchUserDetailsState = {
    userdetails : [],
    loading: false,
}

export const fetchUserDetailsReducer = (state = initialFetchUserDetailsState, action) => {
    switch (action.type) {
        case FETCH_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_USER_DETAILS_SUCCESS:
            return {
                loading: false,
                userdetails: action.payload
            }
        case FETCH_USER_DETAILS_FAILURE:
            return {
                loading: false,
                userdetails: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialUserDetailsState = {
    loading: false,
    userdetails: [],
}

export const updateUserDetailsReducer = (state = initialUserDetailsState, action) => {
    switch (action.type) {
        case UPDATE_USER_DETAILS_RESET:
            return {
                loading: false,
                userdetails: [],
            }
        case UPDATE_USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_DETAILS_SUCCESS:
            return {
                loading: false,
                userdetails: [action.payload]
            }
        case UPDATE_USER_DETAILS_FAILURE:
            return {
                loading: false,
                userdetails: [],
                error: action.payload
            }
        default: return state;
    }
}

const initialMultiUsersState = {
  loading: false,
  multiusers: [],
};

export const updateMultiUsersReducer = (
  state = initialMultiUsersState,
  action
) => {
  switch (action.type) {
    case UPDATE_MULTI_USERS_RESET:
      return {
        loading: false,
        multiusers: [],
      };
    case UPDATE_MULTI_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MULTI_USERS_SUCCESS:
      return {
        loading: false,
        multiusers: [action.payload],
      };
    case UPDATE_MULTI_USERS_FAILURE:
      return {
        loading: false,
        multiusers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};