import {
  CREATE_COMMUNITY_POST_REQUEST,
  CREATE_COMMUNITY_POST_SUCCESS,
  CREATE_COMMUNITY_POST_FAILURE,
  CREATE_COMMUNITY_POST_RESET,
  UPDATE_COMMUNITY_POST_REQUEST,
  UPDATE_COMMUNITY_POST_SUCCESS,
  UPDATE_COMMUNITY_POST_FAILURE,
  UPDATE_COMMUNITY_POST_RESET,
  DELETE_COMMUNITY_POST_REQUEST,
  DELETE_COMMUNITY_POST_SUCCESS,
  DELETE_COMMUNITY_POST_FAILURE,
  DELETE_COMMUNITY_POST_RESET,
  FETCH_ALL_COMMUNITY_POSTS_RESET,
  FETCH_ALL_COMMUNITY_POSTS_REQUEST,
  FETCH_ALL_COMMUNITY_POSTS_FAILURE,
  FETCH_ALL_COMMUNITY_POSTS_SUCCESS,
  CREATE_COMMUNITY_COMMENT_REQUEST,
  CREATE_COMMUNITY_COMMENT_SUCCESS,
  CREATE_COMMUNITY_COMMENT_FAILURE,
  CREATE_COMMUNITY_COMMENT_RESET,
  UPDATE_COMMUNITY_COMMENT_REQUEST,
  UPDATE_COMMUNITY_COMMENT_SUCCESS,
  UPDATE_COMMUNITY_COMMENT_FAILURE,
  UPDATE_COMMUNITY_COMMENT_RESET,
  DELETE_COMMUNITY_COMMENT_REQUEST,
  DELETE_COMMUNITY_COMMENT_SUCCESS,
  DELETE_COMMUNITY_COMMENT_FAILURE,
  DELETE_COMMUNITY_COMMENT_RESET,
  CREATE_COMMUNITY_REPLY_REQUEST,
  CREATE_COMMUNITY_REPLY_SUCCESS,
  CREATE_COMMUNITY_REPLY_FAILURE,
  CREATE_COMMUNITY_REPLY_RESET,
  UPDATE_COMMUNITY_REPLY_REQUEST,
  UPDATE_COMMUNITY_REPLY_SUCCESS,
  UPDATE_COMMUNITY_REPLY_FAILURE,
  UPDATE_COMMUNITY_REPLY_RESET,
  DELETE_COMMUNITY_REPLY_REQUEST,
  DELETE_COMMUNITY_REPLY_SUCCESS,
  DELETE_COMMUNITY_REPLY_FAILURE,
  DELETE_COMMUNITY_REPLY_RESET,
  CREATE_COMMUNITY_LIKEPOST_REQUEST,
  CREATE_COMMUNITY_LIKEPOST_SUCCESS,
  CREATE_COMMUNITY_LIKEPOST_FAILURE,
  CREATE_COMMUNITY_LIKEPOST_RESET,
  CREATE_COMMUNITY_SAVEPOST_REQUEST,
  CREATE_COMMUNITY_SAVEPOST_SUCCESS,
  CREATE_COMMUNITY_SAVEPOST_FAILURE,
  CREATE_COMMUNITY_SAVEPOST_RESET,
  CREATE_COMMUNITY_LIKECOMMENT_REQUEST,
  CREATE_COMMUNITY_LIKECOMMENT_SUCCESS,
  CREATE_COMMUNITY_LIKECOMMENT_FAILURE,
  CREATE_COMMUNITY_LIKECOMMENT_RESET,
  FETCH_COMMUNITY_LIKEDPOST_RESET,
  FETCH_COMMUNITY_LIKEDPOST_REQUEST,
  FETCH_COMMUNITY_LIKEDPOST_FAILURE,
  FETCH_COMMUNITY_LIKEDPOST_SUCCESS,
  FETCH_LEADERBOARD_RESET,
  FETCH_LEADERBOARD_REQUEST,
  FETCH_LEADERBOARD_FAILURE,
  FETCH_LEADERBOARD_SUCCESS,
} from "./CommunityType";

import axios from "axios";

export const createCommunityPostReset = () => {
  return {
    type: CREATE_COMMUNITY_POST_RESET,
  };
};

const createCommunityPostRequest = () => {
  return {
    type: CREATE_COMMUNITY_POST_REQUEST,
  };
};

const createCommunityPostSuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_POST_SUCCESS,
    payload: success,
  };
};

const createCommunityPostFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_POST_FAILURE,
    payload: error,
  };
};

export const createCommunityPost = (data) => {
  return (dispatch) => {
    dispatch(createCommunityPostRequest());
    axios
      .post("/createCommunityPost", data)
      .then((response) => {
        const createCommunityPost = response.data;
        dispatch(createCommunityPostSuccess(createCommunityPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityPostFailure(errorMsg));
      });
  };
};

export const updateCommunityPostReset = () => {
  return {
    type: UPDATE_COMMUNITY_POST_RESET,
  };
};

const updateCommunityPostRequest = () => {
  return {
    type: UPDATE_COMMUNITY_POST_REQUEST,
  };
};

const updateCommunityPostSuccess = (success) => {
  return {
    type: UPDATE_COMMUNITY_POST_SUCCESS,
    payload: success,
  };
};

const updateCommunityPostFailure = (error) => {
  return {
    type: UPDATE_COMMUNITY_POST_FAILURE,
    payload: error,
  };
};

export const updateCommunityPost = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityPostRequest());
    axios
      .post("/updateCommunityPost", data)
      .then((response) => {
        const updateCommunityPost = response.data;
        dispatch(updateCommunityPostSuccess(updateCommunityPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityPostFailure(errorMsg));
      });
  };
};

export const deleteCommunityPostReset = () => {
  return {
    type: DELETE_COMMUNITY_POST_RESET,
  };
};

const deleteCommunityPostRequest = () => {
  return {
    type: DELETE_COMMUNITY_POST_REQUEST,
  };
};

const deleteCommunityPostSuccess = (success) => {
  return {
    type: DELETE_COMMUNITY_POST_SUCCESS,
    payload: success,
  };
};

const deleteCommunityPostFailure = (error) => {
  return {
    type: DELETE_COMMUNITY_POST_FAILURE,
    payload: error,
  };
};

export const deleteCommunityPost = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityPostRequest());
    axios
      .post("/deleteCommunityPost", data)
      .then((response) => {
        const deleteCommunityPost = response.data;
        dispatch(deleteCommunityPostSuccess(deleteCommunityPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityPostFailure(errorMsg));
      });
  };
};

export const fetchAllCommunityPostsReset = () => {
  return {
    type: FETCH_ALL_COMMUNITY_POSTS_RESET,
  };
};

const fetchAllCommunityPostsRequest = () => {
  return {
    type: FETCH_ALL_COMMUNITY_POSTS_REQUEST,
  };
};

const fetchAllCommunityPostsSuccess = (success) => {
  return {
    type: FETCH_ALL_COMMUNITY_POSTS_SUCCESS,
    payload: success,
  };
};

const fetchAllCommunityPostsFailure = (error) => {
  return {
    type: FETCH_ALL_COMMUNITY_POSTS_FAILURE,
    payload: error,
  };
};

export const fetchAllCommunityPosts = (data) => {
  // const fetchAllCommunityPostsRoute = data.route;
  return (dispatch) => {
    dispatch(fetchAllCommunityPostsRequest());

    axios
      .post("/fetchAllCommunityPosts", data)
      .then((response) => {
        const allCommunityPosts = response.data;
        dispatch(fetchAllCommunityPostsSuccess(allCommunityPosts));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchAllCommunityPostsFailure(errorMsg));
      });
  };
};

export const createCommunityCommentReset = () => {
  return {
    type: CREATE_COMMUNITY_COMMENT_RESET,
  };
};

const createCommunityCommentRequest = () => {
  return {
    type: CREATE_COMMUNITY_COMMENT_REQUEST,
  };
};

const createCommunityCommentSuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_COMMENT_SUCCESS,
    payload: success,
  };
};

const createCommunityCommentFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_COMMENT_FAILURE,
    payload: error,
  };
};

export const createCommunityComment = (data) => {
  return (dispatch) => {
    dispatch(createCommunityCommentRequest());

    axios
      .post("/createCommunityComment", data)
      .then((response) => {
        const createCommunityComment = response.data;
        dispatch(createCommunityCommentSuccess(createCommunityComment));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityCommentFailure(errorMsg));
      });
  };
};

export const updateCommunityCommentReset = () => {
  return {
    type: UPDATE_COMMUNITY_COMMENT_RESET,
  };
};

const updateCommunityCommentRequest = () => {
  return {
    type: UPDATE_COMMUNITY_COMMENT_REQUEST,
  };
};

const updateCommunityCommentSuccess = (success) => {
  return {
    type: UPDATE_COMMUNITY_COMMENT_SUCCESS,
    payload: success,
  };
};

const updateCommunityCommentFailure = (error) => {
  return {
    type: UPDATE_COMMUNITY_COMMENT_FAILURE,
    payload: error,
  };
};

export const updateCommunityComment = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityCommentRequest());

    axios
      .post("/updateCommunityComment", data)
      .then((response) => {
        const updateCommunityComment = response.data;
        dispatch(updateCommunityCommentSuccess(updateCommunityComment));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityCommentFailure(errorMsg));
      });
  };
};

export const deleteCommunityCommentReset = () => {
  return {
    type: DELETE_COMMUNITY_COMMENT_RESET,
  };
};

const deleteCommunityCommentRequest = () => {
  return {
    type: DELETE_COMMUNITY_COMMENT_REQUEST,
  };
};

const deleteCommunityCommentSuccess = (success) => {
  return {
    type: DELETE_COMMUNITY_COMMENT_SUCCESS,
    payload: success,
  };
};

const deleteCommunityCommentFailure = (error) => {
  return {
    type: DELETE_COMMUNITY_COMMENT_FAILURE,
    payload: error,
  };
};

export const deleteCommunityComment = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityCommentRequest());
    axios
      .post("/deleteCommunityComment", data)
      .then((response) => {
        const deleteCommunityComment = response.data;
        dispatch(deleteCommunityCommentSuccess(deleteCommunityComment));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityCommentFailure(errorMsg));
      });
  };
};

export const createCommunityReplyReset = () => {
  return {
    type: CREATE_COMMUNITY_REPLY_RESET,
  };
};

const createCommunityReplyRequest = () => {
  return {
    type: CREATE_COMMUNITY_REPLY_REQUEST,
  };
};

const createCommunityReplySuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_REPLY_SUCCESS,
    payload: success,
  };
};

const createCommunityReplyFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_REPLY_FAILURE,
    payload: error,
  };
};

export const createCommunityReply = (data) => {
  return (dispatch) => {
    dispatch(createCommunityReplyRequest());
    axios
      .post("/createCommunityReply", data)
      .then((response) => {
        const createCommunityReply = response.data;
        dispatch(createCommunityReplySuccess(createCommunityReply));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityReplyFailure(errorMsg));
      });
  };
};

export const updateCommunityReplyReset = () => {
  return {
    type: UPDATE_COMMUNITY_REPLY_RESET,
  };
};

const updateCommunityReplyRequest = () => {
  return {
    type: UPDATE_COMMUNITY_REPLY_REQUEST,
  };
};

const updateCommunityReplySuccess = (success) => {
  return {
    type: UPDATE_COMMUNITY_REPLY_SUCCESS,
    payload: success,
  };
};

const updateCommunityReplyFailure = (error) => {
  return {
    type: UPDATE_COMMUNITY_REPLY_FAILURE,
    payload: error,
  };
};

export const updateCommunityReply = (data) => {
  return (dispatch) => {
    dispatch(updateCommunityReplyRequest());

    axios
      .post("/updateCommunityReply", data)
      .then((response) => {
        const updateCommunityReply = response.data;
        dispatch(updateCommunityReplySuccess(updateCommunityReply));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(updateCommunityReplyFailure(errorMsg));
      });
  };
};

export const deleteCommunityReplyReset = () => {
  return {
    type: DELETE_COMMUNITY_REPLY_RESET,
  };
};

const deleteCommunityReplyRequest = () => {
  return {
    type: DELETE_COMMUNITY_REPLY_REQUEST,
  };
};

const deleteCommunityReplySuccess = (success) => {
  return {
    type: DELETE_COMMUNITY_REPLY_SUCCESS,
    payload: success,
  };
};

const deleteCommunityReplyFailure = (error) => {
  return {
    type: DELETE_COMMUNITY_REPLY_FAILURE,
    payload: error,
  };
};

export const deleteCommunityReply = (data) => {
  return (dispatch) => {
    dispatch(deleteCommunityReplyRequest());
    axios
      .post("/deleteCommunityReply", data)
      .then((response) => {
        const deleteCommunityReply = response.data;
        dispatch(deleteCommunityReplySuccess(deleteCommunityReply));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(deleteCommunityReplyFailure(errorMsg));
      });
  };
};

export const createCommunityLikePostReset = () => {
  return {
    type: CREATE_COMMUNITY_LIKEPOST_RESET,
  };
};

const createCommunityLikePostRequest = () => {
  return {
    type: CREATE_COMMUNITY_LIKEPOST_REQUEST,
  };
};

const createCommunityLikePostSuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_LIKEPOST_SUCCESS,
    payload: success,
  };
};

const createCommunityLikePostFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_LIKEPOST_FAILURE,
    payload: error,
  };
};

export const createCommunityLikePost = (data) => {
  return (dispatch) => {
    dispatch(createCommunityLikePostRequest());
    axios
      .post("/createCommunityLikePost", data)
      .then((response) => {
        const createCommunityLikePost = response.data;
        dispatch(createCommunityLikePostSuccess(createCommunityLikePost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityLikePostFailure(errorMsg));
      });
  };
};

export const createCommunitySavePostReset = () => {
  return {
    type: CREATE_COMMUNITY_SAVEPOST_RESET,
  };
};

const createCommunitySavePostRequest = () => {
  return {
    type: CREATE_COMMUNITY_SAVEPOST_REQUEST,
  };
};

const createCommunitySavePostSuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_SAVEPOST_SUCCESS,
    payload: success,
  };
};

const createCommunitySavePostFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_SAVEPOST_FAILURE,
    payload: error,
  };
};

export const createCommunitySavePost = (data) => {
  return (dispatch) => {
    dispatch(createCommunitySavePostRequest());
    axios
      .post("/createCommunitySavePost", data)
      .then((response) => {
        const createCommunitySavePost = response.data;
        dispatch(createCommunitySavePostSuccess(createCommunitySavePost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunitySavePostFailure(errorMsg));
      });
  };
};

export const createCommunityLikeCommentReset = () => {
  return {
    type: CREATE_COMMUNITY_LIKECOMMENT_RESET,
  };
};

const createCommunityLikeCommentRequest = () => {
  return {
    type: CREATE_COMMUNITY_LIKECOMMENT_REQUEST,
  };
};

const createCommunityLikeCommentSuccess = (success) => {
  return {
    type: CREATE_COMMUNITY_LIKECOMMENT_SUCCESS,
    payload: success,
  };
};

const createCommunityLikeCommentFailure = (error) => {
  return {
    type: CREATE_COMMUNITY_LIKECOMMENT_FAILURE,
    payload: error,
  };
};

export const createCommunityLikeComment = (data) => {
  return (dispatch) => {
    dispatch(createCommunityLikeCommentRequest());
    axios
      .post("/createCommunityLikeComment", data)
      .then((response) => {
        const createCommunityLikeComment = response.data;
        dispatch(createCommunityLikeCommentSuccess(createCommunityLikeComment));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(createCommunityLikeCommentFailure(errorMsg));
      });
  };
};

//LikedPost
export const fetchCommunityLikedPostReset = () => {
  return {
    type: FETCH_COMMUNITY_LIKEDPOST_RESET,
  };
};

const fetchCommunityLikedPostRequest = () => {
  return {
    type: FETCH_COMMUNITY_LIKEDPOST_REQUEST,
  };
};

const fetchCommunityLikedPostSuccess = (success) => {
  return {
    type: FETCH_COMMUNITY_LIKEDPOST_SUCCESS,
    payload: success,
  };
};

const fetchCommunityLikedPostFailure = (error) => {
  return {
    type: FETCH_COMMUNITY_LIKEDPOST_FAILURE,
    payload: error,
  };
};

export const fetchCommunityLikedPost = (data) => {
  return (dispatch) => {
    dispatch(fetchCommunityLikedPostRequest());

    axios
      .post("/fetchCommunityLikedPost", data)
      .then((response) => {
        const likedPost = response.data;
        dispatch(fetchCommunityLikedPostSuccess(likedPost));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchCommunityLikedPostFailure(errorMsg));
      });
  };
};

//
export const fetchLeaderboardReset = () => {
  return {
    type: FETCH_LEADERBOARD_RESET,
  };
};

const fetchLeaderboardRequest = () => {
  return {
    type: FETCH_LEADERBOARD_REQUEST,
  };
};

const fetchLeaderboardSuccess = (success) => {
  return {
    type: FETCH_LEADERBOARD_SUCCESS,
    payload: success,
  };
};

const fetchLeaderboardFailure = (error) => {
  return {
    type: FETCH_LEADERBOARD_FAILURE,
    payload: error,
  };
};

export const fetchLeaderboard = (data) => {
  return (dispatch) => {
    dispatch(fetchLeaderboardRequest());

    axios
      .post("/fetchLeaderboard", data)
      .then((response) => {
        const leaderboard = response.data;
        dispatch(fetchLeaderboardSuccess(leaderboard));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchLeaderboardFailure(errorMsg));
      });
  };
};
