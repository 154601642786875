import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Table,
  Badge,
TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
   UncontrolledTooltip,
} from "reactstrap";
import { app, db } from "../../firebase";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
import ReactDatetime from "react-datetime";
import {
  fetchStudentRequestTutor,
  fetchStudentRequestTutorHistory,
  approveStudentRequestTutor,
  disapproveStudentRequestTutor,
  updateModalUploadTutorVideoFile,
  editTutorRequest,
} from "redux/ManageTutorRequests/ManageTutorRequestsActions";
import {
  createRequestTutor,
  createRequestTutorReset,
} from "redux/ScheduleTutor/ScheduleTutorActions";
import UploadTutorVideoFileModal from "components/Modal/UploadTutorVideoFileModal";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { customDropdownWithBorder } from "util/reactCustomStyles";
import {
  collection,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
  doc,
} from "firebase/firestore";
const ManageStudentRequest = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);


  let twoDaysBefore = moment().add(1, "day");
  let valid = function (current) {
    return current.isAfter(twoDaysBefore);
  };


  const [checkUsers, setCheckUsers] = useState([]);
  const [selectedUsers, setselectedUsers] = useState();
  const [selectedType, selectType] = useState("1HPT");
  const handleSelectType = (e,type) => {
      selectType(type)
      if(type == '1HPT') {// Add 1hr for end time
       setEndTime(moment(timeOfRequest,'h:mm a').add(1,'hours').format('h:mm A'))
      }else{// Add 30min for end time
      setEndTime(moment(timeOfRequest,'h:mm a').add(30,'minutes').format('h:mm A'))
      } 
  }

 const [dateOfRequest, setDateOfRequest] = useState();
 const [timeOfRequest, setTimeOfRequest] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
  dispatch(fetchStudentRequestTutor({ handle: props.userDetails.handle }));
  }, []);

    useEffect(() => {
    if (props.fetchStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }

    if (props.fetchStudentRequestTutor.fetchstudentrequesttutor.length) {
      setRequestTutor(
        props.fetchStudentRequestTutor.fetchstudentrequesttutor[0]
      );
        setData( props.fetchStudentRequestTutor.fetchstudentrequesttutor[0]);
      setFilter( props.fetchStudentRequestTutor.fetchstudentrequesttutor[0]);
      
    }
  }, [props.fetchStudentRequestTutor]);


  const modalUploadTutorVideoFile =
    props.updateModalUploadTutorVideoFileStatus.status;
  const toggleUploadTutorVideoFile = () => {
    dispatch(
      updateModalUploadTutorVideoFile(
        !props.updateModalUploadTutorVideoFileStatus.status
      )
    );
  };
  const [requestTutor, setRequestTutor] = useState([]);
 
  useEffect(() => {
    if (props.fetchStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }

    if (props.fetchStudentRequestTutor.fetchstudentrequesttutor.length) {
      setRequestTutor(
        props.fetchStudentRequestTutor.fetchstudentrequesttutor[0]
      );
    }
  }, [props.fetchStudentRequestTutor]);

  const handleApproveRequestOnClick = (
    e,
   item
  ) => {
    e.preventDefault();

    dispatch(
      approveStudentRequestTutor(
        {
          item: item,
          studentScheduleRequestId: item.docId,
          cemail: props.userDetails.email,
          cname: props.userDetails.name,
          chandle: props.userDetails.handle,
          semail: item.user.email,
          sname: item.user.name,
          shandle: item.user.handle,
          date :   moment(item.starttime).format('ll') != moment(item.endtime).format('ll') ? moment(item.starttime).format('ll')+" - "+moment(item.endtime).format('ll'):  moment(item.starttime).format('ll'),
          time: moment(item.starttime).format('h:mm A')+ "-"+ moment(item.endtime).format('h:mm A') 
        }
      )
    );
  };

  const handleDisapproveRequestOnClick = (
    e,
    item
  ) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disapprove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          disapproveStudentRequestTutor({
           
            cname : props.userDetails.name,
            cemail: props.userDetails.email,
            chandle: props.userDetails.handle,
            semail: item.user.email,
            sname: item.user.name,
            date :   moment(item.starttime).format('ll') != moment(item.endtime).format('ll') ? moment(item.starttime).format('ll')+" - "+moment(item.endtime).format('ll'):  moment(item.starttime).format('ll'),
            time: moment(item.starttime).format('h:mm A')+ "-"+ moment(item.endtime).format('h:mm A') ,
            item: item
           
          })
        );
      }
    });
  };

  useEffect(() => {
    if (props.approveStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (props.approveStudentRequestTutor.approvestudentrequesttutor.length) {
        Swal.fire({
          title: "Success!",
          text: "Successfully approved request!",
          icon: "success",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      } else if (
        typeof props.approveStudentRequestTutor.error !== "undefined"
      ) {
        Swal.fire({
          title: "Failed!",
          text: "Failed to approve request",
          icon: "error",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      }
    }
  }, [props.approveStudentRequestTutor]);

  useEffect(() => {
    if (props.disapproveStudentRequestTutor.loading) {
      Swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      if (
        props.disapproveStudentRequestTutor.disapprovestudentrequesttutor.length
      ) {
        Swal.fire({
          title: "Success!",
          text: "Successfully disapproved request!",
          icon: "success",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      } else if (
        typeof props.disapproveStudentRequestTutor.error !== "undefined"
      ) {
        Swal.fire({
          title: "Failed!",
          text: "Failed to disapprove request",
          icon: "error",
          timer: 3000,
        }).then(function (result) {
          dispatch(
            fetchStudentRequestTutor({ handle: props.userDetails.handle })
          );
          dispatch(
            fetchStudentRequestTutorHistory({
              handle: props.userDetails.handle,
            })
          );
        });
      }
    }
  }, [props.disapproveStudentRequestTutor]);

  const onClickEditTutorRequest = (e, meetingId) => {
    console.log(meetingId);
    e.preventDefault();
    dispatch(
      editTutorRequest({
        status: true,
        meetingId: meetingId,
      })
    );

    dispatch(
      updateModalUploadTutorVideoFile(
        !props.updateModalUploadTutorVideoFileStatus.status
      )
    );
  };

  const handleDateOnChange = (e) => {
    const paramDate = e.toJSON();
    setDateOfRequest(new Date(paramDate));
  };

  const handleTimeOnChange = (e) => {
    const paramTime = e.toJSON();
    setTimeOfRequest(new Date(paramTime));
  };

  
  const convertTimeToUTC = (time) => {
    return moment(moment(time).utcOffset(0, false), "HH:mm")
  }

  const convertTimeToLocal = (time) => {
    return moment(moment(time).utcOffset(0, true), "HH:mm")
  }

  const columns = [
    {
      name: "Student",
      selector: (row) => row.user.name,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return moment(row.starttime).format('ll') != moment(row.endtime).format('ll') ? moment(row.starttime).format('ll')+" - "+moment(row.endtime).format('ll'):  moment(row.starttime).format('ll');
      },
    },
    {
      name: "Time",
      selector: (row) => {
         return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
      },
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => {
        return  row.requestType.toUpperCase() 
      },
      wrap: true,
    },
     {
      name: "Duration",
      selector: (row) => {
        return  row.duration 
      },
      wrap: true,
    },
     
    {
      name: "Status",
      selector: (row) => (
        <Badge color="" className="badge-dot mr-4">
          <i
            className={
              row.status == "pending"
                ? "bg-info"
                : row.status == "approved"
                ? "bg-success"
                : row.status == "disapproved"
                ? "bg-danger"
                : "bg-warning"
            }
          />
          {row.status}
        </Badge>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
            row.status == "pending" ?  
                <>
                    <button
                        onClick={(e) => handleApproveRequestOnClick(e, row)}
                        className="btn btn-sm btn-success"
                    >
                        Approve
                    </button>
                    <button
                        onClick={(e) => handleDisapproveRequestOnClick(e, row)}
                        className="btn btn-sm btn-danger"
                    >
                        Disapprove
                    </button>
                </>
              : row.status == "approved" ?  
               <>
                <a
                href={row.hostMeetingLink}
                target="__blank"
                className="btn btn-sm btn-primary"
                >
                Join Zoom Meeting
                </a>

                  <button
                        // onClick={(e) => approveRequest(e, row)}
                        className="btn btn-sm btn-warning"
                    >
                        Re-schedule
                    </button>
               </>
              :row.status == "disapproved" ? 
                <button
                        className="btn btn-sm btn-primary"
                    >
                    Details
                </button>
              :""
      ),
        wrap: true,
        center: true,
        width: "300px",
    },
  ];
    
  const tableHeaderstyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        // backgroundColor: "#ccc",
      },
    },
  };

    const convertDate = (item) => {
        return moment(item).utcOffset(0, false).format("YYYY-MM-DD");
    };

    
  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);


  const requestType = [
    {
      label: '1 Hour Premium Tutoring',
      value: '1HPT',
    },
      {
      label: '30 Minutes Premium Tutoring',
      value: '30MPT',
    },
      {
      label: '30 Minutes Free Tutoring',
      value: '30FT',
    },

  ] 

  const addRequestManually = () => {
    setselectedUsers()
    setDateOfRequest()
    setTimeOfRequest()
    setBackdrop(true);
    setModal(true);
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      let users = [];
      querySnapshot.forEach((doc) => {
        let v = doc.data();
        if(v.ultimateAdvisingStudent && !v.coach){
           users.push({
            value: v.handle,
            label: v.name,
            image: typeof v.profilePicture != "undefined" ? v.profilePicture : "",
            handle: v.handle,
            email: v.email
          });
        }
       
      });
      setCheckUsers(users);
    };
    fetchAllUsers();
  }, []);

  const selectUsers = (e) => {
    setselectedUsers(e);
  };
  const handleRequestTutorOnClick = (e) => {
    e.preventDefault();
     setModal(!modal)
    if(!selectedUsers || dateOfRequest == "" ||  timeOfRequest == ""){
       Swal.fire({
            title: "Fill up required input",
            text: "Student, date and Time must not be empty",
            icon: "warning",
        });
      
    }else{
      
      const doc = {
        date:  moment(dateOfRequest).utcOffset(0, false).format(),
        starttime: moment(timeOfRequest).format('h:mm A'),
        endtime: selectedType == "1HPT" ?  moment(timeOfRequest,'h:mm a').add(1,'hours').format('h:mm A') :  moment(timeOfRequest,'h:mm a').add(30,'minutes').format('h:mm A'),
        status: "pending",
        codetype: selectedType,
        category: "schedule tutor",
        requestType: selectedType == "30FT" ? "free" : "premium", 
        duration: selectedType == "1HPT" ? "1hr" : "30min",
        studentHandle: selectedUsers.value,
        // coachScheduleID: props.item.schedid,
        coachHandle: props.userDetails.handle,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: 'coach'
      };
      const coachDetails = {
        coachEmail : props.userDetails.handle,
        coachName : props.userDetails.name,
      }
      dispatch(createRequestTutor({ handle: selectedUsers, docs: doc, coach: coachDetails }));
    }
  };


   useEffect(() => {

      if(props.createRequestTutor.loading){
          Swal.fire({
              allowOutsideClick : false,
              didOpen: () => {
                Swal.showLoading()
              }
          });
      }
      else{
        Swal.close()
      }

      if(props.createRequestTutor.createrequesttutor.length){
        Swal.fire({
            title: "Success",
            text: "Sucessfully created",
            icon: "success",
        });
      }

  }, [props.createRequestTutor])
  


   return (
    <>
    <Card className="shadow">

      <CardBody>
        {/* <Button color="success" className="float-right" onClick={addRequestManually}>Add a Request</Button> */}
          <CardTitle tag="h4" >MANAGE STUDENT REQUESTS</CardTitle>
          <CardSubtitle tag="h5" className="mb-2 text-muted">List of all current student requests session </CardSubtitle>
        {data.length ? (
          
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            // selectableRows
            // fixedHeader
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign="right"
          />
        ) : (
          <Alert color="primary">No data available .</Alert>
        )}
      </CardBody>
    </Card>

      <Modal
        isOpen={modal}
        toggle={modalToggle}
        backdrop={backdrop}
        size="lg"
        centered
      >
        <ModalHeader toggle={modalToggle}>
          Create a manual request
        </ModalHeader>
        <ModalBody className="pt-1">
            <Row>
              <Col lg="12">
                <FormGroup tag="fieldset" >
                    <h3 >Select a student</h3>
                      
                    <Select
                    name="selectusers"
                    options={checkUsers}
                    classNamePrefix="select"
                    onChange={selectUsers}
                    formatOptionLabel={(v) => (
                      <div className="d-flex ">
                        {v.value ? (
                          v.image ? (
                            <img
                              className="rounded-circle  mr-1 "
                              style={{ width: "25px", height: "25px" }}
                              src={
                                "https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
                                v.image
                              }
                              alt={v.label}
                            />
                          ) : (
                            <div
                              className="rounded-circle profile-letter-all-users mr-1 "
                              style={{ width: "25px", height: "25px" }}
                            >
                              {v.label ? v.label.trim()[0].toUpperCase() : ""}
                            </div>
                          )
                        ) : (
                          ""
                        )}

                        <span>{v.label}</span>
                      </div>
                    )}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup tag="fieldset" >
                  <h3 >Select Type Of Request</h3>

                  {
                    requestType.map((t,ii)=>{
                       return <FormGroup check key={ii}>
                        <Label check>
                          <Input 
                          type="radio" 
                          name="radio2" 
                          value={t.value}
                          checked={t.value === selectedType}
                          onChange={(e) => handleSelectType(e,t.value)}
                          />
                          {
                             t.value == "30FT" ?
                             
                             <>
                             {t.label + " "}  
                              <span className="fa fa-info-circle" id={"freeinfo"} ></span>
                              <UncontrolledTooltip placement="right" target={"freeinfo"}>
                                Only choose the 30-minute bonus session if it has already been
                                  approved. These sessions should be reserved for quarterly
                                  check-ins or for general coaching when you have exhausted your
                                  premium hours.
                              </UncontrolledTooltip>
                              </>
                             :
                              ' '+t.label 
                          }
                          
                        </Label>
                      </FormGroup>
                    })
                  }

                </FormGroup>
              </Col>
              <Col lg="6">
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Date <span className="text-danger"> *</span>
                    </label>
                    <ReactDatetime
                        onChange={(e) => handleDateOnChange(e)}
                        isValidDate={valid}
                        timeFormat={false}
                        className="w-100"
                        inputProps={{ placeholder: "Select Date" }}
                    />
                    </FormGroup>
                </Col>
               <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Time <span className="text-danger">*</span>
                  </label>
                  <ReactDatetime
                      value={timeOfRequest}
                      onChange={(e) => handleTimeOnChange(e)}
                      timeConstraints={{
                       minutes: { min: 30, max: 30, step: 31 },
                    }}
                    dateFormat={false}
                    className="w-100"
                    inputProps={{ placeholder: "Select time" }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={modalToggle} >
           Cancel
          </Button>
          <Button color="primary"  onClick={handleRequestTutorOnClick}  disabled={selectedUsers && dateOfRequest && timeOfRequest ? false : true}>
           Save
          </Button>
        </ModalFooter>
      </Modal>
      </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchStudentRequestTutor: state.fetchStudentRequestTutor,
    createRequestTutor: state.createRequestTutor,
    approveStudentRequestTutor: state.approveStudentRequestTutor,
    disapproveStudentRequestTutor: state.disapproveStudentRequestTutor,
    fetchStudentRequestTutorHistory: state.fetchStudentRequestTutorHistory,
    updateModalUploadTutorVideoFileStatus:
    state.updateModalUploadTutorVideoFileStatus,
    editTutorRequest: state.editTutorRequest,
  };
};

export default connect(mapStateToProps)(ManageStudentRequest);
