import Header from 'components/Headers/Header'
import React from 'react'


import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
} from "reactstrap";

import AchievementsModal from 'components/Modal/AchievementsModal';
import AchievementsTable from 'components/Table/AchievementsTable';

import { connect, useDispatch } from 'react-redux';
import {updateAchievementsModal, newAchievements} from 'redux/Achievements/AchievementsActions'
import Plans from './Plans';
const Achievements = (props) => {
    const dispatch = useDispatch();

    const modalAddAchievements = props.updateAchievementsModalStatus.status;

    const toggleAddAchievements = () =>{
      dispatch(updateAchievementsModal(!props.updateAchievementsModalStatus.status));
      dispatch(newAchievements({status: true}))
    } 

    // useEffect(() => {
      
  
      if(modalAddAchievements && props.createAchievements.achievement.length && typeof props.createAchievements.error === "undefined"){
        dispatch(updateAchievementsModal(false));
      }
  
      if(modalAddAchievements && props.updateAchievements.achievement.length && typeof props.updateAchievements.error === "undefined"){
        dispatch(updateAchievementsModal(false));
      }
  
      if(modalAddAchievements && typeof props.createAchievements.error !== "undefined"){
        dispatch(updateAchievementsModal(false));
      }
  
      if(modalAddAchievements && typeof props.updateAchievements.error !== "undefined"){
        dispatch(updateAchievementsModal(false));
      }

    // }, [])
    
    

    

    return (
    <>
        <Header></Header>
        <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='float-right'>
                    <Button color="primary" disabled={props.adminUser.admin ? "true" : ""} onClick={() => toggleAddAchievements()}>Add Achievement</Button>
                </div>
              </CardHeader>
              <AchievementsTable />
              
            </Card>
          </div>
        </Row>
      </Container>
        <AchievementsModal toggleAddAchievements={toggleAddAchievements} modalAddAchievements={modalAddAchievements} />
      <Plans></Plans>
    </>
  )
}

const mapStateToProps = state => {
  return {
    createAchievements : state.createAchievements,
    updateAchievements : state.updateAchievements,
    updateAchievementsModalStatus : state.updateAchievementsModalStatus,
    editAchievements : state.editAchievements,
    adminUser : state.adminUser
  }
}



export default connect(mapStateToProps)(Achievements)