import { 
    FETCH_SSO_TOKEN_FAILURE,
    FETCH_SSO_TOKEN_REQUEST,
    FETCH_SSO_TOKEN_SUCCESS,
 } from "./IdeasTypes";

const initialFetchSSOTokenState = {
    ssotoken : [],
    loading: false,

}

export const fetchSSOTokenReducer = (state = initialFetchSSOTokenState, action) => {
    switch (action.type) {
        case FETCH_SSO_TOKEN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SSO_TOKEN_SUCCESS:
            return {
                loading: false,
                ssotoken: [action.payload]
            }
        case FETCH_SSO_TOKEN_FAILURE:
            return {
                loading: false,
                ssotoken: [],
                error: action.payload
            }
        default: return state;
    }
}