import { SAVE_COVID_19_IMPACT_RESET, SAVE_COVID_19_IMPACT_REQUEST, SAVE_COVID_19_IMPACT_SUCCESS, SAVE_COVID_19_IMPACT_FAILURE, FETCH_COVID_19_IMPACT_FAILURE, FETCH_COVID_19_IMPACT_REQUEST, FETCH_COVID_19_IMPACT_SUCCESS } from './Covid19ImpactTypes'

const initialCovid19ImpactState = {
    loading: false,
    covid19impact: [],
}

const initialFetchCovid19ImpactState = {
    covid19impact : [],
    loading: false,

}

export const covid19ImpactReducer  = (state = initialCovid19ImpactState, action) => {
    switch (action.type) {
        case SAVE_COVID_19_IMPACT_RESET:
            return {
                loading: false,
                covid19impact: [],
            }
        case SAVE_COVID_19_IMPACT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case SAVE_COVID_19_IMPACT_SUCCESS:
            return {
                loading: false,
                covid19impact: [action.payload]
            }
        case SAVE_COVID_19_IMPACT_FAILURE:
            return {
                loading: false,
                covid19impact: [],
                error: action.payload
            }
        default:
            return state;
    }
}


export const fetchCovid19ImpactReducer = (state = initialFetchCovid19ImpactState, action) => {
    switch (action.type) {
        case FETCH_COVID_19_IMPACT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_COVID_19_IMPACT_SUCCESS:
            return {
                loading: false,
                covid19impact: action.payload
            }
        case FETCH_COVID_19_IMPACT_FAILURE:
            return {
                loading: false,
                covid19impact: [],
                error: action.payload
            }
        default: return state;
    }
}