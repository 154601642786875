import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    Container,
    Row,
    Button,
    Form,
    Input,
    Col,
    FormGroup,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardTitle,
    CardText
} from "reactstrap";
import Header from 'components/Headers/Header'
import { useDispatch ,connect} from 'react-redux';
import Swal from 'sweetalert2'
import classnames from 'classnames';
import TestCard from 'components/Card/Test/TestCard'
import {fetchQuestionBank, createPracticeTestResult} from 'redux/DAT/DATActions'

const Topic = (props) => {

    const dispatch = useDispatch();

    const topics = {
        "dat-biology" : "Biology",
        "dat-general-chemistry" : "General Chemistry",
        "dat-organic-chemistry" : "Organic Chemistry",
        "dat-perceptual-ability" : "Perceptual Ability",
        "dat-reading-comprehension" : "Reading Comprehension",
        "dat-quantitative-reasoning" : "Quantitative Reasoning",
    }

    const [questionList, setQuestionList] = useState([])

    useEffect(() => {
        console.log(props.location.pathname.split("/")[2])
        
        dispatch(fetchQuestionBank())

    }, [])

    useEffect(() => {
        
        if(typeof props.questionBank.questionbank.practiceTest != "undefined"){
            if(props.questionBank.questionbank.practiceTest.length){
                setQuestionList(props.questionBank.questionbank.practiceTest)
                
            }
        }
        
        

    }, [props.questionBank])
    
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    
    const activeTabStyle = { color: "white" , backgroundColor:"#f5365c" }
    const inactiveTabStyle = { color: hover ? 'red' : '' }

    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
        console.log("true")
    };

    const handleMouseLeave = () => {
        setHover(false);
        console.log("false")
    };

    const [startPractice, setStartPractice] = useState(false)

    const handleStartPractice = () =>{
        setStartPractice(!startPractice)
    }

    useEffect(() => {
        setStartPractice(false)
     
        Swal.showLoading();

    }, [props.createPracticeTestResult])
    
    return (
    <>
        <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                
                </div>
            </Container>
        </div>
        
            <Container className="mt--7" fluid>
                <Row>
                    {startPractice?
                    <>
                        <Col md="1"></Col>
                        <Col md="10"><TestCard questionList={questionList}></TestCard></Col>
                        <Col md="1"></Col>
                    </>
                    :
                    <>
                        <Col md="1"></Col>
                        <Col md="10">
                            <Card className=''>
                                <CardHeader className="font-weight-bolder text-xl text-default">{topics[props.location.pathname.split("/")[2]]}</CardHeader>
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                        
                        <Col md="1"></Col>
                        <Col md="10 mt-5 mb-3">
                            <Nav tabs>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={ activeTab === '1' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '1'})}
                                        onClick={() => toggle('1')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        
                                    >
                                        Question Banks
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '2' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => toggle('2')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Video Content
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '3' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => toggle('3')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Study Notes
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '4' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '4' })}
                                        onClick={() => toggle('4')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Cheat Sheets
                                    </NavLink>
                                </NavItem>
                                <NavItem className="font-weight-bold">
                                    <NavLink
                                        style={activeTab === '5' ? activeTabStyle : inactiveTabStyle}
                                        className={classnames({ active: activeTab === '5' })}
                                        onClick={() => toggle('5')}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        Flash Cards
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col md="1"></Col>
                        <Col md="1"></Col>
                        <Col md="10">
                            <Card >
                                <CardBody className='mt-2 pt-2'>
                                    
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row className=' mb-1'>
                                                <Col sm="12 " ><p className='text-lg text-default font-weight-bolder mb-2'>Question Banks</p></Col>
                                                <Col sm="7">
                                                    <Card className='bg-default h-50 '>
                                                        <CardBody className='d-flex justify-content-center align-items-center'><h3 className="pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-white">GRAPH</h3></CardBody>
                                                    </Card>
                                                    <Row className='mb-3'>
                                                        <Col lg="6" md="12" className='mt-3'>
                                                            <Card className="card-stats mb-4 mb-xl-0 w-100">
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                                <i className="fas fa-calendar" />
                                                                            </div>
                                                                        </Col>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                            >
                                                                                Date
                                                                            </CardTitle>
                                                                            <span className="font-weight-bold mb-0">
                                                                            Jun 1, 1900    
                                                                            
                                                                            </span>
                                                                        </div>
                                                                        
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col lg="6" md="12" className='mt-3'>
                                                            <Card className="card-stats mb-4 mb-xl-0 w-100">
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col className="col-auto">
                                                                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                                    <i className="fas fa-clock" />
                                                                                </div>
                                                                            </Col>
                                                                            <div className="col">
                                                                                <CardTitle
                                                                                    tag="h5"
                                                                                    className="text-uppercase text-muted mb-0"
                                                                                >
                                                                                    Time Spent
                                                                                </CardTitle>
                                                                                <span className="h2 font-weight-bold mb-0">
                                                                                    
                                                                                0:20
                                                                                </span>
                                                                            </div>
                                                                            
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        <Col lg="6" md="12" className='mt-3'>
                                                            <Card className="card-stats mb-4 mb-xl-0 w-100">
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col className="col-auto">
                                                                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                                                <i className="fas fa-chart-pie" />
                                                                            </div>
                                                                        </Col>
                                                                        <div className="col">
                                                                            <CardTitle
                                                                                tag="h5"
                                                                                className="text-uppercase text-muted mb-0"
                                                                            >
                                                                                Score
                                                                            </CardTitle>
                                                                            <span className="h2 font-weight-bold mb-0">
                                                                                
                                                                            0/100
                                                                            </span>
                                                                        </div>
                                                                        
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <button className='btn btn-danger' onClick={handleStartPractice}>Start Practice</button>
                                                </Col>
                                                <Col sm="5">
                                                    <Row>
                                                        
                                                        
                                                        <Col sm="12">
                                                            <Card className='bg-danger'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-white'>Practice Test #1</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col sm="12 mt-3">
                                                            <Card className='bg-secondary'>
                                                                <CardBody><h3 className='pt-0 pb-0 mt-0 mb-0  font-weight-bolder text-gray'>Coming soon!</h3></CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <Row>
                                                <Col sm="12 text-center">
                                                    <div className='display-3'>Coming soon!</div>
                                            
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="1"></Col>
                    </>}
                </Row>
            </Container>
        
    </>
  )
}
const mapStateToProps = state => {
    return {
      userDetails : state.loginAuth.userDetails,
      questionBank : state.fetchQuestionBank,
      createPracticeTestResult : state.createPracticeTestResult
    }
}

export default connect(mapStateToProps)(Topic)