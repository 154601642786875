import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment-timezone";
import TextareaAutosize from "react-textarea-autosize";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
  CardText,
  Button,
  Row,
  Col,
  Input,
  Image,
  Form,
  View,
  Icon,
} from "reactstrap";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { app, db } from "../../firebase";
import { getUserData } from "redux/UserAuthentication/LoginActions";
import {
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  collection,
  query,
  where,
  onSnapshot,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  createCommunityComment,
  createCommunityCommentReset,
} from "redux/Community/CommunityAction";

const InputComment = (props) => {
  const dispatch = useDispatch();

  const [inputCommentValue, setInputCommentValue] = useState("");
  const fileInputRef = useRef(null);
  const [fileItemsLimit, setFileItemsLimit] = useState(10);
  const [imagePreview, setImagePreview] = useState([]);
  let toastId = useRef(null);

  const handleChange = (event) => {
    setInputCommentValue(event.target.value);
  };
  //Submit a comment to a post
  // const handleKeyDownComment = async (e, postid) => {
  const onSubmit = (e, postid) => {
    const formData = new FormData();
    formData.append("userHandle", props.userDetails.handle);
    formData.append("comment", inputCommentValue);
    formData.append("postid", postid);
    formData.append("userId", props.userDetails.userId);
    if (imagePreview.length) {
      for (let i = 0; i < imagePreview.length; i++) {
        formData.append(`files[${i}]`, imagePreview[i]);
      }
    }

    dispatch(createCommunityComment(formData));
    setImagePreview([]);
    setInputCommentValue("");
  };
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const maxVideoSizeInMB = 20;
      const maxImageSizeInMB = 10;
      const allowedImgTypes = [
        "image/jpg",
        "image/png",
        "image/jpeg",
        "image/gif",
      ];
      const allowedVideoTypes = ["video/mp4"];
      if (e.target.files.length > fileItemsLimit) {
        Swal.fire({
          title: "Upload limit!",
          text: " Files cannot be more than " + fileItemsLimit + " items",
          icon: "error",
        });
      } else {
        let pushItem = [];
        for (let i = 0; i < e.target.files.length; i++) {
          // console.log(e.target.files[i].name);
          // console.log(e.target.files.item(i).name);
          let fileSizeInMB = e.target.files[i].size / (1024 * 1024);
          if (allowedVideoTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxVideoSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxVideoSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else if (allowedImgTypes.includes(e.target.files[i].type)) {
            if (fileSizeInMB > maxImageSizeInMB) {
              Swal.fire({
                title: "Maximum limit!",
                text:
                  e.target.files[i].name +
                  " File size exceeds the maximum limit of " +
                  maxImageSizeInMB +
                  "MB",
                icon: "error",
              });
              return;
            } else {
              pushItem.push(e.target.files[i]);
            }
          } else {
            Swal.fire({
              title: "Invalid file!",
              text: " Upoad a valid file ",
              icon: "error",
            });
            return;
          }
        }
        setImagePreview(pushItem);
      }
    }
  };

  // useEffect(() => {
  //     if (fileInputRef) fileInputRef.current.value = null;
  // }, [imagePreview]);
  const clickInputFile = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const removeFile = (i) => {
    const newPreview = imagePreview.filter((item, index) => index !== i);
    setImagePreview(newPreview);
  };

  useEffect(() => {
    if (props.createCommunityComment.loading) {
      // check if we already displayed a toast
      if (toastId.current === null) {
        toastId.current = toast("Adding a comment...", {
          type: toast.TYPE.INFO,
        });
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.INFO,
          render: "Adding a comment...",
        });
      }
    } else {
      if (props.createCommunityComment.createcommunitycomment.length) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: "Sucessfully added a comment!",
        });
        toastId = null;
      } else if (typeof props.createCommunityComment.error !== "undefined") {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: "Oopps! something went wrong",
        });
      }
    }
  }, [props.createCommunityComment]);

  return (
    <>
      <div className="d-flex flex-row mb-1 m-3 ">
        {props.item.anonymous == 1 &&
        props.userDetails.handle == props.item.userHandle ? (
          <div
            className="rounded-circle profile-letter-all-users mr-2"
            style={{
              width: "40px",
              height: "40px",
            }}
          >
            {typeof props.userDetails.anonymousName != "undefined"
              ? props.userDetails.anonymousName.trim()[0]
              : "A"}
          </div>
        ) : typeof props.userDetails.profilePicture != "undefined" ? (
          <img
            src={
              "https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/" +
              props.userDetails.profilePicture
            }
            className="mr-2 rounded-circle"
            alt="..."
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        ) : (
          <div
            className="rounded-circle profile-letter-all-users mr-2"
            style={{
              width: "40px",
              height: "40px",
            }}
          >
            {props.userDetails.name.trim()[0]}
          </div>
        )}
        {/* <img  src={props.userDetails.profilePicture ? 'https://storage.googleapis.com/pre-dental-tracker.appspot.com/profile-picture/'+props.userDetails.profilePicture : 'https://picsum.photos/id/135/318/180?grayscale&blur=10' } class="mr-2 rounded-circle" alt="..."  style={{
                    width: '40px',
                    height: '40px'
                }} /> */}
        <div className="media-body ">
          <div className="formCustom">
            <TextareaAutosize
              autoFocus
              className="inputCustom form-control"
              id={"commentInput-" + props.item.postid}
              maxRows={6}
              onChange={handleChange}
              // onKeyDown={e => handleKeyDownComment(e, props.item.postid)}
              placeholder="Write a comment"
              value={inputCommentValue}
              style={{
                overflow: "hidden",
                resize: "none",
              }}
            />
            <div className="d-flex flex-row ml-1 p-1 justify-content-between align-items-center">
              <div className="uploadIconsContainer pt-1">
                <i
                  className="fa fa-camera iconUpload"
                  onClick={(event) => clickInputFile(event)}
                ></i>
              </div>
              <div className="submitCommentSection mr-1">
                {/* <i className="fas fa-arrow-alt-circle-right "></i> */}
                <Button
                  color="primary"
                  size="sm"
                  disabled={
                    inputCommentValue || imagePreview.length ? false : true
                  }
                  onClick={(e) => onSubmit(e, props.item.postid)}
                >
                  Send{" "}
                </Button>
              </div>
            </div>
            <input
              type="file"
              accept="video/*,image/*"
              multiple
              onChange={(event) => handleFileChange(event)}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-row align-items-center mb-1 mt-2 ml-5"
        style={{ overflowY: "auto" }}
      >
        {imagePreview
          ? imagePreview.map((file, i) => {
              return (
                <div style={{ position: "relative" }}>
                  <Button close outline onClick={(event) => removeFile(i)} />
                  {file.type == "video/mp4" ? (
                    <video controls width="250" height="150">
                      <source
                        type={file.type}
                        src={URL.createObjectURL(file)}
                      />
                    </video>
                  ) : (
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      width="250"
                      height="150"
                      className=" m-2 rounded d-block "
                      style={{ overflowY: "scroll" }}
                    />
                  )}
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    createCommunityComment: state.createCommunityComment,
  };
};

export default connect(mapStateToProps)(InputComment);
