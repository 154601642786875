import React, {useEffect, useState} from 'react'
import { Button, Modal, ModalBody, ModalFooter, Card, CardHeader, Row, Col, FormGroup, Input, CardBody, Form, Progress, Table  } from 'reactstrap';



import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2'

import { createMcatScore, createMcatScoreReset, editMcatScore, newMcatScore,updateMcatScore, updateMcatScoreReset } from 'redux/McatScores/McatScoresActions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import Test from 'components/Options/McatScores/Test';
import { testMcatScore } from 'util/DataList';
import { aamcCarsScore,aamcChemScore, aamcBioChemScore, aamcTotalScore,aamcPsychSocScore} from 'util/AamcScoreConversion';

import  Select  from 'react-select';
import { customDropdownWithoutDotColor } from 'util/reactCustomStyles';


const McatScoresModal = (props) => {

  const dispatch = useDispatch();

  const { register, getValues, unregister,watch, handleSubmit,setValue, formState: { errors } } = useForm();

  let toggleAddMcatScores = props.toggleAddMcatScores;
  let modalAddMcatScores = props.modalAddMcatScores;

  const messageRequired = "This field is required.";
  


  const [mcatScoreId, setMcatScoreId] = useState('');

  const [generalChemistryScore, setGeneralChemistryScore] = useState(0);
  const [organicChemistryScore, setOrganicChemistryScore] = useState(0);
  const [biologyScore, setBiologyScore] = useState(0);
  const [patScore, setPatScore] = useState(0);
  const [readingComprehensionScore, setReadingComprehensionScore] = useState(0);
  const [quantitiveReasoningScore, setQuantitiveReasoningScore] = useState(0);
  const [totalScienceScore, setTotalScienceScore] = useState(0);
  const [academicAverageScore, setAcademicAverageScore] = useState(0);

  
  useEffect(() => {
    register("inputTest", { required: messageRequired });
    register("inputDate", { required: messageRequired });
    register("inputGeneralChemistry", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    register("inputOrganicChemistry", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    register("inputBiology", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"}});
    register("inputPat", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    register("inputReadingComprehension", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    register("inputQuantitiveReasoning", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    register("inputTotalScience", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"}});
    register("inputAcademicAverage", { required: messageRequired, min:{value:0, message:"Must be greater than or equal to 0"}, max:{value:30, message:"Must be less than or equal to 30"} });
    // premedReqs.map((value,index)=>{
    //   register("inputRequirement." + index, { required: messageRequired })
    // });


    // setValue("inputGeneralChemistry", 0)
    // setValue("inputOrganicChemistry", 0)
    // setValue("inputBiology", 0)
    // setValue("inputReadingComprehension", 0)

    if(props.editMcatScore.status){
          
      const mcatScoreData  = findMcatScore(props.fetchMcatScores.mcatscores,props.editMcatScore.mcatScoreId);

      setMcatScoreId(props.editMcatScore.mcatScoreId);


      if(testMcatScore.includes(mcatScoreData.label)){
        setValue("inputTest", mcatScoreData.label)
        unregister("inputTestOther");
      }
      else{
        
        register("inputTestOther", { required: messageRequired});
        setValue("inputTest","Other");
        setValue("inputTestOther", mcatScoreData.label);
      }
     
      
      setValue("inputDate", mcatScoreData.date)
      setValue("inputGeneralChemistry", mcatScoreData.generalChemistry)
      setValue("inputOrganicChemistry", mcatScoreData.organicChemistry)
      setValue("inputBiology", mcatScoreData.biology)
      setValue("inputPat", mcatScoreData.pat)
      setValue("inputReadingComprehension", mcatScoreData.readingComprehension)
      setValue("inputQuantitiveReasoning", mcatScoreData.quantitiveReasoning)
      setValue("inputTotalScience", mcatScoreData.totalScience)
      setValue("inputAcademicAverage", mcatScoreData.academicAverage)
      

      setGeneralChemistryScore(mcatScoreData.generalChemistryScore);
      setOrganicChemistryScore(mcatScoreData.organicChemistryScore);
      setBiologyScore(mcatScoreData.biologyScore);
      setPatScore(mcatScoreData.patScore);
      setReadingComprehensionScore(mcatScoreData.readingComprehensionScore);
      setQuantitiveReasoningScore(mcatScoreData.quantitiveReasoningScore);
      setTotalScienceScore(mcatScoreData.totalScienceScore);
      setAcademicAverageScore(mcatScoreData.academicAverageScore);
      
      dispatch(editMcatScore({
          status : false,
          mcatScoreId : ""
      }));

    }
    if(props.newMcatScore.status){
      setMcatScoreId('');
      setValue("inputTestOther", "")
      setValue("inputTest", "")
      setValue("inputDate", "")
      setValue("inputGeneralChemistry", "")
      setValue("inputOrganicChemistry","")
      setValue("inputBiology", "")
      setValue("inputPat","")
      setValue("inputReadingComprehension","")
      setValue("inputQuantitiveReasoning", "")
      setValue("inputTotalScience","")
      setValue("inputAcademicAverage", "")

      setGeneralChemistryScore(0);
      setOrganicChemistryScore(0);
      setBiologyScore(0);
      setPatScore(0);
      setReadingComprehensionScore(0);
      setQuantitiveReasoningScore(0);
      setTotalScienceScore(0);
      setAcademicAverageScore(0);

      dispatch(newMcatScore({status : false}));
    }
    
  })

  if(props.createMcatScores.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.createMcatScores.mcatscore.length && typeof props.createMcatScores.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully added new Mcat Score!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(createMcatScoreReset());
        
      }

      else if(typeof props.createMcatScores.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to add new Mcat Score!',
            icon :'error',
            timer: 3000
            
          }
        )     
        dispatch(createMcatScoreReset());       
        
      }

    }


    if(props.updateMcatScores.loading)
      Swal.fire({
        allowOutsideClick : false,
        didOpen: () => {
          Swal.showLoading()
          
        }
      });

      
    else{
     
      
      if(props.updateMcatScores.mcatscore.length && typeof props.updateMcatScores.error === "undefined"){
        Swal.fire({
            title: 'Success!',
            text: 'Successfully updated Mcat Score!',
            icon : 'success',
            timer: 3000
            
          }
        )
        dispatch(updateMcatScoreReset());
        
        
      }

      else if(typeof props.updateMcatScores.error !== "undefined"){
        Swal.fire({
            title:'Failed!',
            text:'Failed to update Mcat Score!',
            icon :'error',
            timer: 3000
          }
        )            

        dispatch(updateMcatScoreReset());
        
      }

      

    }



  const findMcatScore = (arr,mcatScoreId) => {
      
    return arr.find(x => x.mcatScoreId === mcatScoreId);
  }

  const onSubmit = (data) => {


    data.inputHandle = props.userDetails.handle;
    data.handle = props.userDetails.handle;
    data.userId = props.userDetails.userId;

    if(mcatScoreId){
      data['docId'] = mcatScoreId;
      dispatch(updateMcatScore(data));
    }
    else{
      dispatch(createMcatScore(data));
    }
    
  }

  const handleInputGeneralChemistry = (e) =>{
    setValue("inputGeneralChemistry", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 59){
      setGeneralChemistryScore(e.target.value);
    }
    else{
      setGeneralChemistryScore(0);
    }
    
  }

  const handleInputOrganicChemistry = (e) =>{
    setValue("inputOrganicChemistry", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 53){
      setOrganicChemistryScore(e.target.value)
    }
    else{
      setOrganicChemistryScore(0);
    }
  }

  const handleInputBiology = (e) =>{
    setValue("inputBiology", e.target.value)
    if(e.target.value >= 1 && e.target.value  <= 59){
      setBiologyScore(e.target.value)
    }
    else{
      setBiologyScore(0);
    }
  }

  const handleInputPat = (e) =>{
    setValue("inputPat", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setPatScore(e.target.value)
    }
    else{
      setPatScore(0);
    }
    
  }

  const handleInputReadingComprehension = (e) =>{
    setValue("inputReadingComprehension", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setReadingComprehensionScore(e.target.value)
    }
    else{
      setReadingComprehensionScore(0);
    }
    
  }

  const handleInputQuantitiveReasoning = (e) =>{
    setValue("inputQuantitiveReasoning", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setQuantitiveReasoningScore(e.target.value)
    }
    else{
      setQuantitiveReasoningScore(0);
    }
    
  }

  const handleInputTotalScience = (e) =>{
    setValue("inputTotalScience", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setTotalScienceScore(e.target.value)
    }
    else{
      setTotalScienceScore(0);
    }
    
  }

  const handleInputAcademicAverage = (e) =>{
    setValue("inputAcademicAverage", e.target.value)

    if(e.target.value >= 1 && e.target.value  <= 59){
      setAcademicAverageScore(e.target.value)
    }
    else{
      setAcademicAverageScore(0);
    }
    
  }

  const handleInputTest = (e) => {

    setValue("inputTest", e.value)

    if(e.value == 'Other'){
      register("inputTestOther", { required: messageRequired});
    }
    else{
      unregister("inputTestOther");
    }
    
  }
  
  return (
    <div>
        
        <Modal className="modal-dialog-centered" size="lg" isOpen={modalAddMcatScores} toggle={toggleAddMcatScores} >
          <Form  onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='modal-body p-0'>
          <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{mcatScoreId ? "Edit" : "Add" } DAT Scores</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{maxHeight:"650px", overflowY: 'auto'}}>
                
                  <h6 className="heading-small text-muted mb-4">
                    DAT Score information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTest"
                          >
                            Name of test <span className='text-danger'>*</span>
                          </label>
                          {/* <Input
                            placeholder='Select name of test'
                            className="form-control-alternative"
                            id="inputTest"
                            name="inputTest"
                            value={watch("inputTest")}
                            onChange={e => handleInputTest(e) }
                            type="select"
                          >
                            <Test></Test>
                          </Input>  */}

                          {/* <Select 
                             placeholder='Select name of test'
                            options={testMcatScore} 
                            styles={customDropdownWithoutDotColor }  
                            onChange={(e) => handleInputTest(e)}
                            menuPortalTarget={document.body}
                            value={testMcatScore.find((option) => option.value === watch("inputTest"))}
                          
                          /> */}

                          <Input
                            className="form-control-alternative"
                            id="inputTest"
                            name="inputTest"
                            value={watch("inputTest")}
                            onChange={e => setValue("inputTest", e.target.value) }
                            type="text"
                          />

                          
                        { errors.inputTest && <small className="text-danger">{errors.inputTest.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      <Col lg="6" className={getValues("inputTest") == "Other" ? "" : "d-none"}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTestOther"
                          >
                          <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputTestOther"
                            name="inputTestOther"
                            value={watch("inputTestOther")}
                            onChange={e => setValue("inputTestOther", e.target.value) }
                            type="text"
                          />
                            
                          
                          
                        { errors.inputTestOther && <small className="text-danger">{errors.inputTestOther.message}</small> }
                        </FormGroup>
                        
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputDate"
                          >
                            Date <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative is-invalid"
                            id="inputDate"
                            name="inputDate"
                            value={watch("inputDate")}
                            onChange={e => setValue("inputDate", e.target.value)}
                            type="date"
                          />
                          { errors.inputDate && <small className="text-danger">{errors.inputDate.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputGeneralChemistry"
                          >
                            GENERAL CHEMISTRY <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputGeneralChemistry"
                            name="inputGeneralChemistry"
                            value={watch("inputGeneralChemistry")}
                            onChange={e => handleInputGeneralChemistry(e)}
                            type="number"
                          >
                          </Input>
                          { errors.inputGeneralChemistry && <small className="text-danger">{errors.inputGeneralChemistry.message}</small> }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputOrganicChemistry"
                          >
                            ORGANIC CHEMISTRY <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputOrganicChemistry"
                            name="inputOrganicChemistry"
                            value={watch("inputOrganicChemistry")}
                            onChange={e => handleInputOrganicChemistry(e)}
                            type="number"
                          />
                          
                          { errors.inputOrganicChemistry && <small className="text-danger">{errors.inputOrganicChemistry.message}</small> }
                        </FormGroup>
                      </Col>
                    
                     
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputBiology"
                          >
                            BIOLOGY <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputBiology"
                            name="inputBiology"
                            value={watch("inputBiology")}
                            onChange={e => handleInputBiology(e)}
                            type="number"
                            
                          />
                          { errors.inputBiology && <small className="text-danger">{errors.inputBiology.message}</small> }
                        </FormGroup>
                      </Col>
                      
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputPat"
                          >
                            PAT <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputPat"
                            type="text"
                            name="inputPat"
                            value={watch("inputPat")}
                            onChange={e => handleInputPat(e)}
                          />
                         { errors.inputPat && <small className="text-danger">{errors.inputPat.message}</small> } 
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputReadingComprehension"
                          >
                            READING COMPREHENSION <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputReadingComprehension"
                            type="text"
                            name="inputReadingComprehension"
                            value={watch("inputReadingComprehension")}
                            onChange={e => handleInputReadingComprehension(e)}
                          />
                         { errors.inputReadingComprehension && <small className="text-danger">{errors.inputReadingComprehension.message}</small> } 
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputQuantitiveReasoning"
                          >
                            QUANTITIVE REASONING <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputQuantitiveReasoning"
                            type="text"
                            name="inputQuantitiveReasoning"
                            value={watch("inputQuantitiveReasoning")}
                            onChange={e => handleInputQuantitiveReasoning(e)}
                          />
                         { errors.inputQuantitiveReasoning && <small className="text-danger">{errors.inputQuantitiveReasoning.message}</small> } 
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputTotalScience"
                          >
                            TOTAL SCIENCE <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputTotalScience"
                            type="text"
                            name="inputTotalScience"
                            value={watch("inputTotalScience")}
                            onChange={e => handleInputTotalScience(e)}
                          />
                         { errors.inputTotalScience && <small className="text-danger">{errors.inputTotalScience.message}</small> } 
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputAcademicAverage"
                          >
                            ACADEMIC AVERAGE <span className='text-danger'>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="inputAcademicAverage"
                            type="text"
                            name="inputAcademicAverage"
                            value={watch("inputAcademicAverage")}
                            onChange={e => handleInputAcademicAverage(e)}
                          />
                         { errors.inputAcademicAverage && <small className="text-danger">{errors.inputAcademicAverage.message}</small> } 
                        </FormGroup>
                      </Col>

                    </Row>
                  </div>
                  
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
          {props.adminUser.admin ? "" :
              <>
            <Button color="primary" type='submit'>
            {mcatScoreId ? "Update" : "Save" } 
            </Button>{' '}
            <Button color="secondary" onClick={toggleAddMcatScores}>
              Cancel
            </Button>
            </>}
          </ModalFooter>
          </Form>
        </Modal>
      </div>
  )
}

const mapStateToProps = state => {
  return {
    createMcatScores : state.createMcatScores,
    updateMcatScores : state.updateMcatScores,
    editMcatScore : state.editMcatScore,
    fetchMcatScores : state.fetchMcatScores,
    newMcatScore : state.newMcatScore,
    userDetails : state.loginAuth.userDetails,
    adminUser : state.adminUser
  }
}

export default connect(mapStateToProps)(McatScoresModal)