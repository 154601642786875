import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect, useDispatch } from "react-redux";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const TotalHoursPerActivity = (props) => {


  const [clinicalCompletedHours, setClinicalCompletedHours] = useState(0)
  const [shadowCompletedHours, setShadowCompletedHours] = useState(0)
  const [volunteerCompletedHours, setVolunteerCompletedHours] = useState(0)

  useEffect(() => {
      if(props.fetchWorkActivities.workactivities.length){
          let clinicalCompletedHoursVal = 0;
          let shadowCompletedHoursVal = 0;
          let volunteerCompletedHoursVal = 0;

          setClinicalCompletedHours(clinicalCompletedHoursVal)
          setShadowCompletedHours(shadowCompletedHoursVal)
          setVolunteerCompletedHours(volunteerCompletedHoursVal)

          props.fetchWorkActivities.workactivities.map((value, index) => {
            
            //   if(typeof value.workType != "undefined"){
            //       if(value.workType == "Clinical"){
            //           clinicalCompletedHoursVal += parseFloat(value.completedHours)
                      
            //           setClinicalCompletedHours(clinicalCompletedHoursVal);
            //           if(value.repeated){
            //               value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {
                            
            //                   const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
                              
            //                   setClinicalCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
            //               })
            //           }

            //           // if(value.anticipated){
            //           //     value.anticipatedHours.map((anticipatedHoursValue, anticipatedHoursIndex) => {

            //           //         clinicalCompletedHoursVal += parseFloat(getCompletedHours("CompletedHours",anticipatedHoursValue))
            //           //         setClinicalCompletedHours((prevTotal) => prevTotal + clinicalCompletedHoursVal);
            //           //     })
            //           // }

            //       }
            //       else if(value.workType == "Shadowing"){
            //           shadowCompletedHoursVal += parseFloat(value.completedHours)
            //           setShadowCompletedHours(shadowCompletedHoursVal);
            //           if(value.repeated){
            //               value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

            //                     const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
            //                     setShadowCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
            //               })
            //           }

            //       }
            //       else if(value.workType == "Volunteering"){
            //           volunteerCompletedHoursVal += parseFloat(value.completedHours)
            //           setVolunteerCompletedHours(volunteerCompletedHoursVal);
            //           if(value.repeated){
            //               value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

            //                     const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
            //                     setVolunteerCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
            //               })
            //           }
            //       }

            //       if(value.experienceType == "Community Service/Volunteer - Medical/Clinical"){
            //         volunteerCompletedHoursVal += parseFloat(value.completedHours)
            //           setVolunteerCompletedHours(volunteerCompletedHoursVal);
            //           if(value.repeated){
            //               value.repeatedHours.map((repeatedHoursValue, repeatedHoursIndex) => {

            //                     const repeatedCompletedHours = parseFloat(getCompletedHours("CompletedHours",repeatedHoursValue))
            //                     setVolunteerCompletedHours((prevTotal) => prevTotal + repeatedCompletedHours);
            //               })
            //           }

            //       }


            //   }

            if(typeof value.experienceType != "undefined"){
                if(value.experienceType.includes("Shadowing")){
                    shadowCompletedHoursVal += parseFloat(value.experienceDetailsAverageWeeklyHours) * parseFloat(value.experienceDetailsNumberOfWeeks)
                    
                    setShadowCompletedHours(shadowCompletedHoursVal);

    
                }
            }

            if(typeof value.experienceType != "undefined"){
                if(value.experienceType.includes("Volunteer")){
                    volunteerCompletedHoursVal += parseFloat(value.experienceDetailsAverageWeeklyHours) * parseFloat(value.experienceDetailsNumberOfWeeks)
                    
                    setVolunteerCompletedHours(shadowCompletedHoursVal);

    
                }
            }

 

          })

          
        

          
      }

     

  }, [props.fetchWorkActivities])
  
  const getCompletedHours = (partialName, data) => {
        
    let sum = 0;
    for (const key in data) {
        if (key.includes('CompletedHours')) {
            sum += parseInt(data[key]);
        }
    }

    return sum
  }

  const grade = useSelector(state => state.fetchGrade.grade);
  return (
    <>
        <Row>
            {/* <Col  xl="4" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                Clinical Completed Hours
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                
                                {clinicalCompletedHours}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fas fa-syringe" />
                            </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col> */}
            <Col  xl="6" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            Shadow Completed Hours
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{shadowCompletedHours}</span>
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                            <i className="fas fa-user" />
                        </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col>
            <Col  xl="6" className="d-flex">
                <Card className="card-stats mb-4 mb-xl-0 w-100">
                    <CardBody>
                    <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            Volunteer Completed Hours
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{volunteerCompletedHours}</span>
                        </div>
                        <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-hands-helping" />
                        </div>
                        </Col>
                    </Row>
                    
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    fetchGrade : state.fetchGrade,
    userDetails : state.loginAuth.userDetails,
    fetchWorkActivities : state.fetchWorkActivities,
  }
}

export default connect(mapStateToProps)(TotalHoursPerActivity);


