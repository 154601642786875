import {
  CREATE_PROMEMBER_POST_REQUEST,
  CREATE_PROMEMBER_POST_SUCCESS,
  CREATE_PROMEMBER_POST_FAILURE,
  CREATE_PROMEMBER_POST_RESET,
  UPDATE_PROMEMBER_POST_REQUEST,
  UPDATE_PROMEMBER_POST_SUCCESS,
  UPDATE_PROMEMBER_POST_FAILURE,
  UPDATE_PROMEMBER_POST_RESET,
  DELETE_PROMEMBER_POST_REQUEST,
  DELETE_PROMEMBER_POST_SUCCESS,
  DELETE_PROMEMBER_POST_FAILURE,
  DELETE_PROMEMBER_POST_RESET,
  FETCH_ALL_PROMEMBER_POSTS_RESET,
  FETCH_ALL_PROMEMBER_POSTS_REQUEST,
  FETCH_ALL_PROMEMBER_POSTS_FAILURE,
  FETCH_ALL_PROMEMBER_POSTS_SUCCESS,
  CREATE_PROMEMBER_COMMENT_REQUEST,
  CREATE_PROMEMBER_COMMENT_SUCCESS,
  CREATE_PROMEMBER_COMMENT_FAILURE,
  CREATE_PROMEMBER_COMMENT_RESET,
  UPDATE_PROMEMBER_COMMENT_REQUEST,
  UPDATE_PROMEMBER_COMMENT_SUCCESS,
  UPDATE_PROMEMBER_COMMENT_FAILURE,
  UPDATE_PROMEMBER_COMMENT_RESET,
  DELETE_PROMEMBER_COMMENT_REQUEST,
  DELETE_PROMEMBER_COMMENT_SUCCESS,
  DELETE_PROMEMBER_COMMENT_FAILURE,
  DELETE_PROMEMBER_COMMENT_RESET,
  CREATE_PROMEMBER_REPLY_REQUEST,
  CREATE_PROMEMBER_REPLY_SUCCESS,
  CREATE_PROMEMBER_REPLY_FAILURE,
  CREATE_PROMEMBER_REPLY_RESET,
  UPDATE_PROMEMBER_REPLY_REQUEST,
  UPDATE_PROMEMBER_REPLY_SUCCESS,
  UPDATE_PROMEMBER_REPLY_FAILURE,
  UPDATE_PROMEMBER_REPLY_RESET,
  DELETE_PROMEMBER_REPLY_REQUEST,
  DELETE_PROMEMBER_REPLY_SUCCESS,
  DELETE_PROMEMBER_REPLY_FAILURE,
  DELETE_PROMEMBER_REPLY_RESET,
  CREATE_PROMEMBER_LIKEPOST_REQUEST,
  CREATE_PROMEMBER_LIKEPOST_SUCCESS,
  CREATE_PROMEMBER_LIKEPOST_FAILURE,
  CREATE_PROMEMBER_LIKEPOST_RESET,
  CREATE_PROMEMBER_SAVEPOST_REQUEST,
  CREATE_PROMEMBER_SAVEPOST_SUCCESS,
  CREATE_PROMEMBER_SAVEPOST_FAILURE,
  CREATE_PROMEMBER_SAVEPOST_RESET,
  CREATE_PROMEMBER_LIKECOMMENT_REQUEST,
  CREATE_PROMEMBER_LIKECOMMENT_SUCCESS,
  CREATE_PROMEMBER_LIKECOMMENT_FAILURE,
  CREATE_PROMEMBER_LIKECOMMENT_RESET,
  FETCH_PROMEMBER_LIKEDPOST_RESET,
  FETCH_PROMEMBER_LIKEDPOST_REQUEST,
  FETCH_PROMEMBER_LIKEDPOST_FAILURE,
  FETCH_PROMEMBER_LIKEDPOST_SUCCESS,
} from "./ProMemberType";

const initialCreateProMemberPostState = {
  createpromemberpost: [],
  loading: false,
};

export const createProMemberPostReducer = (
  state = initialCreateProMemberPostState,
  action
) => {
  switch (action.type) {
    case CREATE_PROMEMBER_POST_RESET:
      return {
        loading: false,
        createpromemberpost: [],
      };
    case CREATE_PROMEMBER_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROMEMBER_POST_SUCCESS:
      return {
        loading: false,
        createpromemberpost: [action.payload],
      };
    case CREATE_PROMEMBER_POST_FAILURE:
      return {
        loading: false,
        createpromemberpost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityProMemberPostState = {
  updatecommunitypromemberpost: [],
  loading: false,
};

export const updateCommunityProMemberPostReducer = (
  state = initialUpdateCommunityProMemberPostState,
  action
) => {
  switch (action.type) {
    case UPDATE_PROMEMBER_POST_RESET:
      return {
        loading: false,
        updatecommunitypromemberpost: [],
      };
    case UPDATE_PROMEMBER_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROMEMBER_POST_SUCCESS:
      return {
        loading: false,
        updatecommunitypromemberpost: [action.payload],
      };
    case UPDATE_PROMEMBER_POST_FAILURE:
      return {
        loading: false,
        updatecommunitypromemberpost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityProMemberPostState = {
  deletecommunitypromemberpost: [],
  loading: false,
};

export const deleteCommunityProMemberPostReducer = (
  state = initialDeleteCommunityProMemberPostState,
  action
) => {
  switch (action.type) {
    case DELETE_PROMEMBER_POST_RESET:
      return {
        loading: false,
        deletecommunitypromemberpost: [],
      };
    case DELETE_PROMEMBER_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROMEMBER_POST_SUCCESS:
      return {
        loading: false,
        deletecommunitypromemberpost: [action.payload],
      };
    case DELETE_PROMEMBER_POST_FAILURE:
      return {
        loading: false,
        deletecommunitypromemberpost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateProMemberCommentState = {
  createcommunitypromembercomment: [],
  loading: false,
};

export const createCommunityProMemberCommentReducer = (
  state = initialCreateProMemberCommentState,
  action
) => {
  switch (action.type) {
    case CREATE_PROMEMBER_COMMENT_RESET:
      return {
        loading: false,
        createcommunitypromembercomment: [],
      };
    case CREATE_PROMEMBER_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROMEMBER_COMMENT_SUCCESS:
      return {
        loading: false,
        createcommunitypromembercomment: [action.payload],
      };
    case CREATE_PROMEMBER_COMMENT_FAILURE:
      return {
        loading: false,
        createcommunitypromembercomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityCommentState = {
  updatecommunitypromembercomment: [],
  loading: false,
};

export const updateCommunityProMemberCommentReducer = (
  state = initialUpdateCommunityCommentState,
  action
) => {
  switch (action.type) {
    case UPDATE_PROMEMBER_COMMENT_RESET:
      return {
        loading: false,
        updatecommunitypromembercomment: [],
      };
    case UPDATE_PROMEMBER_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROMEMBER_COMMENT_SUCCESS:
      return {
        loading: false,
        updatecommunitypromembercomment: [action.payload],
      };
    case UPDATE_PROMEMBER_COMMENT_FAILURE:
      return {
        loading: false,
        updatecommunitypromembercomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityProMemberCommentState = {
  deletecommunitypromembercomment: [],
  loading: false,
};

export const deleteCommunityProMemberCommentReducer = (
  state = initialDeleteCommunityProMemberCommentState,
  action
) => {
  switch (action.type) {
    case DELETE_PROMEMBER_COMMENT_RESET:
      return {
        loading: false,
        deletecommunitypromembercomment: [],
      };
    case DELETE_PROMEMBER_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROMEMBER_COMMENT_SUCCESS:
      return {
        loading: false,
        deletecommunitypromembercomment: [action.payload],
      };
    case DELETE_PROMEMBER_COMMENT_FAILURE:
      return {
        loading: false,
        deletecommunitypromembercomment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateProMemberReplyState = {
  createcommunityreply: [],
  loading: false,
};

export const createCommunityProMemberReplyReducer = (
  state = initialCreateProMemberReplyState,
  action
) => {
  switch (action.type) {
    case CREATE_PROMEMBER_REPLY_RESET:
      return {
        loading: false,
        createcommunityreply: [],
      };
    case CREATE_PROMEMBER_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROMEMBER_REPLY_SUCCESS:
      return {
        loading: false,
        createcommunityreply: [action.payload],
      };
    case CREATE_PROMEMBER_REPLY_FAILURE:
      return {
        loading: false,
        createcommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialUpdateCommunityProMemberReplyState = {
  updatecommunityreply: [],
  loading: false,
};

export const updateCommunityProMemberReplyReducer = (
  state = initialUpdateCommunityProMemberReplyState,
  action
) => {
  switch (action.type) {
    case UPDATE_PROMEMBER_REPLY_RESET:
      return {
        loading: false,
        updatecommunityreply: [],
      };
    case UPDATE_PROMEMBER_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROMEMBER_REPLY_SUCCESS:
      return {
        loading: false,
        updatecommunityreply: [action.payload],
      };
    case UPDATE_PROMEMBER_REPLY_FAILURE:
      return {
        loading: false,
        updatecommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialDeleteCommunityProMemberReplyState = {
  deletecommunityreply: [],
  loading: false,
};

export const deleteCommunityProMemberReplyReducer = (
  state = initialDeleteCommunityProMemberReplyState,
  action
) => {
  switch (action.type) {
    case DELETE_PROMEMBER_REPLY_RESET:
      return {
        loading: false,
        deletecommunityreply: [],
      };
    case DELETE_PROMEMBER_REPLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PROMEMBER_REPLY_SUCCESS:
      return {
        loading: false,
        deletecommunityreply: [action.payload],
      };
    case DELETE_PROMEMBER_REPLY_FAILURE:
      return {
        loading: false,
        deletecommunityreply: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialCreateProMemberLikePostState = {
  createcommunitypromemberlikepost: [],
  loading: false,
};

export const createProMemberLikePostReducer = (
  state = initialCreateProMemberLikePostState,
  action
) => {
  switch (action.type) {
    case CREATE_PROMEMBER_LIKEPOST_RESET:
      return {
        loading: false,
        createcommunitypromemberlikepost: [],
      };
    case CREATE_PROMEMBER_LIKEPOST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROMEMBER_LIKEPOST_SUCCESS:
      return {
        loading: false,
        createcommunitypromemberlikepost: [action.payload],
      };
    case CREATE_PROMEMBER_LIKEPOST_FAILURE:
      return {
        loading: false,
        createcommunitypromemberlikepost: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

// const initialCreateProMemberSavePostState = {
//   createcommunitysavepost: [],
//   loading: false,
// };

// export const createCommunitySavePostReducer = (
//   state = initialCreateProMemberSavePostState,
//   action
// ) => {
//   switch (action.type) {
//     case CREATE_PROMEMBER_SAVEPOST_RESET:
//       return {
//         loading: false,
//         createcommunitysavepost: [],
//       };
//     case CREATE_PROMEMBER_SAVEPOST_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_PROMEMBER_SAVEPOST_SUCCESS:
//       return {
//         loading: false,
//         createcommunitysavepost: [action.payload],
//       };
//     case CREATE_PROMEMBER_SAVEPOST_FAILURE:
//       return {
//         loading: false,
//         createcommunitysavepost: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const initialCreateProMemberLikeCommentState = {
//   createcommunitylikeComment: [],
//   loading: false,
// };

// export const createCommunityLikeCommentReducer = (
//   state = initialCreateProMemberLikeCommentState,
//   action
// ) => {
//   switch (action.type) {
//     case CREATE_PROMEMBER_LIKECOMMENT_RESET:
//       return {
//         loading: false,
//         createcommunitylikeComment: [],
//       };
//     case CREATE_PROMEMBER_LIKECOMMENT_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case CREATE_PROMEMBER_LIKECOMMENT_SUCCESS:
//       return {
//         loading: false,
//         createcommunitylikeComment: [action.payload],
//       };
//     case CREATE_PROMEMBER_LIKECOMMENT_FAILURE:
//       return {
//         loading: false,
//         createcommunitylikeComment: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// const initialFetchCommunityLikedPostState = {
//   likedpost: [],
//   loading: false,
// };

// export const fetchCommunityLikedPostReducer = (
//   state = initialFetchCommunityLikedPostState,
//   action
// ) => {
//   switch (action.type) {
//     case FETCH_PROMEMBER_LIKEDPOST_RESET:
//       return {
//         loading: false,
//         likedpost: [],
//       };
//     case FETCH_PROMEMBER_LIKEDPOST_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case FETCH_PROMEMBER_LIKEDPOST_SUCCESS:
//       return {
//         loading: false,
//         likedpost: action.payload,
//       };
//     case FETCH_PROMEMBER_LIKEDPOST_FAILURE:
//       return {
//         loading: false,
//         likedpost: [],
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };
