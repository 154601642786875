
import { 

    FETCH_STUDENT_REQUEST_TUTOR_FAILURE,
    FETCH_STUDENT_REQUEST_TUTOR_REQUEST,
    FETCH_STUDENT_REQUEST_TUTOR_RESET,
    FETCH_STUDENT_REQUEST_TUTOR_SUCCESS,
    

    APPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
    APPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    APPROVE_STUDENT_REQUEST_TUTOR_RESET,
    APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,

    DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET,
    DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,


    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET,
    FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS,

    OPEN_UTV_MODAL,
    CLOSE_UTV_MODAL,
    EDIT_TUTOR_REQUEST,

    FETCH_SRT_DETAILS_FAILURE,
    FETCH_SRT_DETAILS_REQUEST,
    // FETCH_SRT_DETAILS_RESET,
    FETCH_SRT_DETAILS_SUCCESS,

    CREATE_STR_UPLOAD_REQUEST ,
    CREATE_STR_UPLOAD_SUCCESS,
    CREATE_STR_UPLOAD_FAILURE,
    CREATE_STR_UPLOAD_RESET

} from "./ManageTutorRequestsTypes"

import axios from "axios";

export const fetchStudentRequestTutorReset = () => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_RESET,
    }
}

const fetchStudentRequestTutorRequest = () => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_REQUEST,
    }
}

const fetchStudentRequestTutorSuccess = success => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_SUCCESS,
        payload: success
    }
}

const fetchStudentRequestTutorFailure = error => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_FAILURE,
        payload : error
    }
}


export const fetchStudentRequestTutor = (data) => {

    return (dispatch) => {

        dispatch(fetchStudentRequestTutorRequest());
        
        
        
        axios.post('/fetchStudentRequestTutor', data)
        .then(response => {
            const fetchStudentRequestTutor = response.data
            dispatch(fetchStudentRequestTutorSuccess(fetchStudentRequestTutor))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStudentRequestTutorFailure(errorMsg))
        })
        
    }

}


export const approveStudentRequestTutorReset = () => {
    return {
        type: APPROVE_STUDENT_REQUEST_TUTOR_RESET,
    }
}

const approveStudentRequestTutorRequest = () => {
    return {
        type: APPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    }
}

const approveStudentRequestTutorSuccess = success => {
    return {
        type: APPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,
        payload: success
    }
}

const approveStudentRequestTutorFailure = error => {
    return {
        type: APPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
        payload : error
    }
}


export const approveStudentRequestTutor = (data) => {

    return (dispatch) => {

        dispatch(approveStudentRequestTutorRequest());
        
        
        
        axios.post('/approveStudentRequestTutor', data)
        .then(response => {
            const approveStudentRequestTutor = response.data
            dispatch(approveStudentRequestTutorSuccess(approveStudentRequestTutor))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(approveStudentRequestTutorFailure(errorMsg))
        })
        
    }

}


export const disapproveStudentRequestTutorReset = () => {
    return {
        type: DISAPPROVE_STUDENT_REQUEST_TUTOR_RESET,
    }
}

const disapproveStudentRequestTutorRequest = () => {
    return {
        type: DISAPPROVE_STUDENT_REQUEST_TUTOR_REQUEST,
    }
}

const disapproveStudentRequestTutorSuccess = success => {
    return {
        type: DISAPPROVE_STUDENT_REQUEST_TUTOR_SUCCESS,
        payload: success
    }
}

const disapproveStudentRequestTutorFailure = error => {
    return {
        type: DISAPPROVE_STUDENT_REQUEST_TUTOR_FAILURE,
        payload : error
    }
}


export const disapproveStudentRequestTutor = (data) => {

    return (dispatch) => {

        dispatch(disapproveStudentRequestTutorRequest());
        
        
        
        axios.post('/disapproveStudentRequestTutor', data)
        .then(response => {
            const disapproveStudentRequestTutor = response.data
            dispatch(disapproveStudentRequestTutorSuccess(disapproveStudentRequestTutor))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(disapproveStudentRequestTutorFailure(errorMsg))
        })
        
    }

}


export const fetchStudentRequestTutorHistoryReset = () => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_HISTORY_RESET,
    }
}

const fetchStudentRequestTutorHistoryRequest = () => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_HISTORY_REQUEST,
    }
}

const fetchStudentRequestTutorHistorySuccess = success => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_HISTORY_SUCCESS,
        payload: success
    }
}

const fetchStudentRequestTutorHistoryFailure = error => {
    return {
        type: FETCH_STUDENT_REQUEST_TUTOR_HISTORY_FAILURE,
        payload : error
    }
}


export const fetchStudentRequestTutorHistory = (data) => {

    return (dispatch) => {

        dispatch(fetchStudentRequestTutorHistoryRequest());
        
        
        
        axios.post('/fetchStudentRequestTutorHistory', data)
        .then(response => {
            const fetchStudentRequestTutorHistory = response.data
            dispatch(fetchStudentRequestTutorHistorySuccess(fetchStudentRequestTutorHistory))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStudentRequestTutorHistoryFailure(errorMsg))
        })
        
    }

}

const openModalUploadTutorVideoFile = status => {
    return {
        type: OPEN_UTV_MODAL,
        payload : status
    }
}

const closeModalUploadTutorVideoFile = status => {
    return {
        type: CLOSE_UTV_MODAL,
        payload : status
    }
}

export const updateModalUploadTutorVideoFile = (data) => {

    return (dispatch) => {
        if (data) {

            dispatch(openModalUploadTutorVideoFile(data))

        } 
        else{

            dispatch(closeModalUploadTutorVideoFile(data))

        } 
    }

}

export const editTutorRequest = data => {
    
    return {
        type: EDIT_TUTOR_REQUEST,
        payload : data
    }
}


const fetchStudentRequestTutorDetailsRequest = () => {
    return {
        type: FETCH_SRT_DETAILS_REQUEST,
    }
}

const fetchStudentRequestTutorDetailsSuccess = success => {
    return {
        type: FETCH_SRT_DETAILS_SUCCESS,
        payload: success
    }
}

const fetchStudentRequestTutorDetailsFailure = error => {
    return {
        type: FETCH_SRT_DETAILS_FAILURE,
        payload : error
    }
}

export const fetchStudentRequestTutorDetails = (data) => {

    return (dispatch) => {
        
        dispatch(fetchStudentRequestTutorDetailsRequest());
        
        
        axios.post('/fetchStudentRequestTutorDetails' , data)
        .then(response => {
            const details = response.data
            dispatch(fetchStudentRequestTutorDetailsSuccess(details))
            
         
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStudentRequestTutorDetailsFailure(errorMsg))
        })
        
    }

}

export const createStrUploadReset = () => {
    return {
        type: CREATE_STR_UPLOAD_RESET,
    }
}

const createStrUploadRequest = () => {
    return {
        type: CREATE_STR_UPLOAD_REQUEST,
    }
}

const createStrUploadSuccess = success => {
    return {
        type: CREATE_STR_UPLOAD_SUCCESS,
        payload: success
    }
}

const createStrUploadFailure = error => {
    return {
        type: CREATE_STR_UPLOAD_FAILURE,
        payload : error
    }
}

export const createStrUpload = (data) => {

    return (dispatch) => {

        dispatch(createStrUploadRequest());
        
        axios.post('/storeStrUpload', data,{headers: {
            'Content-Type': 'multipart/form-data'
            
          }})
        .then(response => {
            const strUpload = response.data
            dispatch(createStrUploadSuccess(strUpload))
            
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createStrUploadFailure(errorMsg))
        })
        
    }

}