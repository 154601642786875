import chroma from "chroma-js";

export const customDropdown = {
    control: (provided) => ({
      ...provided,
      borderColor: 'transparent',
      borderRadius: 7,
      boxShadow:"0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)"
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none',
    }),
    option: (provided) => ({
      ...provided,
      padding: '8px 16px',
      fontSize: '0.875rem',
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '8px 8px',
      fontSize: '0.875rem',
      color:"#8898aa"
    }),
    input: (provided) => ({
      ...provided,
      color: '#495057',
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: '8px 8px',
      fontSize: '0.875rem',
      color:"#8898aa"
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => null,
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const customDropdownWithBorder = {
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '8px', 
        
    }),
    control: (provided) => ({
        ...provided,
        borderColor: '#cad1d7',
        borderRadius: 7,
        
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        borderRadius: 0,
        boxShadow: 'none',
    }),
    option: (provided) => ({
        ...provided,
        padding: '8px 16px',
        fontSize: '0.875rem',
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: '8px 8px',
        fontSize: '0.875rem',
        color:"#8898aa"
    }),
    input: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: '8px 8px',
        fontSize: '0.875rem',
        color:"#8898aa"
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => null,
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

  

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  
export const customDropdownWithColor = {

    control: (provided) => ({
        ...provided,
        borderColor: 'transparent',
        borderRadius: 7,
        boxShadow:"0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)"
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        borderRadius: 0,
        boxShadow: 'none',
    }),
    option: (provided) => ({
        ...provided,
        padding: '8px 16px',
        
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: '8px 8px',
        fontSize: '0.875rem',
        color:"#8898aa"
    }),
    input: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    placeholder: (provided) => ({
        ...provided,
        padding: '8px 8px',
        fontSize: '0.875rem',
        color:"#8898aa"
    }),
    indicatorSeparator: () => null,
    clearIndicator: () => null,

    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
        fontSize: '0.875rem',
      };
    },
    input: (styles) => ({ 
        ...styles, 
        ...dot() 
    }),
    placeholder: (styles) => ({ 
        ...styles, 
        ...dot('#ccc') 
    }),
    singleValue: (styles, { data }) => ({ 
        ...styles, 
        ...dot(data.color) 
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};


export const customDropdownWithoutDotColor = {
  control: (provided) => ({
    ...provided,
    borderColor: 'transparent',
    borderRadius: 7,
    boxShadow: "0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)"
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    boxShadow: 'none',
  }),
  option: (provided) => ({
    ...provided,
    padding: '8px 16px',

  }),
  singleValue: (provided) => ({
    ...provided,
    padding: '8px 8px',
    fontSize: '0.875rem',
    color: "#8898aa"
  }),
  input: (provided) => ({
    ...provided,
    color: '#495057',
  }),
  placeholder: (provided) => ({
    ...provided,
    padding: '8px 8px',
    fontSize: '0.875rem',
    color: "#8898aa"
  }),
  indicatorSeparator: () => null,
  clearIndicator: () => null,

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
      fontSize: '0.875rem',
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};